import type { IInspection } from '@/interfaces/inspections';
import { InspectionStatus, InspectionStatusColor } from '@/interfaces/enums/inspections';

export const colors: Record<InspectionStatus | 'unknown', InspectionStatusColor> = {
    [InspectionStatus.SCHEDULING]: InspectionStatusColor.SCHEDULING,
    [InspectionStatus.SCHEDULED]: InspectionStatusColor.SCHEDULED,
    [InspectionStatus.IN_PROGRESS]: InspectionStatusColor.IN_PROGRESS,
    [InspectionStatus.COMPLETED]: InspectionStatusColor.COMPLETED,
    unknown: InspectionStatusColor.UNKNOWN,
};

const getInspectionStatusColor = (status?: IInspection['status']) => {
    return (status != null && colors[status]) || colors.unknown;
};

export default getInspectionStatusColor;
