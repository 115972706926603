import {
    ACCESS_TOKEN_STORAGE_KEY,
    PROFILE_STORAGE_KEY,
    REFRESH_TOKEN_STORAGE_KEY,
} from '@/constants';

const removeUserData = () => {
    try {
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        localStorage.removeItem(PROFILE_STORAGE_KEY);
    } catch (error) {
        console.error('LocalStorage is not available. Cannot persist user login data.', error);
    }
};

export default removeUserData;
