import { type PropsWithChildren, type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';
import clsx from 'clsx';

import c from './StatsCard.module.css';

export type Props = PropsWithChildren<{
    title: string;
    color: string;
    className?: string;
}>;

const StatsCard: FunctionComponent<Props> = ({ title, color, children, className }) => {
    return (
        <Card
            title={<Typography.Text className='m-0 uppercase'>{title}</Typography.Text>}
            style={{ backgroundColor: color }}
            classNames={{
                header: c.header,
                body: c.body,
            }}
            className={clsx('flex-grow-1', className)}
        >
            {children}
        </Card>
    );
};

export default StatsCard;
