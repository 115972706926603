import { PROPERTIES_MAX_DEPTH } from '@/constants';

import {
    IPropertyData,
    IPropertyFormValueItem,
    IPropertyFormValues,
    IPropertyTree,
    IPropertyValue,
} from '@/interfaces/properties';

const propertiesToValues = (
    tree: IPropertyTree,
    data?: IPropertyData,
    defaultValues?: IPropertyFormValues,
    maxDepth: number = PROPERTIES_MAX_DEPTH,
): IPropertyFormValues => {
    const values: IPropertyFormValues = {};
    if (maxDepth <= 0) {
        return values;
    }

    tree.forEach((field) => {
        const fieldName = field.name;
        const dataItem = data?.find((item) => item.id === fieldName);
        const defaultValue = defaultValues?.[fieldName];
        values[fieldName] =
            dataItem?.vals.map(({ id, readonly, value, children }) => {
                const fieldValue: IPropertyValue = { id, readonly, value };
                // @ts-ignore
                let subValue: IPropertyFormValueItem = { value: fieldValue };
                if (field.children) {
                    const otherValues = propertiesToValues(
                        field.children,
                        children,
                        defaultValue ? { [fieldName]: defaultValue } : undefined,
                        maxDepth - 1,
                    );
                    subValue = { ...otherValues, ...subValue };
                }

                return subValue;
            }) ??
            defaultValue?.map((item) => {
                const fieldValue: IPropertyValue = { ...item.value };
                // @ts-ignore
                let subValue: IPropertyFormValueItem = { value: fieldValue };

                if (field.children) {
                    const otherValues = propertiesToValues(
                        field.children,
                        undefined,
                        defaultValue ? { [fieldName]: defaultValue } : undefined,
                        maxDepth - 1,
                    );
                    subValue = { ...otherValues, ...subValue };
                }

                return subValue;
            }) ??
            [];
    });

    return values;
};

export default propertiesToValues;
