import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Typography, Spin } from 'antd';
import { Show } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import ScoresDisplay from './ScoresDisplay';

import type { IFactoryBrand } from '@/interfaces/factoryBrands';

const tBase = 'factoryBrands.show';

export const FactoryBrandsShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'factories', action: 'edit' });

    const { queryResult } = useShow<IFactoryBrand>();
    const { data, isLoading, isError } = queryResult;
    const entity = data?.data;

    if (isLoading) {
        return <Spin />;
    }

    if (isError || !entity) {
        return <div>{t(`${tBase}.errors.notFound`)}</div>;
    }

    return (
        <Show title={t('factoryBrands.titles.show')} canEdit={canEdit} isLoading={isLoading}>
            <Typography.Title level={5}>{t(`${tBase}.fields.name`)}</Typography.Title>
            <Typography.Paragraph>{entity.name}</Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.listing`)}</Typography.Title>
            <Typography.Paragraph>{entity.listing ?? DEFAULT_NA_VALUE}</Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.scores`)}</Typography.Title>
            <Typography.Paragraph>
                <ScoresDisplay value={entity.scores} />
            </Typography.Paragraph>
        </Show>
    );
};
