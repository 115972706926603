import { type FunctionComponent, Fragment, useCallback } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Input, Row, Col, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import type { UnitOfMeasure } from '@/interfaces';

export type UnitOfMeasureValue = Omit<UnitOfMeasure, 'id' | 'factor'> & {
    id?: UnitOfMeasure['id'];
    factor: UnitOfMeasure['factor'] | string;
};

export type Props = {
    tBase: string;
};

const UomsField: FunctionComponent<Props> = ({ tBase }) => {
    const t = useTranslate();

    const onFieldValidate = useCallback(
        async (name: string, index: number, value?: string | number) => {
            if (value == null || !`${value}`.trim()) {
                throw new Error(t(`${tBase}.rules.${name}.required`, { index: index + 1 }));
            }
        },
        [t, tBase]
    );

    return (
        <Form.List
            name='uoms'
            rules={[
                {
                    validator: async (_rule: any, value?: UnitOfMeasureValue[]) => {
                        if (!value?.length) {
                            throw new Error(t(`${tBase}.rules.uoms.required`));
                        }
                    },
                },
            ]}
        >
            {(fields, { add, remove }, { errors }) => (
                <Fragment>
                    {fields.map(({ key, name: fieldKey, ...fieldProps }) => (
                        <Row key={key} gutter={[16, 16]} style={{ marginBottom: 16, flexGrow: 1 }}>
                            <Col style={{ flexGrow: 1 }}>
                                <Form.Item
                                    {...fieldProps}
                                    name={[fieldKey, 'name']}
                                    noStyle
                                    rules={[
                                        {
                                            validator: (_rule: any, value?: string) =>
                                                onFieldValidate('uomsName', fieldKey, value),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(`${tBase}.fields.uomsName`)}
                                        autoComplete='off'
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ flexGrow: 1 }}>
                                <Form.Item
                                    {...fieldProps}
                                    name={[fieldKey, 'factor']}
                                    noStyle
                                    rules={[
                                        {
                                            validator: (_rule: any, value?: string) =>
                                                onFieldValidate('uomsFactor', fieldKey, value),
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        placeholder={t(`${tBase}.fields.uomsFactor`)}
                                        autoComplete='off'
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button
                                    type='link'
                                    className='dynamic-delete-button'
                                    onClick={() => remove(fieldKey)}
                                >
                                    <MinusCircleOutlined />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <Button
                        type='dashed'
                        icon={<PlusOutlined />}
                        style={{ marginBottom: 16 }}
                        onClick={() => add()}
                    >
                        {t(`${tBase}.buttons.addUoms`)}
                    </Button>
                    <Form.ErrorList errors={errors} />
                </Fragment>
            )}
        </Form.List>
    );
};

export default UomsField;
