import { Line } from '@ant-design/plots';

interface LineChartProps {
    data?: { xField: string; value: number }[];
}

const quarters = ['2022-Q1', '2022-Q2', '2022-Q3', '2022-Q4', '2023-Q1', '2023-Q2', '2023-Q3'];
const mockData = quarters.map((quarter) => ({
    xField: quarter,
    value: Math.floor(Math.random() * 10),
}));

const LineChart: React.FC<LineChartProps> = ({ data = mockData }) => {
    data = data.map((el) => ({ xField: el.xField.toString(), value: el.value }));
    const config = {
        data,
        padding: 'auto',
        xField: 'xField',
        yField: 'value',
        animation: {
            appear: {
                duration: 2000,
            },
        },
    };

    // @ts-ignore
    return <Line {...config} />;
};

export default LineChart;
