import { useMemo, type FunctionComponent } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';

import { DEFAULT_NA_VALUE } from '@/constants';

import StatsCard from '@/components/StatsCard';

import getAuditStatusColor from '@/utils/audit/getAuditStatusColor';
import getAuditScoreResultColor from '@/utils/audit/getAuditScoreResultColor';
import getAuditNccaStatusColor from '@/utils/audit/getAuditNccaStatusColor';
import getAuditNcRateResultColor from '@/utils/audit/getAuditNcRateResultColor';
import type { IAudit } from '@/interfaces/audits';
import { AuditStatus } from '@/interfaces/enums/audits';

export type Props = {
    tBase: string;
    audit?: IAudit;
};

const AuditStats: FunctionComponent<Props> = ({ tBase, audit }) => {
    const t = useTranslate();
    const { totalKeyItems, checkedKeyItems, keyItemsRate, nccaDaysLeft } = useMemo(() => {
        const totalKeyItems = audit?.keyItems?.length || 0;
        const checkedKeyItems = audit?.keyItems?.filter((ki) => ki.value).length || 0;
        const days = audit?.daysToFix || -1;
        /** @todo Format with locale */
        const nccaDaysLeft = days > 0 ? `${days} ${days === 1 ? 'day' : 'days'}` : DEFAULT_NA_VALUE;

        return {
            totalKeyItems,
            checkedKeyItems,
            keyItemsRate: !totalKeyItems ? 0 : (checkedKeyItems / totalKeyItems) * 100,
            nccaDaysLeft,
        };
    }, [audit]);

    const isComplete = audit?.auditStatus === AuditStatus.COMPLETE;

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.auditStatus`)}
                    color={getAuditStatusColor(audit?.auditStatus)}
                >
                    <div className='d-flex row justify-between align-end'>
                        <div>&nbsp;</div>
                        <Typography.Title level={3} className='m-0 uppercase'>
                            {audit?.auditStatus == null
                                ? DEFAULT_NA_VALUE
                                : t(`audits.auditStatus.${audit.auditStatus}`)}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.scoreResult`)}
                    color={getAuditScoreResultColor(isComplete ? audit?.scoreResult : undefined)}
                >
                    <div className='d-flex row justify-between align-end'>
                        {isComplete ? (
                            <div>&nbsp;</div>
                        ) : (
                            <div>{t('audits.scoreResult.hiddenIncomplete')}</div>
                        )}
                        <Typography.Title level={3} className='m-0 uppercase'>
                            {isComplete && audit?.scoreResult != null
                                ? t(`audits.scoreResult.${audit.scoreResult}`)
                                : DEFAULT_NA_VALUE}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.keyItems`)}
                    color={getAuditNcRateResultColor(keyItemsRate)}
                >
                    <div className='d-flex row justify-between align-end'>
                        <div>{`${checkedKeyItems}/${totalKeyItems}`}</div>
                        <Typography.Title level={3} className='m-0'>
                            {`${keyItemsRate.toFixed(2)}%`}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.nccaStatus`)}
                    color={getAuditNccaStatusColor(audit?.nccaStatus)}
                >
                    <div className='d-flex row justify-between align-end'>
                        <div>{nccaDaysLeft}</div>
                        <Typography.Title level={3} className='m-0 uppercase'>
                            {audit?.nccaStatus == null
                                ? DEFAULT_NA_VALUE
                                : t(`audits.nccaStatus.${audit.nccaStatus}`)}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
        </Row>
    );
};

export default AuditStats;
