import dayjs from 'dayjs';

import { type FormValues } from '@/pages/factoryBrands/form';
import { type ScoresValue } from '@/components/ScoresField';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

const formValuesFromFactoryBrand = (entity?: IFactoryBrand): FormValues | undefined => {
    if (!entity) {
        return;
    }

    const initialValues: FormValues = {
        name: entity.name,
        listing: entity.listing,
        scores:
            entity.scores?.map((item): ScoresValue => {
                return {
                    quarter: item.quarter,
                    // @ts-ignore
                    year: dayjs(`${Number(item.year)}-06-15`),
                    value: item.value,
                };
            }) ?? [],
    };

    return initialValues;
};

export default formValuesFromFactoryBrand;
