import { type FunctionComponent, Fragment, useCallback, useMemo } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Select, Row, Col, Button, Space, InputNumber, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { DEFAULT_MIN_DATE } from '@/constants';

import c from './ScoresField.module.css';

export type ScoresValue = {
    quarter: number | string;
    year: number | string;
    value: number | string;
};

export type Props = {
    tBase: string;
};

const ScoresField: FunctionComponent<Props> = ({ tBase }) => {
    const t = useTranslate();

    const quarterOptions = useMemo(
        () =>
            [1, 2, 3, 4].map((quarter) => ({
                value: quarter,
                label: t(`quarters.${quarter}q`),
            })),
        [t],
    );

    const onFieldValidate = useCallback(
        async (name: string, index: number, value?: string | number) => {
            if (value == null || !`${value}`.trim()) {
                throw new Error(t(`${tBase}.rules.${name}.required`, { index: index + 1 }));
            }
        },
        [t, tBase],
    );

    const onListValidate = useCallback(
        async (_rule: any, value?: ScoresValue[]) => {
            if (!value?.length) {
                return;
            }

            for (let i = 0; i < value.length - 1; i++) {
                for (let j = i + 1; j < value.length; j++) {
                    const a = value[i];
                    const b = value[j];
                    if (
                        Number(a.year) === Number(b.year) &&
                        Number(a.quarter) === Number(b.quarter)
                    ) {
                        throw new Error(
                            t(`${tBase}.rules.scores.unique`, {
                                i: i + 1,
                                j: j + 1,
                                v: `${quarterOptions[Number(a.quarter) - 1]?.label}-${a.year}`,
                            }),
                        );
                    }
                }
            }
        },
        [t, tBase, quarterOptions],
    );

    return (
        <Form.List name='scores' rules={[{ validator: onListValidate }]}>
            {(fields, { add, remove }, { errors }) => (
                <Fragment>
                    {fields.map(({ key, name: fieldKey, ...fieldProps }) => (
                        <Row key={key} gutter={[16, 16]} className={c.row}>
                            <Col className='flex-grow-1'>
                                <Space.Compact className='w-full'>
                                    <Form.Item
                                        {...fieldProps}
                                        name={[fieldKey, 'quarter']}
                                        noStyle
                                        rules={[
                                            {
                                                validator: (_rule: any, value?: string) =>
                                                    onFieldValidate(
                                                        'scoresQuarter',
                                                        fieldKey,
                                                        value,
                                                    ),
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t(`${tBase}.placeholders.scoresQuarter`)}
                                            options={quarterOptions}
                                            className={c.quarter}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...fieldProps}
                                        name={[fieldKey, 'year']}
                                        noStyle
                                        rules={[
                                            {
                                                validator: (_rule: any, value?: string) =>
                                                    onFieldValidate('scoresYear', fieldKey, value),
                                            },
                                        ]}
                                    >
                                        <DatePicker.YearPicker
                                            minDate={DEFAULT_MIN_DATE}
                                            placeholder={t(`${tBase}.placeholders.scoresYear`)}
                                            autoComplete='off'
                                            className='w-full'
                                        />
                                    </Form.Item>
                                </Space.Compact>
                            </Col>
                            <Col className='flex-grow-1'>
                                <Form.Item
                                    {...fieldProps}
                                    name={[fieldKey, 'value']}
                                    noStyle
                                    rules={[
                                        {
                                            validator: (_rule: any, value?: string) =>
                                                onFieldValidate('scoresValue', fieldKey, value),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type='number'
                                        placeholder={t(`${tBase}.placeholders.scoresValue`)}
                                        min={0}
                                        autoComplete='off'
                                        controls={false}
                                        changeOnWheel={false}
                                        className='w-full'
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button
                                    type='link'
                                    className='dynamic-delete-button'
                                    onClick={() => remove(fieldKey)}
                                >
                                    <MinusCircleOutlined />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <Button
                        type='dashed'
                        icon={<PlusOutlined />}
                        className={c.button}
                        onClick={() => add({ year: '', quarter: undefined, value: '' })}
                    >
                        {t(`${tBase}.buttons.addScores`)}
                    </Button>
                    <Form.ErrorList errors={errors} />
                </Fragment>
            )}
        </Form.List>
    );
};

export default ScoresField;
