import { type FunctionComponent, useEffect, useState } from 'react';
import { Button, Card, Form, type FormProps, Select } from 'antd';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';

import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import type { AntdSelectOption } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';

export type Props = {
    formProps: FormProps;
};

const tBase = 'productTypes.filters';

const ProductTypesFilters: FunctionComponent<Props> = ({ formProps }) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const [productTypesOptions, setProductTypesOptions] = useState<AntdSelectOption[]>([]);

    const { data: productTypesData } = useCustom<IProductType[]>({
        url: `${apiUrl}/productTypes`,
        method: 'get',
    });

    const productTypes = productTypesData?.data;
    useEffect(() => {
        setProductTypesOptions(
            productTypes?.map(({ name }) => ({
                value: name.value ?? '',
                label: name.value ?? '',
            })) ?? [],
        );
    }, [productTypes]);

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                <Form.Item label={t(`${tBase}.fields.name`)} name='name'>
                    <Select
                        placeholder={t(`${tBase}.placeholders.name`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ProductTypesFilters;
