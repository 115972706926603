import { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useGetIdentity, useNotification } from '@refinedev/core';

import DashboardInspectionsList from './DashboardInspectionsList';
import DashboardAuditsList from './DashboardAuditsList';
import DashboardSummary from './DashboardSummary';
import DashboardCharts from './DashboardCharts';

import formatUserFullname from '@/utils/user/formatUserFullname';
import type { IUser } from '@/interfaces/users';

export const DashboardPage = () => {
    const { data: user } = useGetIdentity<IUser>();
    const { open, close } = useNotification();

    useEffect(() => {
        const userName = formatUserFullname(user) || 'User';
        open?.({
            type: 'success',
            key: 'dasboard-greetings',
            message: 'Below you can find current status of inspections and audits for you.',
            description: `Welcome, ${userName}`,
        });

        return () => {
            close?.('dasboard-greetings');
        };
    }, [open, close, user]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <DashboardSummary />
            </Col>
            <Col xs={24}>
                <DashboardCharts />
            </Col>
            <Col xs={24}>
                <DashboardInspectionsList />
            </Col>
            <Col xs={24}>
                <DashboardAuditsList />
            </Col>
        </Row>
    );
};
