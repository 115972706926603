import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

const useFactoryBrandsOptions = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetFactories } = {} } = useCan({
        resource: 'factories',
        action: 'get',
    });
    const { data } = useCustom<IFactoryBrand[]>({
        url: `${apiUrl}/factoryBrands`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetFactories,
        },
    });

    const factoryBrands = data?.data;

    return useMemo(
        () =>
            factoryBrands?.map(
                (item): AntdSelectOption<IFactoryBrand> => ({
                    value: item.id,
                    label: item.name,
                    item,
                }),
            ) ?? [],
        [factoryBrands],
    );
};

export default useFactoryBrandsOptions;
