import { type FunctionComponent } from 'react';

import PropertiesTreeField from '@/components/PropertiesTreeField';

import type { IPropertyTree } from '@/interfaces/properties';

export const pvModulesFieldsTree: IPropertyTree = [
    {
        name: 'product_tech',
        type: 'select',
        options: [],
        isUnique: true,
        readonly: true,
        children: [
            {
                name: 'semicond_material',
                type: 'text',
            },
        ],
    },
    {
        name: 'semicond_type',
        type: 'text',
    },
    {
        name: 'cell_arch',
        type: 'text',
    },
    {
        name: 'cell_design',
        type: 'text',
        readonly: true,
    },
];

export type Props = {
    tBase: string;
};

const PVModulesFields: FunctionComponent<Props> = ({ tBase }) => {
    return <PropertiesTreeField tree={pvModulesFieldsTree} tBase={`${tBase}.modules.pv`} />;
};

export default PVModulesFields;
