import { type FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, type FormProps } from 'antd';

import PropertyTextField from '@/components/PropertyTextField';
import UomsField, { type UnitOfMeasureValue } from './fields/UomsField';
import OtherModulesFields, { otherModulesFieldsTree } from './OtherModulesFields';
import PVModulesFields, { pvModulesFieldsTree } from './PVModulesFields';

import valuesToProperties from '@/utils/property/valuesToProperties';
import type { FormField, FormOnFinish } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';
import type { IPropertyData, IPropertyFormValues, IPropertyValue } from '@/interfaces/properties';
import { MAX_NAME_LENGTH } from '@/constants';

export type FormValues = Pick<IProductType, 'name'> & {
    uoms: UnitOfMeasureValue[];
    properties?: IPropertyData;
};

export type Props = {
    isPvModules: boolean;
    formProps: FormProps<FormValues>;
    onFinish: FormOnFinish<FormValues, IProductType>;
};

const tBase = 'productTypes.form';

const ProductTypesForm: FunctionComponent<Props> = ({ isPvModules, formProps, onFinish }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data: FormValues = {
                name: values.name,
                uoms: [],
            };

            values.uoms?.forEach(({ id, name, factor }) => {
                // if (name?.trim() && factor != null && `${factor}`.trim() !== '') {
                if (name?.trim()) {
                    const item: UnitOfMeasureValue = { name: name.trim(), factor };
                    if (id != null) {
                        item.id = id;
                    }
                    data.uoms.push(item);
                }
            });

            data.properties = valuesToProperties(
                isPvModules ? pvModulesFieldsTree : otherModulesFieldsTree,
                values as unknown as IPropertyFormValues,
            );

            setIsSaving(true);
            try {
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [onFinish, isPvModules],
    );

    const fields: FormField<keyof FormValues | 'uomsList'>[] = useMemo(
        () => [
            {
                name: 'name',
                rules: [
                    {
                        validator: async (_rule: any, value?: IPropertyValue) => {
                            if (value?.value == null || !value.value.trim()) {
                                throw new Error(t(`${tBase}.rules.name.required`));
                            }
                            if (value.value.length > MAX_NAME_LENGTH) {
                                throw new Error(
                                    t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                                );
                            }
                        },
                    },
                ],
                field: (
                    <PropertyTextField
                        placeholder={t(`${tBase}.placeholders.name`)}
                        autoComplete='off'
                    />
                ),
                required: true,
            },
            {
                name: 'uomsList',
                field: <UomsField tBase={tBase} />,
                required: true,
            },
        ],
        [t],
    );

    return (
        <Form
            {...formProps}
            disabled={isSaving}
            layout='vertical'
            onFinish={onFinishInner}
            style={{ maxWidth: 600 }}
        >
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}

            {(isPvModules && <PVModulesFields tBase={tBase} />) || (
                <OtherModulesFields tBase={tBase} />
            )}
        </Form>
    );
};

export default ProductTypesForm;
