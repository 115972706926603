import { type FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Input, type FormProps } from 'antd';

import { MAX_NAME_LENGTH } from '@/constants';

import type { FormField, FormOnFinish } from '@/interfaces';
import type { ICompany } from '@/interfaces/companies';

export type FormValues = Pick<ICompany, 'name'>;

export type Props = {
    formProps: FormProps<FormValues>;
    onFinish: FormOnFinish<FormValues, ICompany>;
};

const tBase = 'companies.form';

const CompanyForm: FunctionComponent<Props> = ({ formProps, onFinish }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data: FormValues = {
                name: values.name,
            };

            setIsSaving(true);
            try {
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [onFinish],
    );

    const fields: FormField<keyof FormValues>[] = useMemo(
        () => [
            {
                name: 'name',
                rules: [
                    { required: true, message: t(`${tBase}.rules.name.required`) },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input autoComplete='off' placeholder={t(`${tBase}.placeholders.name`)} />,
            },
        ],
        [t],
    );

    return (
        <Form
            {...formProps}
            disabled={isSaving}
            layout='vertical'
            onFinish={onFinishInner}
            style={{ maxWidth: 600 }}
        >
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default CompanyForm;
