import type { IAudit } from '@/interfaces/audits';
import { AuditNccaStatus, AuditNccaStatusColor } from '@/interfaces/enums/audits';

export const colors: Record<AuditNccaStatus | 'unknown', AuditNccaStatusColor> = {
    [AuditNccaStatus.ONGOING]: AuditNccaStatusColor.ONGOING,
    [AuditNccaStatus.COMPLETE]: AuditNccaStatusColor.COMPLETE,
    [AuditNccaStatus.NA]: AuditNccaStatusColor.NA,
    unknown: AuditNccaStatusColor.UNKNOWN,
};

const getAuditNccaStatusColor = (nccaStatus?: IAudit['nccaStatus']) => {
    return (nccaStatus != null && colors[nccaStatus]) || colors.unknown;
};

export default getAuditNccaStatusColor;
