import { type CrudFilter } from '@refinedev/core';
import { type Dayjs } from 'dayjs';

import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { IUser } from '@/interfaces/users';
import type { IProjectListItem } from '@/interfaces/projects';

export type SearchParams = {
    name?: string;
    factoryBrandId?: IFactoryBrand['id'];
    city?: string;
    country?: string;
    clientId?: IUser['id'];
    createdAt?: [Dayjs, Dayjs];
    selmaTotalInspect?: {
        min?: IProjectListItem['selmaTotalInspect'] | string;
        max?: IProjectListItem['selmaTotalInspect'] | string;
    };
};

const onSearch = ({
    name,
    createdAt,
    factoryBrandId,
    clientId,
    city,
    country,
}: SearchParams): CrudFilter[] => {
    return [
        {
            field: 'name',
            operator: 'contains',
            value: name || undefined,
        },
        {
            field: 'factoryCity',
            operator: 'contains',
            value: city || undefined,
        },
        {
            field: 'factoryCountry',
            operator: 'contains',
            value: country || undefined,
        },
        {
            field: 'createdAt',
            operator: 'gte',
            value: createdAt?.[0]?.isValid()
                ? createdAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt?.[1]?.isValid() ? createdAt[1].endOf('day').toISOString() : undefined,
        },
        {
            field: 'factoryBrandId',
            operator: 'eq',
            value: factoryBrandId || undefined,
        },
        {
            field: 'clientId',
            operator: 'eq',
            value: clientId || undefined,
        },
    ];
};

export default onSearch;
