import { ReactNode, useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { useCan, useTranslate } from '@refinedev/core';
import { DateField, TextField } from '@refinedev/antd';

import { DATE_FORMAT_DAY, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';

import downloadInspectionReport from '@/utils/inspection/downloadInspectionReport';
import downloadInspectionFlashFile from '@/utils/inspection/downloadInspectionFlashFile';
import formatFactoryLocation from '@/utils/factory/formatFactoryLocation';
import type { TableColumn } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';

import c from './InspectionBaseCard.module.css';

export type Props = {
    tBase: string;
    inspection?: IInspection;
};

type SummaryItem = { name: string; value?: string | number | ReactNode };

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const InspectionBaseCard: FunctionComponent<Props> = ({ tBase, inspection }) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const items = useMemo((): SummaryItem[] => {
        const newItems: SummaryItem[] = [
            { name: t(`${tBase}.fields.name`), value: inspection?.name },
            { name: t(`${tBase}.fields.total`), value: inspection?.total },
        ];

        if (canGetCompanies) {
            newItems.push({
                name: t(`${tBase}.fields.client.name`),
                value: inspection?.client?.name,
            });
        }

        newItems.push(
            { name: t(`${tBase}.fields.project.name`), value: inspection?.project?.name },
            { name: t(`${tBase}.fields.factory.name`), value: inspection?.factory?.name },
            {
                name: t(`${tBase}.fields.factory.location`),
                value: formatFactoryLocation(inspection?.factory),
            },
            {
                name: t(`${tBase}.fields.productType.name`),
                value: inspection?.productType?.name.value,
            },
        );

        let count = 1;
        inspection?.productModels?.forEach(({ name }) => {
            newItems.push({
                name: t(`${tBase}.fields.productModel`, { index: count++ }),
                value: name,
            });
        });

        newItems.push(
            { name: t(`${tBase}.fields.batchNumber`), value: inspection?.batchNumber },
            {
                name: t(`${tBase}.fields.startDate`),
                value: inspection?.startDate ? (
                    <DateField value={inspection.startDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            {
                name: t(`${tBase}.fields.completeDate`),
                value: inspection?.completeDate ? (
                    <DateField value={inspection.completeDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            {
                name: t(`${tBase}.fields.reportDate`),
                value: inspection?.reportDate ? (
                    <DateField value={inspection.reportDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            { name: t(`${tBase}.fields.imagesLink`), value: inspection?.imagesLink },
            {
                name: t(`${tBase}.fields.reportDownloadingUrl`),
                value: (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.buttons.downloadReport`)}
                        disabled={
                            !inspection?.isReportUploaded || !inspection?.reportDownloadingUrl
                        }
                        onClick={() => downloadInspectionReport(inspection)}
                    />
                ),
            },
            {
                name: t(`${tBase}.fields.flashDataDownloadingUrl`),
                value: (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.buttons.downloadFlashData`)}
                        disabled={
                            !inspection?.isFlashDataUploaded || !inspection?.flashDataDownloadingUrl
                        }
                        onClick={() => downloadInspectionFlashFile(inspection)}
                    />
                ),
            },
        );

        return newItems;
    }, [inspection, canGetCompanies, t, tBase]);

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: SummaryItem['name']) => (
                    <Typography.Title level={5} className='m-0'>
                        <TextField value={value} />
                    </Typography.Title>
                ),
            },
            {
                name: 'value',
                align: 'right',
                render: (value?: SummaryItem['value']) => {
                    if (value == null) {
                        return <TextField value={DEFAULT_NA_VALUE} />;
                    }

                    if (typeof value === 'string') {
                        return <TextField value={value} />;
                    }

                    return value;
                },
            },
        ],
        [],
    );

    return (
        <Card className={c.card}>
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='name'
                className={c.list}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                })}
            </Table>
        </Card>
    );
};

export default InspectionBaseCard;
