import { useMemo, type FunctionComponent } from 'react';
import { Button, Card, Form, type FormProps, Input, DatePicker } from 'antd';
import { useTranslate } from '@refinedev/core';

import { DEFAULT_MIN_DATE } from '@/constants';

import { type SearchParams } from './onSearch';
import type { FormField } from '@/interfaces';

export type FormValues = SearchParams;

export type Props = {
    formProps: FormProps;
};

const tBase = 'companies.filters';

const CompaniesFilters: FunctionComponent<Props> = ({ formProps }) => {
    const t = useTranslate();

    const fields: FormField<keyof FormValues>[] = useMemo(
        () => [
            {
                name: 'name',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.name`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'createdAt',
                field: (
                    <DatePicker.RangePicker
                        minDate={DEFAULT_MIN_DATE}
                        allowClear={true}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'updatedAt',
                field: (
                    <DatePicker.RangePicker
                        minDate={DEFAULT_MIN_DATE}
                        allowClear={true}
                        className='w-full'
                    />
                ),
            },
        ],
        [t],
    );

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            name={name}
                            label={t(`${tBase}.fields.${name}`)}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CompaniesFilters;
