import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import FactoryBrandsForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

export const FactoryBrandsEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { formProps, saveButtonProps, onFinish } = useForm<IFactoryBrand, HttpError, FormValues>({
        errorNotification,
    });

    const initialValues = formProps?.initialValues;

    return (
        <Edit title={t('factoryBrands.titles.edit')} saveButtonProps={saveButtonProps}>
            {initialValues != null && (
                <FactoryBrandsForm
                    formProps={{
                        ...formProps,
                        initialValues: {
                            ...initialValues,
                            scores: initialValues?.scores?.length
                                ? initialValues.scores
                                : [{ year: '', quarter: undefined, value: '' }],
                        },
                    }}
                    onFinish={onFinish}
                />
            )}
        </Edit>
    );
};
