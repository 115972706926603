import {
    ACCESS_TOKEN_STORAGE_KEY,
    PROFILE_STORAGE_KEY,
    REFRESH_TOKEN_STORAGE_KEY,
} from '@/constants';

import type { TokenResponseData, ProfileResponseData } from '@/interfaces/auth';

const persistUserData = (tokenData: TokenResponseData, profileData: ProfileResponseData) => {
    try {
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, tokenData.accessToken);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, tokenData.refreshToken);
        localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profileData));
    } catch (error) {
        console.error('LocalStorage is not available. Cannot persist user login data.', error);
    }
};

export default persistUserData;
