import { useCallback, useMemo, useState, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Input, Select, type FormProps, Row, Col } from 'antd';

import { MAX_NAME_LENGTH } from '@/constants';

import MapBoxGeocoder from '@/components/MapGeoCoder';

import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import coordsToSimpleLngLat from '@/utils/coordsToSimpleLngLat';
import formValuesFromFactory from '@/utils/factory/formValuesFromFactory';
import formValuesToFactory from '@/utils/factory/formValuesToFactory';
import type { FormField, FormOnFinish, LocationAddress, SimpleLngLat } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';

import c from './FactoriesForm.module.css';

export type Props = {
    formProps: FormProps<FormValues>;
    onFinish: FormOnFinish<FormValues, IFactory>;
};

export type FormValues = Pick<
    IFactory,
    | 'name'
    | 'brandId'
    | 'mainProductTypeId'
    | 'address'
    | 'city'
    | 'country'
    | 'latitude'
    | 'longitude'
>;

const tBase = 'factories.form';

const FactoriesForm: FunctionComponent<Props> = ({ formProps, onFinish }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const factoryBrandsOptions = useFactoryBrandsOptions();
    const { data: productTypesOptions } = useProductTypesOptions();

    const form = formProps.form;
    const onLocationChange = useCallback(
        (location: LocationAddress) => {
            if (form) {
                form.setFieldValue('longitude', location.longitude);
                form.setFieldValue('latitude', location.latitude);
                form.setFieldValue('address', location.address);
                form.setFieldValue('city', location.city);
                form.setFieldValue('country', location.country);
            }
        },
        [form],
    );

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            setIsSaving(true);
            try {
                const data = formValuesToFactory(values);
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [onFinish],
    );

    const factory = formProps.initialValues as IFactory | undefined;
    const initialValues: FormValues | undefined = useMemo(
        () => formValuesFromFactory(factory),
        [factory],
    );

    const fields: FormField<keyof FormValues>[] = useMemo(
        () => [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
            {
                name: 'brandId',
                rules: [{ required: true, message: t(`${tBase}.rules.brandId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.brandId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                    />
                ),
            },
            {
                name: 'mainProductTypeId',
                rules: [
                    { required: true, message: t(`${tBase}.rules.mainProductTypeId.required`) },
                ],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.mainProductTypeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
            {
                name: 'address',
                field: <Input autoComplete='off' />,
            },
            {
                name: 'city',
                field: <Input autoComplete='off' />,
            },
            {
                name: 'country',
                field: <Input autoComplete='off' />,
            },
            {
                name: 'longitude',
                rules: [{ required: true, message: t(`${tBase}.rules.longitude.required`) }],
                field: <Input autoComplete='off' disabled={true} readOnly={true} />,
            },
            {
                name: 'latitude',
                rules: [{ required: true, message: t(`${tBase}.rules.latitude.required`) }],
                field: <Input autoComplete='off' disabled={true} readOnly={true} />,
            },
        ],
        [factoryBrandsOptions, productTypesOptions, t],
    );

    const longitude = formProps.initialValues?.longitude;
    const latitude = formProps.initialValues?.latitude;
    const defaultLocation = useMemo<SimpleLngLat | undefined>(
        () => coordsToSimpleLngLat(longitude, latitude),
        [longitude, latitude],
    );

    return (
        <Form
            {...formProps}
            initialValues={initialValues}
            layout='vertical'
            disabled={isSaving}
            onFinish={onFinishInner}
        >
            <Row gutter={[32, 32]}>
                <Col className={c.fields}>
                    {fields.map(({ name, field, ...fieldProps }) => {
                        return (
                            <Form.Item
                                {...fieldProps}
                                key={name}
                                label={t(`${tBase}.fields.${name}`)}
                                name={name}
                            >
                                {field}
                            </Form.Item>
                        );
                    })}
                </Col>
                <Col className={c.mapContainer}>
                    <MapBoxGeocoder
                        defaultLocation={defaultLocation}
                        needInitialAddress={!initialValues?.address}
                        onLocationChange={onLocationChange}
                        className={c.map}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default FactoriesForm;
