import { useMemo } from 'react';
import { useTranslate } from '@refinedev/core';

import type { AntdSelectOption } from '@/interfaces';
import { AuditNccaStatus } from '@/interfaces/enums/audits';

const useAuditNccaStatusOptions = () => {
    const t = useTranslate();

    return useMemo(
        () =>
            Object.values(AuditNccaStatus).map(
                (nccaStatus): AntdSelectOption => ({
                    value: nccaStatus,
                    label: t(`audits.nccaStatus.${nccaStatus}`),
                }),
            ),
        [t],
    );
};

export default useAuditNccaStatusOptions;
