import { type ForwardRefExoticComponent, type RefAttributes } from 'react';
import { DollarOutlined, BankOutlined } from '@ant-design/icons';
import { type AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

import type { IFactorySummaryItem } from '@/interfaces/factories';

export type ActualProductionItem = {
    product: string;
    production: number;
    productionDisplay: string;
    change?: 'up' | 'down';
};

export type ProductionDataItem = {
    year: number;
    items: ActualProductionItem[];
    IconComponent?: ForwardRefExoticComponent<AntdIconProps & RefAttributes<HTMLSpanElement>>;
};

const getActualProductionData = (factoriesSummary?: IFactorySummaryItem[]) => {
    const currentYear = new Date().getFullYear();
    const result: ProductionDataItem[] = [
        { year: currentYear, IconComponent: DollarOutlined, items: [] },
        { year: currentYear - 1, IconComponent: BankOutlined, items: [] },
        { year: currentYear - 2, IconComponent: undefined, items: [] },
    ];
    factoriesSummary?.forEach(({ product, actualProduction, reportingPeriod, uom }) => {
        const item = result.find(({ year }) => year === reportingPeriod);
        if (item) {
            const productItem = item.items.find((item) => item.product === product);
            if (productItem) {
                productItem.production += actualProduction;
            } else {
                item.items.push({
                    product: product,
                    production: actualProduction,
                    productionDisplay: `${(actualProduction || 0).toFixed(2)}${
                        uom ? ` ${uom}` : ''
                    }`,
                });
            }
        }
    });

    const sortProduction = (a: ActualProductionItem, b: ActualProductionItem) =>
        a.product.localeCompare(b.product);

    for (let i = 0; i < 2; i++) {
        result[i].items.sort(sortProduction);
        const prevItems = result[i + 1].items;
        result[i].items.forEach((item) => {
            const prevItem = prevItems.find(({ product }) => product === item.product);
            item.change =
                !prevItem || prevItem.production === item.production
                    ? undefined
                    : prevItem.production < item.production
                      ? 'up'
                      : 'down';
        });
    }

    return result.slice(0, 2);
};

export default getActualProductionData;
