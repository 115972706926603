import { type FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, type FormProps, Input } from 'antd';

import { MAX_NAME_LENGTH } from '@/constants';

import ScoresField, { type ScoresValue } from '@/components/ScoresField';

import type { FormField, FormOnFinish } from '@/interfaces';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import formValuesToFactoryBrand from '@/utils/factoryBrand/formValuesToFactoryBrand';
import formValuesFromFactoryBrand from '@/utils/factoryBrand/formValuesFromFactoryBrand';

export type FormValues = Pick<IFactoryBrand, 'name' | 'listing'> & {
    scores: ScoresValue[];
};

export type Props = {
    formProps: FormProps<FormValues>;
    onFinish: FormOnFinish<FormValues, IFactoryBrand>;
};

const tBase = 'factoryBrands.form';

const FactoryBrandsForm: FunctionComponent<Props> = ({ formProps, onFinish }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data = formValuesToFactoryBrand(values);

            setIsSaving(true);
            try {
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [onFinish],
    );

    const fields: FormField<keyof FormValues | 'scoresList'>[] = useMemo(
        () => [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
            {
                name: 'listing',
                field: (
                    <Input placeholder={t(`${tBase}.placeholders.listing`)} autoComplete='off' />
                ),
            },
            {
                name: 'scoresList',
                field: <ScoresField tBase={tBase} />,
            },
        ],
        [t],
    );

    const factoryBrand = formProps.initialValues as IFactoryBrand | undefined;
    const initialValues: FormValues | undefined = useMemo(
        () => formValuesFromFactoryBrand(factoryBrand),
        [factoryBrand],
    );

    return (
        <Form
            {...formProps}
            initialValues={initialValues}
            disabled={isSaving}
            layout='vertical'
            onFinish={onFinishInner}
            style={{ maxWidth: 600 }}
        >
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default FactoryBrandsForm;
