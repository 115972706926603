import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, InputNumber } from 'antd';

import type { FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';

export type Props = {};

export type FormValues = Pick<IInspection, 'selmaTotalInspect' | 'selmaNCsQty'>;

const tBase = 'inspections.form.selmaBase';

type FormFields = FormField<keyof FormValues>[];

const InspectionSelmaBaseFields: FunctionComponent<Props> = () => {
    const t = useTranslate();

    const fields: FormFields = useMemo(
        () => [
            {
                name: 'selmaTotalInspect',
                field: (
                    <InputNumber
                        type='number'
                        min={0}
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.selmaTotalInspect`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'selmaNCsQty',
                field: (
                    <InputNumber
                        type='number'
                        min={0}
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.selmaNCsQty`)}
                        readOnly={true}
                        disabled={true}
                        className='w-full'
                    />
                ),
            },
        ],
        [t],
    );

    return (
        <Fragment>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionSelmaBaseFields;
