import { PROPERTIES_MAX_DEPTH } from '@/constants';

import {
    IPropertyData,
    IPropertyDataItem,
    IPropertyFormValues,
    IPropertyTree,
} from '@/interfaces/properties';

const valuesToProperties = (
    tree: IPropertyTree,
    values?: IPropertyFormValues,
    maxDepth: number = PROPERTIES_MAX_DEPTH,
): IPropertyData => {
    const data: IPropertyData = [];
    if (maxDepth <= 0) {
        return data;
    }

    tree.forEach((field) => {
        const fieldName = field.name;
        const vals: IPropertyDataItem[] =
            values?.[fieldName]?.map((item) => {
                const value: IPropertyDataItem = item.value;

                if (field.children) {
                    value.children = valuesToProperties(field.children, item);
                }

                return value;
            }) ?? [];

        data.push({ id: fieldName, vals });
    });

    return data;
};

export default valuesToProperties;
