export enum DefectSeverity {
    MINOR = 'minor',
    MAJOR = 'major',
    CRITICAL = 'critical',
}

export enum DefectType {
    SELMA = 'SELMA',
    PSI = 'PSI',
}

export enum DefectSubtype {
    VISUAL_INSPECTION = 'Visual Inspection',
    IV_MEASURMENT = 'I-V measurement',
    EL_IMAGING = 'EL imaging',
}

export enum DefectSeverityColor {
    MINOR = '#ffd966',
    MAJOR = '#ff9900',
    CRITICAL = '#cc4125',
    UNKNOWN = '#b8b8b8',
}
