import { FunctionComponent, useMemo } from 'react';
import { Button, Dropdown, type MenuProps } from 'antd';
import { useCan, useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { type MenuInfo } from 'rc-menu/lib/interface';
import { type MenuItemType } from 'antd/lib/menu/interface';
import { MoreOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import onClickCapture from '@/utils/onClickCapture';

export type Props = {
    tBase: string;
    items?: MenuItemType[];
    resource: string;
    entityId: any;
    showEdit?: boolean;
    showView?: boolean;
    showDelete?: boolean;
    onMenuItemClick?: (key: string, entityId: any) => void;
};

const TableItemActionsMenu: FunctionComponent<Props> = ({
    tBase,
    entityId,
    items,
    resource,
    showEdit,
    showView,
    showDelete,
    onMenuItemClick,
}) => {
    const t = useTranslate();
    const { mutate } = useDelete();
    const { show, edit } = useNavigation();

    const onMenuClick: MenuProps['onClick'] = ({ domEvent, key }: MenuInfo) => {
        domEvent.stopPropagation();

        switch (key) {
            case 'show':
                show(resource, entityId);
                break;

            case 'edit':
                edit(resource, entityId);
                break;

            case 'delete':
                mutate({ resource, id: entityId, mutationMode: 'undoable' });
                break;
        }

        onMenuItemClick?.(key, entityId);
    };

    const { data: { can: canView } = {} } = useCan({
        resource,
        action: 'show',
        params: { id: entityId },
    });

    const { data: { can: canEdit } = {} } = useCan({
        resource,
        action: 'edit',
        params: { id: entityId },
    });

    const { data: { can: canDelete } = {} } = useCan({
        resource,
        action: 'delete',
        params: { id: entityId },
    });

    const menuItems = useMemo(() => {
        const newMenuItems = items ? [...items] : [];

        if (showView) {
            newMenuItems.push({
                key: 'show',
                type: 'item',
                label: t(`${tBase}.menu.show`),
                icon: <EyeOutlined />,
                disabled: !canView,
            });
        }

        if (showEdit) {
            newMenuItems.push({
                key: 'edit',
                type: 'item',
                label: t(`${tBase}.menu.edit`),
                icon: <EditOutlined />,
                disabled: !canEdit,
            });
        }

        if (showDelete) {
            newMenuItems.push({
                key: 'delete',
                type: 'item',
                label: t(`${tBase}.menu.delete`),
                icon: <DeleteOutlined />,
                disabled: !canDelete,
            });
        }

        return newMenuItems;
    }, [items, showView, showEdit, showDelete, canView, canEdit, canDelete, tBase, t]);

    return (
        <Dropdown
            trigger={['click']}
            menu={{
                items: menuItems,
                onClick: onMenuClick,
            }}
        >
            <Button
                shape='circle'
                type='text'
                size='small'
                icon={<MoreOutlined />}
                onClick={onClickCapture}
            />
        </Dropdown>
    );
};

export default TableItemActionsMenu;
