import { useGetIdentity } from '@refinedev/core';

import { Typography, Space, Grid, Row, Col, Layout } from 'antd';

import UserAvatar from '@/components/UserAvatar';
// import LocaleSelector from '@/components/LocaleSelector';

import formatUserFullname from '@/utils/user/formatUserFullname';
import type { IUser } from '@/interfaces/users';

const { useBreakpoint } = Grid;

export const Header: React.FC = () => {
    const { data: user } = useGetIdentity<IUser>();
    const screens = useBreakpoint();
    const fullName = formatUserFullname(user);

    return (
        <Layout.Header
            style={{
                padding: '0 24px',
                background: 'white',
            }}
        >
            <Row
                align='middle'
                style={{
                    justifyContent: screens.sm ? 'space-between' : 'end',
                }}
            >
                <Col xs={0} sm={12}></Col>
                <Col>
                    <Space size='middle' align='center'>
                        {/* <LocaleSelector /> */}
                        <Typography.Text
                            ellipsis
                            strong
                            style={{
                                display: 'flex',
                            }}
                        >
                            {fullName}
                        </Typography.Text>
                        <UserAvatar user={user} size='default' />
                    </Space>
                </Col>
            </Row>
        </Layout.Header>
    );
};
