import { type FunctionComponent, useMemo } from 'react';
import { TextField } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';

import { DEFAULT_NA_VALUE } from '@/constants';

import type { FactoryBrandScore } from '@/interfaces/factoryBrands';

export type Props = {
    value?: FactoryBrandScore;
};

const ScoresFieldDisplay: FunctionComponent<Props> = ({ value }) => {
    const t = useTranslate();

    // In case `scores` array is empty `lastScore` contains
    // { year: null, quarter: null, value: null }
    const text = useMemo(
        () =>
            [value?.quarter ? t(`quarters.${value.quarter}q`) : null, value?.year]
                .filter(Boolean)
                .join('-') || DEFAULT_NA_VALUE,
        [value, t],
    );

    return <TextField value={text} />;
};

export default ScoresFieldDisplay;
