import type { IInspection } from '@/interfaces/inspections';
import { InspectionResult, InspectionResultColor } from '@/interfaces/enums/inspections';

export const colors: Record<InspectionResult | 'unknown', InspectionResultColor> = {
    [InspectionResult.FAIL]: InspectionResultColor.FAIL,
    [InspectionResult.INCONCULSIVE]: InspectionResultColor.INCONCULSIVE,
    [InspectionResult.PASS]: InspectionResultColor.PASS,
    unknown: InspectionResultColor.UNKNOWN,
};

const getInspectionResultColor = (result: IInspection['result']) => {
    return (result != null && colors[result]) || colors.unknown;
};

export default getInspectionResultColor;
