import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';

const useFactoriesOptions = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetFactories } = {} } = useCan({
        resource: 'factories',
        action: 'get',
    });
    const { data } = useCustom<IFactory[]>({
        url: `${apiUrl}/factories`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetFactories,
        },
    });

    const factories = data?.data;

    return useMemo(
        () =>
            factories?.map(
                (item): AntdSelectOption<IFactory> => ({
                    value: item.id,
                    label: item.name,
                    item,
                }),
            ) ?? [],
        [factories],
    );
};

export default useFactoriesOptions;
