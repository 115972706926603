import downloadFile from '@/utils/downloadFile';
import getInspectionFlashFileName from './getInspectionFlashFileName';
import type { IInspection } from '@/interfaces/inspections';

const downloadInspectionFlashFile = (inspection?: IInspection) => {
    if (inspection && inspection.isFlashDataUploaded && inspection.flashDataDownloadingUrl) {
        downloadFile(inspection.flashDataDownloadingUrl, getInspectionFlashFileName(inspection));
    }
};

export default downloadInspectionFlashFile;
