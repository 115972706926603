import { type FunctionComponent, useMemo, useCallback } from 'react';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { Col, Row } from 'antd';
import { AuditOutlined, FileSearchOutlined } from '@ant-design/icons';

import ChartCard from './ChartCard';

import type {
    IDashboardInspectionChartItem,
    IDashboardAuditChartItem,
} from '@/interfaces/dashboard';
import { InspectionResultColor } from '@/interfaces/enums/inspections';
import { AuditScoreResultColor } from '@/interfaces/enums/audits';

export type Props = {};

const tBase = 'dashboard.charts';

const inspectionColors = Object.values(InspectionResultColor);
const auditColors = Object.values(AuditScoreResultColor);

const DashboardCharts: FunctionComponent<Props> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();

    const { data: inspectionChartResponse } = useCustom<IDashboardInspectionChartItem[]>({
        url: `${apiUrl}/inspections/dashboard/chart`,
        method: 'get',
    });

    const { data: auditChartResponse } = useCustom<IDashboardAuditChartItem[]>({
        url: `${apiUrl}/audits/dashboard/chart`,
        method: 'get',
    });

    const inspectionChartData = inspectionChartResponse?.data;
    const inspectionChartItems = useMemo(
        () =>
            inspectionChartData?.map(({ count, result }) => ({ value: count, name: result })) ?? [],
        [inspectionChartData],
    );

    const auditChartData = auditChartResponse?.data;
    const auditChartItems = useMemo(
        () =>
            auditChartData?.map(({ count, scoreResult }) => ({
                value: count,
                name: scoreResult,
            })) ?? [],
        [auditChartData],
    );

    const inspectionLabelFormatter = useCallback(
        (label: string) => t(`inspections.result.${label}`),
        [t],
    );

    const auditLabelFormatter = useCallback(
        (label: string) => t(`audits.scoreResult.${label}`),
        [t],
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={12}>
                <ChartCard
                    cardTitle={t(`${tBase}.inspections.cardTitle`)}
                    title={t(`${tBase}.inspections.title`)}
                    items={inspectionChartItems}
                    Icon={FileSearchOutlined}
                    colors={inspectionColors}
                    legendLabelFormatter={inspectionLabelFormatter}
                />
            </Col>
            <Col xs={12}>
                <ChartCard
                    cardTitle={t(`${tBase}.audits.cardTitle`)}
                    title={t(`${tBase}.audits.title`)}
                    items={auditChartItems}
                    Icon={AuditOutlined}
                    colors={auditColors}
                    legendLabelFormatter={auditLabelFormatter}
                />
            </Col>
        </Row>
    );
};

export default DashboardCharts;
