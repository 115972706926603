import { AuditNcsRateResultColor } from '@/interfaces/enums/audits';

export const colors: { threshold: number; color: AuditNcsRateResultColor }[] = [
    { threshold: 60, color: AuditNcsRateResultColor.BAD },
    { threshold: 80, color: AuditNcsRateResultColor.AVERAGE },
    { threshold: 90, color: AuditNcsRateResultColor.GOOD },
];

const getAuditNcRateResultColor = (rate?: number) => {
    if (rate == null) {
        return AuditNcsRateResultColor.UNKNOWN;
    }

    for (let { threshold, color } of colors) {
        if (rate < threshold) {
            return color;
        }
    }

    return AuditNcsRateResultColor.EXCELLENT;
};

export default getAuditNcRateResultColor;
