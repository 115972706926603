import { useRef, useEffect, type FunctionComponent, useState } from 'react';
import mapboxgl, { Marker, Map, NavigationControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { MAPBOX_DEFAULT_CENTER, MAPBOX_DEFAULT_ZOOM, MAPBOX_TOKEN } from '@/constants';

import type { SimpleLngLat } from '@/interfaces';

mapboxgl.accessToken = MAPBOX_TOKEN;

export type Props = {
    defaultLocation?: SimpleLngLat;
    markers?: SimpleLngLat[];
    className?: string;
};

const MarkersMap: FunctionComponent<Props> = ({ defaultLocation, markers, className }) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<Map | undefined>();

    useEffect(() => {
        if (!map || !markers) {
            return;
        }

        const placedMarkers = markers.map((marker) => {
            const placedMarker = new Marker({ draggable: false });
            placedMarker.setLngLat(marker);

            try {
                placedMarker.addTo(map);
            } catch (error) {
                console.error(error);
            }
            return placedMarker;
        });

        return () => {
            placedMarkers.forEach((marker) => marker.remove());
        };
    }, [map, markers]);

    useEffect(() => {
        if (!mapRef.current) {
            return;
        }

        const map = new Map({
            container: mapRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: defaultLocation ?? MAPBOX_DEFAULT_CENTER,
            zoom: defaultLocation ? 16 : MAPBOX_DEFAULT_ZOOM,
        });

        map.addControl(new NavigationControl(), 'bottom-right');

        let isAborted = false;
        map.on('load', () => {
            if (!isAborted) {
                setMap(map);
            }
        });

        return () => {
            isAborted = true;
            map.remove();
            setMap(undefined);
        };
    }, [defaultLocation]);

    return <div ref={mapRef} className={className} />;
};

export default MarkersMap;
