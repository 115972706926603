import { type FormValues } from '@/pages/factories/form';

const formValuesToFactory = (values: FormValues): FormValues => {
    return {
        name: values.name,
        address: values.address,
        city: values.city,
        country: values.country,
        longitude: values.longitude,
        latitude: values.latitude,
        brandId: values.brandId,
        mainProductTypeId: values.mainProductTypeId,
    };
};

export default formValuesToFactory;
