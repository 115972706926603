import type { IAudit } from '@/interfaces/audits';
import { AuditScoreResult, AuditScoreResultColor } from '@/interfaces/enums/audits';

export const colors: Record<AuditScoreResult | 'unknown', AuditScoreResultColor> = {
    [AuditScoreResult.EXCELLENT]: AuditScoreResultColor.EXCELLENT,
    [AuditScoreResult.GOOD]: AuditScoreResultColor.GOOD,
    [AuditScoreResult.AVERAGE]: AuditScoreResultColor.AVERAGE,
    [AuditScoreResult.BAD]: AuditScoreResultColor.BAD,
    unknown: AuditScoreResultColor.UNKNOWN,
};

const getAuditScoreResultColor = (result?: IAudit['scoreResult']) => {
    return (result != null && colors[result]) || colors.unknown;
};

export default getAuditScoreResultColor;
