import { type LngLatLike } from 'mapbox-gl';
import dayjs from 'dayjs';

import { DefectSubtype, DefectType } from '@/interfaces/enums/defects';

export const PROFILE_STORAGE_KEY = 'sp-profile';
export const ACCESS_TOKEN_STORAGE_KEY = 'sp-access-token';
export const REFRESH_TOKEN_STORAGE_KEY = 'sp-refresh-token';
export const TABLE_SETTINGS_STORAGE_KEY = 'sp-table-settings';
export const TOKEN_EXPIRATION_TOLERANCE = 60;

if (!process.env.REACT_APP_BACKEND_URL) {
    throw new Error('Environment variable "REACT_APP_BACKEND_URL" is not provided');
}
export const API_ROOT = `${
    process.env.REACT_APP_BACKEND_URL || 'https://api-test-crm.selma.sinovoltaics.com'
}/api/v1`;

if (!process.env.REACT_APP_MAPBOX_TOKEN) {
    throw new Error('Environment variable "REACT_APP_MAPBOX_TOKEN" is not provided');
}
export const MAPBOX_TOKEN = `${process.env.REACT_APP_MAPBOX_TOKEN}`;
export const MAPBOX_DEFAULT_CENTER: LngLatLike = [0, 0];
export const MAPBOX_DEFAULT_ZOOM = 1;
export const MAPBOX_DEFAULT_LOCALE = 'en-US';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_DAY = 'YYYY-MM-DD';

export const PROPERTIES_MAX_DEPTH = 5;

export const DEFECT_TYPES: { id: string; text: DefectType }[] = [
    { id: '69f970d8-d132-404e-b5b8-084f376dceb4', text: DefectType.SELMA },
    { id: '4c5c2061-aa45-4888-aad6-6033c1caedaa', text: DefectType.PSI },
];

export const DEFECT_SUB_TYPES: { id: string; text: DefectSubtype }[] = [
    { id: '6e60afa3-423a-4f77-add2-0837cd3fa95e', text: DefectSubtype.VISUAL_INSPECTION },
    { id: 'f5d5f1dc-d571-48db-bdb8-a20facf9eafc', text: DefectSubtype.IV_MEASURMENT },
    { id: '6de7549a-b338-4ac9-a890-c975e5dac4b9', text: DefectSubtype.EL_IMAGING },
];

export const PRODUCT_TYPE_PV_MODULES_ID = 'c9826e4a-8544-425c-9bd7-0e08bca73947';

export const DEFECT_TYPE_PSI_ID = '4c5c2061-aa45-4888-aad6-6033c1caedaa';

// react-query's micro cache life time, ms
export const REACT_QUERY_MICRO_CACHE_TTL = 30000;

/** @todo Is this correct? */
export const MAX_EMAIL_LENGTH = 250;

export const MAX_NAME_LENGTH = 250;

export const MIN_PASSWORD_LENGTH = 6;

export const DEFAULT_NA_VALUE = 'N/A';

export const DEFECT_CHART_MAX_ITEMS = 22;

export const DEFAULT_MIN_DATE = dayjs('1900-01-01T00:00:00Z');
