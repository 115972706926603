import { useMemo } from 'react';
import { useTranslate } from '@refinedev/core';

import type { AntdSelectOption } from '@/interfaces';
import { AuditStatus } from '@/interfaces/enums/audits';

const useAuditStatusOptions = () => {
    const t = useTranslate();

    return useMemo(
        () =>
            Object.values(AuditStatus).map(
                (status): AntdSelectOption => ({
                    value: status,
                    label: t(`audits.auditStatus.${status}`),
                }),
            ),
        [t],
    );
};

export default useAuditStatusOptions;
