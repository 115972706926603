import type { IInspection } from '@/interfaces/inspections';

const getInspectionReworkRate = (inspection?: IInspection) => {
    if (inspection?.totalReworks == null || inspection.totalProductionInput == null) {
        return;
    }

    return Math.min(
        100,
        Math.max(
            0,
            inspection.totalProductionInput
                ? (inspection.totalReworks / inspection.totalProductionInput) * 100
                : 0,
        ),
    );
};

export default getInspectionReworkRate;
