import React from 'react';
import routerProvider from '@refinedev/react-router-v6/legacy';
import clsx from 'clsx';

import { ReactComponent as LogoSvg } from '@/assets/sinovoltaics-logo-sidebar.svg';

import c from './Title.module.css';

const { Link } = routerProvider;

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    return (
        <Link to='/' className='w-full d-block overflow-hidden'>
            <LogoSvg className={clsx(c.logo, collapsed ? c.collapsed : c.expanded)} />
        </Link>
    );
};
