import { Fragment, useCallback, useMemo, useState, type FunctionComponent } from 'react';
import { Table, Space, Row, Col } from 'antd';
import {
    useTranslate,
    type IResourceComponentsProps,
    type HttpError,
    useNavigation,
    useCan,
} from '@refinedev/core';
import { List, TextField, useTable, EditButton } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import ProjectFilters from './ProjectFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onSearch, { type SearchParams } from './ProjectFilters/onSearch';
import onClickCapture from '@/utils/onClickCapture';
import { errorNotification } from '@/notifications';
import type { TableColumn } from '@/interfaces';
import { type QueryHaving } from '@/utils/dataProvider';
import type { IProjectListItem } from '@/interfaces/projects';
import type { IProductType } from '@/interfaces/productTypes';
import type { ICompany } from '@/interfaces/companies';

type ProjectColumns = TableColumn<IProjectListItem, keyof IProjectListItem | 'actions'>[];

const tBase = 'projects.list';

export const ProjectsList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const [having, setHaving] = useState<QueryHaving[] | undefined>(undefined);
    const [havingTemp, setHavingTemp] = useState<QueryHaving[] | undefined>(undefined);
    const { data: { can: canCreate } = {} } = useCan({ resource: 'projects', action: 'create' });
    const { data: { can: canEdit } = {} } = useCan({ resource: 'projects', action: 'edit' });
    const { data: { can: canDelete } = {} } = useCan({ resource: 'projects', action: 'delete' });
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const { tableProps, searchFormProps, setFilters, setCurrent } = useTable<
        IProjectListItem,
        HttpError,
        SearchParams
    >({
        resource: 'projects/summary',
        errorNotification,
        meta: { having },
        sorters: {
            initial: [{ field: 'name', order: 'asc' }],
        },
    });

    const onFinish = useCallback(
        (values: SearchParams) => {
            setFilters?.(onSearch(values));
            setHaving?.(havingTemp);
            setCurrent?.(1);
        },
        [havingTemp, setFilters, setCurrent],
    );

    const getTableKey = (entity: IProjectListItem) => {
        const pieces = [entity.id, entity.clientId, entity.productTypeId];
        return pieces.filter(Boolean).join('/');
    };

    const columns: ProjectColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: IProjectListItem['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'productTypeName',
                render: (value?: IProductType['name']['value']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'uomTotalNormalized',
                render: (_value: IProjectListItem['uomTotalNormalized'], entity) => (
                    <TextField
                        value={
                            entity.uomTotalNormalized == null
                                ? DEFAULT_NA_VALUE
                                : `${entity.uomTotalNormalized}${entity.uomName ? ` ${entity.uomName}` : ''}`
                        }
                    />
                ),
                sorter: true,
            },
            {
                name: 'selmaTotalInspect',
                render: (value?: IProjectListItem['selmaTotalInspect']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'clientName',
                render: (value: ICompany['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                show: canGetCompanies,
                sorter: true,
            },
            {
                name: 'actions',
                render: (_value: void, entity) => (
                    <Space>
                        <EditButton
                            hideText={true}
                            size='small'
                            disabled={!canEdit}
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />
                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            errorNotification={errorNotification}
                            disabled={!canDelete}
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [canEdit, canDelete, canGetCompanies],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'projects',
    );

    return (
        <List
            title={t('projects.titles.list')}
            canCreate={canCreate}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col xs={6}>
                    <ProjectFilters
                        formProps={{ ...searchFormProps, onFinish }}
                        onHavingChange={setHavingTemp}
                    />
                </Col>
                <Col xs={18}>
                    <Table
                        {...tableProps}
                        rowKey={getTableKey}
                        rowClassName='cursor-pointer'
                        className='table-with-header-cell-nowrap'
                        onRow={(entity) => {
                            return {
                                onClick: () => show('projects', entity.id),
                            };
                        }}
                    >
                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return (
                                <Table.Column
                                    {...columnProps}
                                    dataIndex={name}
                                    key={key}
                                    hidden={hidden ?? !settings[key]}
                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                />
                            );
                        })}
                    </Table>
                </Col>
            </Row>
        </List>
    );
};
