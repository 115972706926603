import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Create } from '@refinedev/antd';

import AuditForm from './form';

import c from './form/AuditForm.module.css';

export const AuditsCreate: FunctionComponent<IResourceComponentsProps> = () => {
    return (
        <Create wrapperProps={{ className: c.form }} footerButtons={() => null}>
            <AuditForm />
        </Create>
    );
};
