import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Create } from '@refinedev/antd';

import InspectionForm from './form';

import c from './form/InspectionForm.module.css';

export const InspectionsCreate: FunctionComponent<IResourceComponentsProps> = () => {
    return (
        <Create footerButtonProps={{ className: c.footer }} footerButtons={() => null}>
            <InspectionForm
            // formProps={{
            //     ...formProps,
            //     initialValues: {
            //         productModels: [{ name: '', output: '' }],
            //     },
            // }}
            />
        </Create>
    );
};
