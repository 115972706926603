import { type AuthProvider } from '@refinedev/core';

import isUserAdmin from '@/utils/user/isUserAdmin';
import isUserOperator from '@/utils/user/isUserOperator';
import isTokenActive from '@/utils/auth/isTokenActive';
import login from '@/utils/auth/login';
import fetchProfile from '@/utils/auth/fetchProfile';
import persistUserData from '@/utils/auth/persistUserData';
import removeUserData from '@/utils/auth/removeUserData';
import refreshAccessToken from '@/utils/auth/refreshAccessToken';
import getProfile from '@/utils/auth/getProfile';
import getAccessToken from '@/utils/auth/getAccessToken';
import getRefreshToken from '@/utils/auth/getRefreshToken';
import type { UserPermissions } from '@/interfaces/auth';

const authProvider: AuthProvider = {
    async login({ username, password }) {
        let errorMessage: string | undefined;
        try {
            const tokenData = await login(username, password);
            if (!tokenData) {
                throw new Error('Failed to log you in');
            }
            const profileData = await fetchProfile(tokenData.accessToken);
            if (!profileData) {
                throw new Error('Failed to fetch profile data');
            }

            persistUserData(tokenData, profileData);
        } catch (error) {
            errorMessage = (error as Error).message;
            if (errorMessage === 'Failed to fetch') {
                errorMessage = 'Login failed. Please check your internet connection.';
            }
        }

        if (errorMessage) {
            return {
                success: false,
                error: {
                    message: 'Login Error',
                    name: errorMessage,
                },
            };
        }

        return {
            success: true,
            redirectTo: '/',
        };
    },

    async logout() {
        removeUserData();
        return {
            success: true,
            redirectTo: '/login',
        };
    },

    async onError(error) {
        console.error(error);
        return {};
    },

    async check() {
        const accessToken = getAccessToken();
        if (isTokenActive(accessToken)) {
            return {
                authenticated: true,
            };
        }

        const refreshToken = getRefreshToken();
        if (refreshToken && isTokenActive(refreshToken)) {
            try {
                const tokenData = await refreshAccessToken(refreshToken);
                if (!tokenData) {
                    throw new Error('Failed to refresh token');
                }
                const profileData = await fetchProfile(tokenData.accessToken);
                if (!profileData) {
                    throw new Error('Failed to fetch profile data');
                }

                persistUserData(tokenData, profileData);
                return {
                    authenticated: true,
                };
            } catch (error) {
                console.error(error);
            }
        }

        return {
            authenticated: false,
            redirectTo: '/login',
            logout: true,
        };
    },

    async getPermissions(): Promise<UserPermissions> {
        const user = getProfile();

        return {
            user,
            isAdmin: isUserAdmin(user),
            isOperator: isUserOperator(user),
        };
    },

    async getIdentity() {
        return getProfile();
    },
};

export default authProvider;
