// Monkey-patching Nest.js CRUD data provider to add `having` to query params

import dataProvider from '@refinedev/nestjsx-crud';
import { type CrudOperators } from '@refinedev/core';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import {
    handleFilter,
    handlePagination,
    handleSort,
    handleJoin,
    mapOperator,
} from '@refinedev/nestjsx-crud';

import { API_ROOT } from '@/constants';

import httpClient from '@/utils/dataProvider/axios';

export type QueryHaving = {
    field: string;
    operator: CrudOperators;
    value: any;
};

const apiUrl = API_ROOT;

const customDataProvider = dataProvider(apiUrl, httpClient);

const handleHaving = (query: RequestQueryBuilder, having?: QueryHaving[]) => {
    if (having && Array.isArray(having)) {
        query.queryObject['having'] = having.map(({ field, operator, value }) =>
            JSON.stringify({
                [field]: {
                    [mapOperator(operator)]: value,
                },
            })
        );
    }

    return query;
};

customDataProvider.getList = async function ({ resource, pagination, filters, sorters, meta }) {
    const url = `${apiUrl}/${resource}`;

    let query = RequestQueryBuilder.create();
    query.setParamNames();

    query = handleFilter(query, filters);
    query = handleJoin(query, meta?.join);
    query = handlePagination(query, pagination);
    query = handleSort(query, sorters);
    query = handleHaving(query, meta?.having);

    const { data } = await httpClient.get(`${url}?${query.query()}`);

    return {
        data: data.data,
        total: data.total,
    };
};

export default customDataProvider;
