import { type FunctionComponent, Fragment } from 'react';

import DefectsField from '@/components/DefectsField';
import InspectionPsiBaseFields, {
    type FormValues as PsiBaseValues,
} from './InspectionPsiBaseFields';

import type { IDefect } from '@/interfaces/defects';
import type { IInspectionDefectValue } from '@/interfaces/inspections';
import { DefectType } from '@/interfaces/enums/defects';

import c from './InspectionFormPsi.module.css';

export type Props = {
    isPvModules: boolean;
    defects?: IDefect[];
};

export type FormValues = PsiBaseValues & {
    psiDefectsValues?: Record<IDefect['id'], IInspectionDefectValue>;
    psiDefects?: IInspectionDefectValue[];
};

const InspectionFormPsi: FunctionComponent<Props> = ({ isPvModules, defects }) => {
    return (
        <Fragment>
            <InspectionPsiBaseFields isPvModules={isPvModules} />
            <DefectsField defectType={DefectType.PSI} defects={defects} className={c.defects} />
        </Fragment>
    );
};

export default InspectionFormPsi;
