import type { IFactoryChartItem } from '@/interfaces/factories';

const getFactoriesChartData = (data?: IFactoryChartItem[]) => {
    return (
        data?.map(({ factoryName, quarter, value }) => ({
            xField: quarter,
            seriesField: factoryName,
            value,
        })) ?? []
    );
};

export default getFactoriesChartData;
