import { useMemo, type FunctionComponent } from 'react';
import { type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Show } from '@refinedev/antd';
import { Col, Row } from 'antd';

import MarkersMap from '@/components/MarkersMap';
import FactoryAudits from './FactoryAudits';
import FactoryInspections from './FactoryInspections';
import FactorySummaryCard from './FactorySummaryCard';
import FactoryProductionCard from './FactoryProductionCard';
import FactoryFinanceCard from './FactoryFinanceCard';

import type { SimpleLngLat } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';

import c from './FactoriesShow.module.css';

const tBase = 'factories.show';

export const FactoriesShow: FunctionComponent<IResourceComponentsProps> = () => {
    const { data: { can: canEdit } = {} } = useCan({ resource: 'factories', action: 'edit' });
    const {
        queryResult: { data, isLoading },
    } = useShow<IFactory>();
    const factory = data?.data;

    const longitude = factory?.longitude;
    const latitude = factory?.latitude;
    const markers = useMemo((): SimpleLngLat[] => {
        return longitude != null && latitude != null ? [[longitude, latitude]] : [];
    }, [longitude, latitude]);

    return (
        <Show
            canEdit={canEdit}
            contentProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
                styles: {
                    body: {
                        padding: 0,
                    },
                },
            }}
            isLoading={isLoading}
        >
            <Row gutter={[16, 16]} className={c.row}>
                <Col xs={12}>
                    <FactorySummaryCard factory={factory} tBase={tBase} />
                </Col>
                <Col xs={12}>
                    <MarkersMap markers={markers} defaultLocation={markers[0]} className={c.map} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className={c.row}>
                <Col xs={12}>
                    <FactoryProductionCard factory={factory} tBase={tBase} />
                </Col>
                <Col xs={12}>
                    <FactoryFinanceCard factory={factory} tBase={tBase} />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className={c.row}>
                <Col xs={12}>
                    <FactoryAudits factory={factory} tBase={tBase} />
                </Col>
                <Col xs={12}>
                    <FactoryInspections factory={factory} tBase={tBase} />
                </Col>
            </Row>
        </Show>
    );
};
