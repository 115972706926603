import { useEffect, useMemo, type FunctionComponent } from 'react';
import {
    Button,
    Card,
    DatePicker,
    Form,
    type FormProps,
    Input,
    Select,
    Space,
    InputNumber,
} from 'antd';
import { useCan, useTranslate } from '@refinedev/core';

import { DEFAULT_MIN_DATE } from '@/constants';

import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import useFactoryCountriesOptions from '@/hooks/options/useFactoryCountriesOptions';
import useFactoryCitiesOptions from '@/hooks/options/useFactoryCitiesOptions';
import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import { SearchParams } from './onSearch';
import { type QueryHaving } from '@/utils/dataProvider';
import type { FormField } from '@/interfaces';
import type { IProjectListItem } from '@/interfaces/projects';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

export type Props = {
    formProps: FormProps;
    onHavingChange: (having: QueryHaving[]) => void;
};

export type FormValues = Pick<
    IProjectListItem,
    'name' | 'clientId' | 'createdAt' | 'selmaTotalInspect'
> & {
    factoryBrandId: IFactoryBrand['id'];
    city: string;
    country: string;
};

const tBase = 'projects.filters';

const ProjectFilters: FunctionComponent<Props> = ({ formProps, onHavingChange }) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const formWatchParams = { form: formProps.form, preserve: true };
    const factoryBrandId: IFactoryBrand['id'] | undefined = Form.useWatch(
        'factoryBrandId',
        formWatchParams,
    );

    const factoryBrandsOptions = useFactoryBrandsOptions();
    const clientsOptions = useCompaniesOptions();
    const countryOptions = useFactoryCountriesOptions(factoryBrandId);
    const cityOptions = useFactoryCitiesOptions(factoryBrandId);

    const selmaTotalInspect: SearchParams['selmaTotalInspect'] = Form.useWatch(
        'selmaTotalInspect',
        formProps.form,
    );

    useEffect(() => {
        const having: QueryHaving[] = [];
        if (selmaTotalInspect?.min != null && selmaTotalInspect?.min !== '') {
            having.push({
                field: 'selmaTotalInspect',
                operator: 'gte',
                value: Number(selmaTotalInspect.min) ?? undefined,
            });
        }
        if (selmaTotalInspect?.max != null && selmaTotalInspect?.max !== '') {
            having.push({
                field: 'selmaTotalInspect',
                operator: 'lte',
                value: Number(selmaTotalInspect.max) ?? undefined,
            });
        }
        onHavingChange(having);
    }, [selmaTotalInspect, onHavingChange]);

    const fields: FormField<keyof FormValues>[] = useMemo(() => {
        const newFields: FormField<keyof FormValues>[] = [
            {
                name: 'name',
                field: (
                    <Input
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.name`)}
                        allowClear={true}
                    />
                ),
            },
            {
                name: 'factoryBrandId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryBrandId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                    />
                ),
            },
            {
                name: 'country',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.country`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={countryOptions}
                    />
                ),
            },
            {
                name: 'city',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.city`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={cityOptions}
                    />
                ),
            },
        ];
        if (canGetCompanies) {
            newFields.push({
                name: 'clientId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.clientId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={clientsOptions}
                    />
                ),
            });
        }
        newFields.push({
            name: 'createdAt',
            field: (
                <DatePicker.RangePicker
                    minDate={DEFAULT_MIN_DATE}
                    allowClear={true}
                    className='w-full'
                />
            ),
        });

        return newFields;
    }, [factoryBrandsOptions, clientsOptions, cityOptions, countryOptions, canGetCompanies, t]);

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            label={t(`${tBase}.fields.${name}`)}
                            name={name}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item key='selmaTotalInspect' label={t(`${tBase}.fields.selmaTotalInspect`)}>
                    <Space.Compact className='w-full'>
                        <Form.Item name={['selmaTotalInspect', 'min']} className='flex-grow-1'>
                            <InputNumber
                                type='number'
                                placeholder={t(`${tBase}.placeholders.selmaTotalInspectMin`)}
                                min={0}
                                precision={0}
                                autoComplete='off'
                                controls={false}
                                changeOnWheel={false}
                                className='w-full'
                            />
                        </Form.Item>
                        <Form.Item name={['selmaTotalInspect', 'max']} className='flex-grow-1'>
                            <InputNumber
                                type='number'
                                placeholder={t(`${tBase}.placeholders.selmaTotalInspectMax`)}
                                min={0}
                                precision={0}
                                autoComplete='off'
                                controls={false}
                                changeOnWheel={false}
                                className='w-full'
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ProjectFilters;
