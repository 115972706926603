import { type FunctionComponent } from 'react';
import { Checkbox, Form } from 'antd';
import { useTranslate } from '@refinedev/core';
import clsx from 'clsx';

import type { IAuditKeyItemInfo } from '@/interfaces/audits';

import c from './AuditKeyItemsField.module.css';

export type Props = {
    disabled?: boolean;
    keyItems?: IAuditKeyItemInfo[];
    className?: string;
};

const AuditKeyItemsField: FunctionComponent<Props> = ({ keyItems, disabled, className }) => {
    const t = useTranslate();

    return (
        <div className={clsx('d-flex column', c.fields, className)}>
            {keyItems?.map(({ id, name }) => (
                <Form.Item
                    key={id}
                    name={['keyItemsValues', id]}
                    noStyle={true}
                    valuePropName='checked'
                    className='d-flex flex-row justify-between'
                >
                    <Checkbox title={t(`audits.keyItems.${name}`, name)} disabled={disabled}>
                        {name}
                    </Checkbox>
                </Form.Item>
            ))}
        </div>
    );
};

export default AuditKeyItemsField;
