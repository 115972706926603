import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { IAuditKeyItemInfo, IAuditType } from '@/interfaces/audits';
import type { IProductType } from '@/interfaces/productTypes';
import type { ICompany } from '@/interfaces/companies';

const useAuditKeyItems = (auditTypeId?: IAuditType['id'], productTypeId?: IProductType['id'], clientId?: ICompany['id'] ) => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetAudit } = {} } = useCan({
        resource: 'audits',
        action: 'get',
    });
    const { data } = useCustom<IAuditKeyItemInfo[]>({
        url: `${apiUrl}/audits/key-items`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetAudit,
        },
        config: {
            query: {
              type: auditTypeId,
              product: productTypeId,
              client: clientId,
            },
        },
    });

    return data?.data;
};

export default useAuditKeyItems;
