import { Fragment, useMemo, type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    type HttpError,
    useCan,
} from '@refinedev/core';
import { List, useTable, EditButton, TextField } from '@refinedev/antd';
import { Table, Space, Col, Row } from 'antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import PropertyDataDisplay from '@/components/PropertyDataDisplay';
import UnitsOfMeasureDisplay from '@/components/UnitOfMeasureDisplay';
import ProductTypesFilters from './ProductTypesFilters';

import useTableSettings from '@/hooks/useTableSettings';
import { errorNotification } from '@/notifications';
import onClickCapture from '@/utils/onClickCapture';
import { onSearch, type SearchParams } from './filterLogic';
import type { TableColumn, UnitOfMeasure } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';

type ProductTypeColumns = TableColumn<
    IProductType,
    keyof IProductType | 'technologies' | 'actions' | ['name', 'value']
>[];

const tBase = 'productTypes.list';

export const ProductTypesList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({
        resource: 'productTypes',
        action: 'create',
    });
    const { data: { can: canEdit } = {} } = useCan({ resource: 'productTypes', action: 'edit' });
    const { data: { can: canDelete } = {} } = useCan({
        resource: 'productTypes',
        action: 'delete',
    });

    const { tableProps, searchFormProps } = useTable<IProductType, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'name.value', order: 'asc' }],
        },
    });

    const columns: ProductTypeColumns = useMemo(
        () => [
            {
                name: ['name', 'value'],
                render: (value?: IProductType['name']['value']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'technologies',
                render: (_value: void, entity) => (
                    <PropertyDataDisplay propertyId='product_tech' properties={entity.properties} />
                ),
                sorter: false,
            },
            {
                name: 'uoms',
                render: (value?: UnitOfMeasure[]) => <UnitsOfMeasureDisplay value={value} />,
                sorter: false,
            },
            {
                name: 'actions',
                render: (_value: void, entity) => (
                    <Space>
                        {canEdit && (
                            <EditButton
                                hideText={true}
                                size='small'
                                recordItemId={entity.id}
                                onClickCapture={onClickCapture}
                            />
                        )}
                        {canDelete && (
                            <CustomDeleteButton
                                hideText={true}
                                size='small'
                                recordItemId={entity.id}
                                mutationMode='undoable'
                                onClick={onClickCapture}
                            />
                        )}
                    </Space>
                ),
            },
        ],
        [canEdit, canDelete],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'product-types',
    );

    return (
        <List
            title={t('productTypes.titles.list')}
            resource='productTypes'
            canCreate={canCreate}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={6}>
                    <ProductTypesFilters formProps={searchFormProps} />
                </Col>
                <Col xs={24} sm={16} md={18}>
                    <Table
                        {...tableProps}
                        rowKey='id'
                        rowClassName='cursor-pointer'
                        className='table-with-header-cell-nowrap'
                        onRow={(entity) => {
                            return {
                                onClick: () => show('productTypes', entity.id),
                            };
                        }}
                    >
                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return (
                                <Table.Column
                                    {...columnProps}
                                    dataIndex={name}
                                    key={key}
                                    hidden={hidden ?? !settings[key]}
                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                />
                            );
                        })}
                    </Table>
                </Col>
            </Row>
        </List>
    );
};
