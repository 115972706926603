import { type FunctionComponent, useMemo, Fragment } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    useCan,
    type HttpError,
} from '@refinedev/core';
import { List, TextField, useTable, EditButton, DateField } from '@refinedev/antd';
import { Table, Col, Row, Space } from 'antd';

import { DATE_FORMAT, DEFAULT_NA_VALUE } from '@/constants';

import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import CompaniesFilters from './CompaniesFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import { errorNotification } from '@/notifications';
import onSearch, { type SearchParams } from './CompaniesFilters/onSearch';
import type { TableColumn } from '@/interfaces';
import type { ICompany } from '@/interfaces/companies';

type CompanyColumns = TableColumn<ICompany, keyof ICompany | 'actions'>[];

const tBase = 'companies.list';

export const CompaniesList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'companies', action: 'create' });

    const { tableProps, searchFormProps } = useTable<ICompany, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'createdAt', order: 'desc' }],
        },
    });

    const columns: CompanyColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: ICompany['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'createdAt',
                sorter: true,
                render: (value?: ICompany['createdAt']) =>
                    value ? <DateField value={value} format={DATE_FORMAT} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'updatedAt',
                sorter: true,
                render: (value?: ICompany['updatedAt']) =>
                    value ? <DateField value={value} format={DATE_FORMAT} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'actions',
                render: (_value: void, entity: ICompany) => (
                    <Space>
                        <EditButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />

                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'companies',
    );

    return (
        <List
            title={t('companies.titles.list')}
            canCreate={canCreate}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <CompaniesFilters formProps={searchFormProps} />
                        </Col>
                        <Col span={18}>
                            <Table
                                {...tableProps}
                                rowKey='id'
                                rowClassName='cursor-pointer'
                                className='table-with-header-cell-nowrap'
                                onRow={(entity) => {
                                    return {
                                        onClick: () => show('companies', entity.id),
                                    };
                                }}
                            >
                                {columns.map(({ name, title, hidden, ...columnProps }) => {
                                    const key = Array.isArray(name) ? name.join('.') : name;
                                    return (
                                        <Table.Column
                                            {...columnProps}
                                            dataIndex={name}
                                            key={key}
                                            hidden={hidden ?? !settings[key]}
                                            title={title ?? t(`${tBase}.fields.${key}`)}
                                        />
                                    );
                                })}
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List>
    );
};
