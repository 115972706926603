import { useCallback } from 'react';
import { useNotification, useApiUrl, useTranslate } from '@refinedev/core';
import { type UploadFile } from 'antd';
import axios from 'axios';

import axiosInstance from '@/utils/dataProvider/axios';

export type FileFieldInfo = {
    name: string;
    urlKey: string;
    flagName: string;
};

export type Params = {
    resource: string;
    fields: FileFieldInfo[];
    tBase: string;
};

type FileValues = Record<string, UploadFile[] | undefined>;

type FileFlags = Record<string, boolean | undefined>;

/** @todo It would be much better to move this to BE */
const useUploadFilesToAmazonService = ({ resource, fields, tBase }: Params) => {
    const apiUrl = useApiUrl();
    const t = useTranslate();
    const { open } = useNotification();

    const uploadFile = useCallback(
        async (
            entityId: string | number,
            field: FileFieldInfo,
            values?: FileValues,
            flags?: FileFlags,
        ) => {
            const value = values?.[field.name];
            // File was uploaded but user deleted it
            if (!value?.length && flags?.[field.flagName] === true) {
                return false;
            }

            const data = value?.[0];
            // File has not changed or was not provided
            if (data == null || data.uid === field.name) {
                return null;
            }

            const file = data?.originFileObj;
            // Something went wrong with the file user provided
            if (!file) {
                const defaultMessage = `Failed to load provided file for ${resource}/${entityId}/${field.urlKey}`;
                console.error(defaultMessage);
                open?.({
                    type: 'error',
                    message: t(`${tBase}.upload.errors.${field.name}.getFile`, defaultMessage),
                });
                return null;
            }

            let uploadingUrl: string | undefined;
            try {
                const uploadingUrlResponse = await axiosInstance(
                    `${apiUrl}/${resource}/${entityId}/${field.urlKey}`,
                );
                uploadingUrl = uploadingUrlResponse.data.url;
            } catch (error) {
                console.error(error);
            }

            if (!uploadingUrl) {
                const defaultMessage = `Failed to get uploading url for ${resource}/${entityId}/${field.urlKey}`;
                console.error(defaultMessage);
                open?.({
                    type: 'error',
                    message: t(`${tBase}.upload.errors.${field.name}.getUrl`, defaultMessage),
                });
                return false;
            }

            let isFileUploaded: boolean = false;
            let s3ResponseData: any;
            try {
                const s3Response = await axios(uploadingUrl, { method: 'PUT', data: file });
                if (s3Response.statusText === 'OK') {
                    isFileUploaded = true;
                } else {
                    s3ResponseData = s3Response.data;
                }
            } catch (error) {
                console.error(error);
            }

            if (!isFileUploaded) {
                const defaultMessage = 'Failed to upload file';
                console.error(defaultMessage, s3ResponseData);
                open?.({
                    type: 'error',
                    message: t(`${tBase}.upload.errors.${field.name}.upload`),
                });
                return false;
            }

            return true;
        },
        [resource, apiUrl, tBase, t, open],
    );

    const uploadFiles = useCallback(
        async (entityId: string | number, values?: FileValues, flags?: FileFlags) => {
            if (entityId == null) {
                return;
            }

            const fileFlags: FileFlags = {};
            const result = await Promise.allSettled(
                fields.map((field) => uploadFile(entityId, field, values, flags)),
            );
            let hasUpdatedFiles = false;
            result.forEach((item, index) => {
                const field = fields[index];
                if (item.status === 'fulfilled' && item.value != null) {
                    fileFlags[field.flagName] = item.value;
                    hasUpdatedFiles = true;
                }
            });

            if (hasUpdatedFiles) {
                await axiosInstance(`${apiUrl}/${resource}/${entityId}`, {
                    method: 'PATCH',
                    data: fileFlags,
                });
            }
        },
        [fields, resource, apiUrl, uploadFile],
    );

    return uploadFiles;
};

export default useUploadFilesToAmazonService;
