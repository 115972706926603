import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import CompanyForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { ICompany } from '@/interfaces/companies';

export const CompaniesEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, onFinish } = useForm<ICompany, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Edit title={t('companies.titles.edit')} saveButtonProps={saveButtonProps}>
            <CompanyForm formProps={formProps} onFinish={onFinish} />
        </Edit>
    );
};
