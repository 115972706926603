import { useMemo, type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';
import { useCustom, useTranslate } from '@refinedev/core';
import { BarChartOutlined } from '@ant-design/icons';

import { API_ROOT, PRODUCT_TYPE_PV_MODULES_ID } from '@/constants';

import FactoryProduction from './FactoryProduction';

import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import type { IFactory, IFactoryHistogram } from '@/interfaces/factories';

import c from './FactoryProductionCard.module.css';

export type Props = {
    tBase: string;
    factory?: IFactory;
};

const FactoryProductionCard: FunctionComponent<Props> = ({ tBase, factory }) => {
    const t = useTranslate();

    const factoryTitle = useMemo(() => getFactoryTitle(factory), [factory]);

    const { data: histogramResponse } = useCustom<IFactoryHistogram[]>({
        url: `${API_ROOT}/factories/${factory?.id}/histogram`,
        method: 'get',
        config: {
            filters: [
                { field: 'summary.product_id', operator: 'eq', value: PRODUCT_TYPE_PV_MODULES_ID },
            ],
        },
        queryOptions: {
            enabled: factory?.id != null,
        },
    });

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <BarChartOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.monthlyProduction`, {
                            factoryTitle,
                        })}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <FactoryProduction data={histogramResponse?.data} />
        </Card>
    );
};

export default FactoryProductionCard;
