import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@refinedev/core';

import { TABLE_SETTINGS_STORAGE_KEY } from '@/constants';

import type { TableColumn, TableSettings, TableColumnsList } from '@/interfaces';

const useTableSettings = <
    Item extends Record<string, any>,
    Keys extends string | number | (string | number)[],
>(
    columns: TableColumn<Item, Keys>[],
    tBase: string,
    tableName: string,
    initial?: TableSettings,
) => {
    const t = useTranslate();
    const [settings, setSettings] = useState<TableSettings | undefined>(undefined);

    const savedSettings = useMemo(() => {
        let newSavedSettings: TableSettings = {};
        try {
            const settingsJson = localStorage.getItem(`${TABLE_SETTINGS_STORAGE_KEY}-${tableName}`);
            if (settingsJson) {
                newSavedSettings = JSON.parse(settingsJson);
                if (
                    !newSavedSettings ||
                    typeof newSavedSettings !== 'object' ||
                    Array.isArray(newSavedSettings)
                ) {
                    newSavedSettings = {};
                }
            }
        } catch (_error) {
            // Just swallowing errors
        }

        return newSavedSettings;
    }, [tableName]);

    const columnsList: TableColumnsList = useMemo(() => {
        return columns.map(({ name: columnName }) => {
            const name = Array.isArray(columnName) ? columnName.join('.') : `${columnName}`;
            return {
                name,
                label:
                    t(`${tBase}.tableSettings.fields.${name}`, '') || t(`${tBase}.fields.${name}`),
            };
        });
    }, [columns, tBase, t]);

    const defaultSettings = useMemo(() => {
        const newSettings: TableSettings = {};
        columnsList.forEach(({ name }) => {
            newSettings[name] = savedSettings[name] ?? initial?.[name] ?? true;
        });
        return newSettings;
    }, [columnsList, savedSettings, initial]);

    useEffect(() => {
        setSettings((prev) => {
            if (prev == null) {
                return defaultSettings;
            }

            const newSettings: TableSettings = {};
            columnsList.forEach(({ name }) => {
                newSettings[name] = prev[name] ?? defaultSettings[name];
            });

            return newSettings;
        });
    }, [columnsList, defaultSettings]);

    const onSettingsChange = useCallback(
        (updatedSettings: TableSettings) => {
            setSettings((prev) => {
                const newSettings: TableSettings = {};
                columnsList.forEach(({ name }) => {
                    newSettings[name] =
                        updatedSettings[name] ?? prev?.[name] ?? defaultSettings[name];
                });

                try {
                    localStorage.setItem(
                        `${TABLE_SETTINGS_STORAGE_KEY}-${tableName}`,
                        JSON.stringify(newSettings),
                    );
                } catch (_error) {
                    // Just swallowing errors
                }

                return newSettings;
            });
        },
        [columnsList, defaultSettings, tableName],
    );

    return { columnsList, settings: settings ?? defaultSettings, onSettingsChange };
};

export default useTableSettings;
