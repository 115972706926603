import { useMemo, type FunctionComponent } from 'react';
import { Col, Row, Table } from 'antd';
import { useNavigation, useTranslate } from '@refinedev/core';
import { TextField, useTable } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import AuditResultBadge from '@/components/AuditResultBadge';
import AuditNccaStatusBadge from '@/components/AuditNccaStatusBadge';

import useTableSettings from '@/hooks/useTableSettings';
import downloadAuditReport from '@/utils/audit/downloadAuditReport';
import downloadNccaAuditReport from '@/utils/audit/downloadNccaAuditReport';
import type { TableColumn } from '@/interfaces';
import type { IAudit } from '@/interfaces/audits';
import type { IProjectDisplay } from '@/interfaces/projects';
import type { IFactory } from '@/interfaces/factories';

export type Props = {
    tBase: string;
    project?: IProjectDisplay;
};

type AuditColumns = TableColumn<IAudit, keyof IAudit | ['factory', 'name']>[];

const ProjectAudits: FunctionComponent<Props> = ({ project, tBase }) => {
    const t = useTranslate();
    const { show } = useNavigation();

    const { tableProps } = useTable<IAudit>({
        resource: 'audits',
        filters: {
            permanent: [{ field: 'project.id', operator: 'eq', value: project?.id }],
        },
        sorters: {
            initial: [{ field: 'id', order: 'desc' }],
        },
        queryOptions: {
            enabled: project?.id != null,
        },
    });

    const columns: AuditColumns = useMemo(
        () => [
            {
                name: 'name',
                sorter: true,
                render: (value: IAudit['name']) => <TextField value={value || DEFAULT_NA_VALUE} />,
            },
            {
                name: ['factory', 'name'],
                sorter: true,
                render: (value?: IFactory['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'totalScore',
                sorter: true,
                render: (value: IAudit['totalScore'] | undefined, entity: IAudit) => (
                    <AuditResultBadge totalScore={value} scoreResult={entity.scoreResult} />
                ),
            },
            {
                name: 'nccaStatus',
                sorter: true,
                render: (value?: IAudit['nccaStatus']) => <AuditNccaStatusBadge status={value} />,
            },
            {
                name: 'reportDownloadingUrl',
                render: (_value: IAudit['reportDownloadingUrl'], entity) => (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.audits.list.buttons.downloadAuditReport`)}
                        disabled={!entity?.isReportUploaded || !entity?.reportDownloadingUrl}
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadAuditReport(entity);
                        }}
                    />
                ),
            },
            {
                name: 'nccaReportDownloadingUrl',
                render: (_value: IAudit['nccaReportDownloadingUrl'], entity) => (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.audits.list.buttons.downloadNccaAuditReport`)}
                        disabled={
                            !entity?.isNccaReportUploaded || !entity?.nccaReportDownloadingUrl
                        }
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadNccaAuditReport(entity);
                        }}
                    />
                ),
            },
        ],
        [tBase, t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        `${tBase}.audits.list`,
        'project-audits',
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} className='text-right hidden'>
                <TableSettingsButton
                    title={t(`${tBase}.audits.list.tableSettings.title`)}
                    columnsList={columnsList}
                    settings={settings}
                    onSettingsChange={onSettingsChange}
                />
            </Col>
            <Col xs={24}>
                <Table
                    {...tableProps}
                    rowKey='id'
                    rowClassName='cursor-pointer'
                    className='table-with-header-cell-nowrap'
                    onRow={(entity) => {
                        return {
                            onClick: () => show('audits', entity.id),
                        };
                    }}
                >
                    {columns.map(({ name, title, hidden, ...columnProps }) => {
                        const key = Array.isArray(name) ? name.join('.') : name;
                        return (
                            <Table.Column
                                {...columnProps}
                                dataIndex={name}
                                key={key}
                                hidden={hidden ?? !settings[key]}
                                title={title ?? t(`${tBase}.audits.list.fields.${key}`)}
                            />
                        );
                    })}
                </Table>
            </Col>
        </Row>
    );
};

export default ProjectAudits;
