import type { AuditNcChartItem } from '@/interfaces/audits';
import type { IAuditNCChart } from '@/interfaces/audits';
import { AuditNCCASeverity } from '@/interfaces/enums/audits';
import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';
import { useApiUrl, useCan, useCustom, CrudFilter } from '@refinedev/core';


const useAuditNcsChartItems = (filters?: CrudFilter[]) => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetAudit } = {} } = useCan({
        resource: 'audits',
        action: 'get',
    });

    const { data } = useCustom<IAuditNCChart[]>({
        url: `${apiUrl}/audits/ncs/chart`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetAudit,
        },
        config: { filters },
    });

    const result: AuditNcChartItem[] = [];
    
    data?.data.forEach((item) => {
      const ncs = [
        {
          name: item.name,
          severity: AuditNCCASeverity.MINOR,
          count: item.minor,
        },
        {
          name: item.name,
          severity: AuditNCCASeverity.MAJOR,
          count: item.major,
        },
        {
          name: item.name,
          severity: AuditNCCASeverity.CRITICAL,
          count: item.critical,
        }
      ];
      ncs.sort((a, b) => b.count - a.count);
      result.push(...ncs);
    });
    
    return result;
};

export default useAuditNcsChartItems;
