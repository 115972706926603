import { type FunctionComponent, Fragment } from 'react';

import DefectsField from '@/components/DefectsField';

import InspectionSelmaBaseFields, {
    type FormValues as SelmaBaseValues,
} from './InspectionSelmaBaseFields';

import type { IDefect } from '@/interfaces/defects';
import type { IInspectionDefectValue } from '@/interfaces/inspections';
import { DefectType } from '@/interfaces/enums/defects';

import c from './InspectionFormSelma.module.css';

export type Props = {
    defects?: IDefect[];
};

export type FormValues = SelmaBaseValues & {
    selmaDefectsValues?: Record<IDefect['id'], IInspectionDefectValue>;
    selmaDefects?: IInspectionDefectValue[];
};

const InspectionFormSelma: FunctionComponent<Props> = ({ defects }) => {
    return (
        <Fragment>
            <InspectionSelmaBaseFields />
            <DefectsField defectType={DefectType.SELMA} defects={defects} className={c.defects} />
        </Fragment>
    );
};

export default InspectionFormSelma;
