import getFactoryLocation from '../factory/getFactoryLocation';
import getAuditReportName from './getAuditReportName';
import getNccaAuditReportName from './getNccaAuditReportName';
import { type FormValues } from '@/pages/audits/form';
import type { IAudit, IAuditNCValue } from '@/interfaces/audits';
import { auditNcfields } from '@/interfaces/enums/audits';

const formValuesFromAudit = (entity?: IAudit): FormValues | undefined => {
    if (!entity) {
        return;
    }

    const ncsValues: Record<string, IAuditNCValue> = {};
    entity.ncs?.forEach((nc) => {
        const nameId = nc.nameId ?? nc.name?.id;
        ncsValues[nameId] = { nameId };
        auditNcfields.forEach((field) => (ncsValues[nameId][field] = nc[field]));
    }, []);

    const keyItemsValues: Record<string, boolean | undefined> = {};
    entity.keyItems?.forEach((keyItem) => {
        keyItemsValues[keyItem.keyItemId] = !!keyItem.value;
    }, []);

    const factory = entity.factory;

    const initialValues: FormValues = {
        name: entity.name,
        typeId: entity.typeId ?? entity.type?.id,
        projectId: entity.projectId ?? entity.project?.id,
        // factoryId: entity.factory?.id,
        factoryBrandId: factory?.brandId ?? factory?.brand?.id,
        factoryLocation: getFactoryLocation(factory),
        productTypeId: entity.productTypeId ?? entity.productType?.id,
        clientId: entity.clientId ?? entity.client?.id,
        totalScore: entity.totalScore,
        nccaStatus: entity.nccaStatus,
        auditStatus: entity.auditStatus,
        startDate: entity.startDate,
        completeDate: entity.completeDate,
        reportDate: entity.reportDate,
        nccaCompleteDate: entity.nccaCompleteDate,

        ncsValues,
        keyItemsValues,

        isReportUploaded: entity.isReportUploaded,
        reportUpload: entity.isReportUploaded
            ? [{ uid: 'reportUpload', name: getAuditReportName(entity), type: 'application/pdf' }]
            : undefined,

        isNccaReportUploaded: entity.isNccaReportUploaded,
        nccaReportUpload: entity.isNccaReportUploaded
            ? [
                  {
                      uid: 'nccaReportUpload',
                      name: getNccaAuditReportName(entity),
                      type: 'application/pdf',
                  },
              ]
            : undefined,
    };

    return initialValues;
};

export default formValuesFromAudit;
