import { type LngLatLike, LngLat } from 'mapbox-gl';

import type { SimpleLngLat } from '@/interfaces';

const isLngLatObj = (
    location: { lng: number; lat: number } | { lon: number; lat: number }
): location is { lng: number; lat: number } => location.hasOwnProperty('lng');

const mapboxLngLatToSimple = (location?: LngLatLike): SimpleLngLat | undefined => {
    if (location == null) {
        return;
    }

    if (Array.isArray(location)) {
        return location[0] == null || location[1] == null ? undefined : [location[0], location[1]];
    }

    if (location instanceof LngLat || isLngLatObj(location)) {
        return [location.lng, location.lat];
    }

    return [location.lon, location.lat];
};

export default mapboxLngLatToSimple;
