import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Input } from 'antd';

import type { FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';

export type Props = {
    isPvModules: boolean;
};

export type FormValues = Pick<IInspection, 'subclient'>;

const tBase = 'inspections.form.additional';

type FormFields = FormField<keyof FormValues>[];

const InspectionAdditionalFields: FunctionComponent<Props> = ({ isPvModules }) => {
    const t = useTranslate();

    const fields: FormFields = useMemo(
        () => [
            {
                name: 'subclient',
                field: (
                    <Input placeholder={t(`${tBase}.placeholders.subclient`)} autoComplete='off' />
                ),
            },
        ],
        [t],
    );

    return (
        <Fragment>
            {isPvModules && (
                <Form.Item className='font-bold uppercase'>{t(`${tBase}.title`)}</Form.Item>
            )}
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionAdditionalFields;
