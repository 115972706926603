import { useMemo, type FunctionComponent } from 'react';
import { Button, Card, DatePicker, Form, type FormProps, Input, Select } from 'antd';
import { useCan, useTranslate } from '@refinedev/core';

import { DEFAULT_MIN_DATE } from '@/constants';

import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import useProjectsOptions from '@/hooks/options/useProjectsOptions';
import useFactoryCountriesOptions from '@/hooks/options/useFactoryCountriesOptions';
import useFactoryCitiesOptions from '@/hooks/options/useFactoryCitiesOptions';
import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import useInspectionResultOptions from '@/hooks/options/useInspectionResultOptions';
import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import { type SearchParams } from './onSearch';
import type { FormField } from '@/interfaces';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { ICompany } from '@/interfaces/companies';

export type FormValues = SearchParams;

export type Props = {
    formProps: FormProps;
};

const tBase = 'inspections.filters';

const InspectionsFilters: FunctionComponent<Props> = ({ formProps }) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const formWatchParams = { form: formProps.form, preserve: true };
    const factoryBrandId: IFactoryBrand['id'] | undefined = Form.useWatch(
        'factoryBrandId',
        formWatchParams,
    );
    const clientId: ICompany['id'] | undefined = Form.useWatch('clientId', formWatchParams);

    const { data: productTypesOptions } = useProductTypesOptions();
    const factoryBrandsOptions = useFactoryBrandsOptions();
    const inspectionResultOptions = useInspectionResultOptions();
    const countryOptions = useFactoryCountriesOptions(factoryBrandId);
    const cityOptions = useFactoryCitiesOptions(factoryBrandId);
    const clientsOptions = useCompaniesOptions();
    const projectsOptions = useProjectsOptions(clientId);

    const fields: FormField<keyof FormValues>[] = useMemo(() => {
        const newFields: FormField<keyof FormValues>[] = [
            {
                name: 'name',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.name`)}
                        autoComplete='off'
                    />
                ),
            },
        ];

        if (canGetCompanies) {
            newFields.push({
                name: 'clientId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.clientId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={clientsOptions}
                    />
                ),
            });
        }

        newFields.push(
            {
                name: 'projectId',
                field: (
                    <Select
                        placeholder={
                            !!clientId && !projectsOptions.length
                                ? t(`${tBase}.placeholders.projectIdNoneForClient`)
                                : t(`${tBase}.placeholders.projectId`)
                        }
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={projectsOptions}
                    />
                ),
            },
            {
                name: 'productTypeId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.productTypeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
            {
                name: 'factoryBrandId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryBrandId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                    />
                ),
            },
            {
                name: 'country',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.country`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={countryOptions}
                    />
                ),
            },
            {
                name: 'city',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.city`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={cityOptions}
                    />
                ),
            },
            {
                name: 'result',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.result`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={inspectionResultOptions}
                    />
                ),
            },

            {
                name: 'createdAt',
                field: (
                    <DatePicker.RangePicker
                        minDate={DEFAULT_MIN_DATE}
                        allowClear={true}
                        className='w-full'
                    />
                ),
            },
        );

        return newFields;
    }, [
        clientId,
        projectsOptions,
        productTypesOptions,
        factoryBrandsOptions,
        inspectionResultOptions,
        countryOptions,
        cityOptions,
        clientsOptions,
        canGetCompanies,
        t,
    ]);

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            name={name}
                            label={t(`${tBase}.fields.${name}`)}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default InspectionsFilters;
