import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';

import FactoryBrandsForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

export const FactoryBrandsCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, onFinish } = useForm<IFactoryBrand, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Create title={t('factoryBrands.titles.create')} saveButtonProps={saveButtonProps}>
            <FactoryBrandsForm
                formProps={{
                    ...formProps,
                    initialValues: { scores: [{ quarter: undefined, year: '', value: '' }] },
                }}
                onFinish={onFinish}
            />
        </Create>
    );
};
