import { useMemo, type FunctionComponent } from 'react';
import { Button, Card, Form, type FormProps, Input, Select, DatePicker } from 'antd';
import { useTranslate } from '@refinedev/core';

import { DEFAULT_MIN_DATE } from '@/constants';

import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import useUserRolesOptions from '@/hooks/options/useUserRolesOptions';
import { type SearchParams } from './onSearch';
import type { FormField } from '@/interfaces';

export type FormValues = SearchParams;

export type Props = {
    formProps: FormProps;
};

const tBase = 'users.filters';

const UsersFilters: FunctionComponent<Props> = ({ formProps }) => {
    const t = useTranslate();

    const isActiveOptions = useMemo(
        () => [
            { value: true, label: t('users.isActiveOptions.active') },
            { value: false, label: t('users.isActiveOptions.inactive') },
        ],
        [t],
    );
    const userRolesOptions = useUserRolesOptions();
    const companiesOptions = useCompaniesOptions();

    const fields: FormField<keyof FormValues>[] = useMemo(
        () => [
            {
                name: 'email',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.email`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'firstName',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.firstName`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'lastName',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.lastName`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'role',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.role`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={userRolesOptions}
                    />
                ),
            },
            {
                name: 'companyId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.companyId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={companiesOptions}
                    />
                ),
            },
            {
                name: 'createdAt',
                field: (
                    <DatePicker.RangePicker
                        minDate={DEFAULT_MIN_DATE}
                        allowClear={true}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'updatedAt',
                field: (
                    <DatePicker.RangePicker
                        minDate={DEFAULT_MIN_DATE}
                        allowClear={true}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'isActive',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.isActive`)}
                        allowClear={true}
                        optionFilterProp='label'
                        options={isActiveOptions}
                    />
                ),
            },
        ],
        [isActiveOptions, userRolesOptions, companiesOptions, t],
    );

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            name={name}
                            label={t(`${tBase}.fields.${name}`)}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default UsersFilters;
