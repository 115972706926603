import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, type FormProps } from 'antd';

import PropertiesTreeSelector from '@/components/PropertiesTreeSelector';

import type { FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';
import type { IProductType } from '@/interfaces/productTypes';

export type Props = {
    formProps: FormProps;
    isPvModules: boolean;
    productType?: IProductType;
};

export type FormValues = Pick<IInspection, 'properties'>;

const tBase = 'inspections.form.technology';

type FormFields = FormField<keyof FormValues>[];

const InspectionTechnologyFields: FunctionComponent<Props> = ({
    formProps,
    isPvModules,
    productType,
}) => {
    const t = useTranslate();

    const properties = productType?.properties;

    const getFieldValue = formProps.form?.getFieldValue;
    const setFieldValue = formProps.form?.setFieldValue;

    const fields: FormFields = useMemo(() => {
        const newFields: FormFields = [
            {
                name: 'properties',
                field: (
                    <PropertiesTreeSelector
                        tBase={tBase}
                        data={properties}
                        getFieldValue={getFieldValue}
                        setFieldValue={setFieldValue}
                        /** @todo Where to display this field? */
                        skipFields={['property_cell_arch']}
                    />
                ),
                noStyle: true,
            },
        ];

        return newFields;
    }, [properties, getFieldValue, setFieldValue]);

    return (
        <Fragment>
            {isPvModules && (
                <Form.Item className='font-bold uppercase'>{t(`${tBase}.title`)}</Form.Item>
            )}
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionTechnologyFields;
