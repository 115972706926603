import { Fragment, useMemo, type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    type HttpError,
    useCan,
} from '@refinedev/core';
import { List, TextField, useTable, EditButton } from '@refinedev/antd';
import { Table, Space, Tag, Col, Row } from 'antd';

import { DEFECT_SUB_TYPES, DEFECT_TYPES, DEFECT_TYPE_PSI_ID, DEFAULT_NA_VALUE } from '@/constants';

import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import DefectsFilters from './DefectsFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import onSearch, { type SearchParams } from './DefectsFilters/onSearch';
import { errorNotification } from '@/notifications';
import type { TableColumn } from '@/interfaces';
import type { IDefect } from '@/interfaces/defects';
import type { IProperty } from '@/interfaces/properties';

type DefectColumns = TableColumn<
    IDefect,
    keyof IDefect | 'actions' | ['productType', 'name', 'value']
>[];

const tBase = 'defects.list';

export const DefectsList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'defects', action: 'create' });
    const { data: { can: canEdit } = {} } = useCan({ resource: 'defects', action: 'edit' });
    const { data: { can: canDelete } = {} } = useCan({ resource: 'defects', action: 'delete' });

    const { tableProps, searchFormProps } = useTable<IDefect, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'name', order: 'asc' }],
        },
    });

    const columns: DefectColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: IDefect['name']) => <TextField value={value} />,
                sorter: true,
            },
            {
                name: ['productType', 'name', 'value'],
                render: (value?: IProperty['value']) => <TextField value={value} />,
            },
            {
                name: 'typeId',
                render: (value?: IDefect['typeId']) => (
                    <TextField
                        value={
                            DEFECT_TYPES.find(({ id }) => id === value)?.text || DEFAULT_NA_VALUE
                        }
                    />
                ),
            },
            {
                name: 'subtypeId',
                render: (value: IDefect['subtypeId'], entity) => (
                    <TextField
                        value={
                            (entity.typeId === DEFECT_TYPE_PSI_ID &&
                                DEFECT_SUB_TYPES.find(({ id }) => id === value)?.text) ||
                            DEFAULT_NA_VALUE
                        }
                    />
                ),
            },
            {
                name: 'color',
                render: (value: IDefect['color']) => <Tag color={value}>{value}</Tag>,
                sorter: true,
            },
            {
                name: 'actions',
                render: (_value: void, entity) => (
                    <Space>
                        <EditButton
                            hideText={true}
                            size='small'
                            disabled={!canEdit}
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />
                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            disabled={!canDelete}
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [canEdit, canDelete],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(columns, tBase, 'defects');

    return (
        <List
            title={t('defects.titles.list')}
            canCreate={canCreate}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={6}>
                    <DefectsFilters formProps={searchFormProps} />
                </Col>
                <Col xs={24} sm={16} md={18}>
                    <Table
                        {...tableProps}
                        rowKey='id'
                        rowClassName='cursor-pointer'
                        className='table-with-header-cell-nowrap'
                        onRow={(entity) => {
                            return {
                                onClick: () => show('defects', entity.id),
                            };
                        }}
                    >
                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return (
                                <Table.Column
                                    {...columnProps}
                                    dataIndex={name}
                                    key={key}
                                    hidden={hidden ?? !settings[key]}
                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                />
                            );
                        })}
                    </Table>
                </Col>
            </Row>
        </List>
    );
};
