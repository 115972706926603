import { useCallback, useMemo, type FunctionComponent } from 'react';
import { Bar } from '@ant-design/plots';
import { useTranslate } from '@refinedev/core';

import type { AuditNcChartItem } from '@/interfaces/audits';
import { AuditNCCASeverity, AuditNCCASeverityColor } from '@/interfaces/enums/audits';

export type Props = {
    data?: AuditNcChartItem[];
};

const colors = {
    [AuditNCCASeverity.MINOR]: AuditNCCASeverityColor.MINOR,
    [AuditNCCASeverity.MAJOR]: AuditNCCASeverityColor.MAJOR,
    [AuditNCCASeverity.CRITICAL]: AuditNCCASeverityColor.CRITICAL,
};

const Chart: FunctionComponent<Props> = ({ data }) => {
    const t = useTranslate();

    const getSeverityName = useCallback(
        (severity: AuditNCCASeverity) => {
            return t(`audits.nccaSeverity.${severity}`);
        },
        [t],
    );

    const colorsRange = useMemo(() => {
        const indexes = Object.values(colors).map((_value, index) => index);
        return data?.length
            ? indexes.map((index) => colors[data[index].severity])
            : Object.values(colors);
    }, [data]);

    return (
        <Bar
            data={data}
            xField='name'
            yField='count'
            colorField='severity'
            style={{
                radius: 3,
                height: 24,
                insetTop: 2,
                insetBottom: 2,
                // cursor: 'pointer',
                // opacity: 0.7,
                // fillOpacity: 0.7,
                fill: ({ severity }: AuditNcChartItem) => colors[severity],
            }}
            scale={{
                color: {
                    range: colorsRange,
                },
            }}
            axis={{
                x: {
                    tickLength: 0,
                },
                y: {
                    tickLength: 0,
                },
            }}
            interaction={{
                legendFilter: false,
            }}
            legend={{
                color: {
                    itemMarker: 'circle',
                    position: 'bottom',
                    labelFormatter: getSeverityName,
                    layout: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                },
            }}
            animate={false}
        />
    );
};

export default Chart;
