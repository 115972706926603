import { useMemo, type FunctionComponent } from 'react';
import { Column } from '@ant-design/plots';

import getMonthName from '@/utils/getMonthName';
import type { IFactoryHistogram } from '@/interfaces/factories';

export type Props = {
    data?: IFactoryHistogram[];
};

const FactoryProduction: FunctionComponent<Props> = ({ data }) => {
    const chartData = useMemo(
        () => data?.map(({ month, ...item }) => ({ ...item, month: getMonthName(month) })) ?? [],
        [data]
    );

    return (
        <Column
            data={chartData}
            xField='month'
            yField='production'
            label={false}
            animation={{
                appear: {
                    duration: 2000,
                },
            }}
        />
    );
};

export default FactoryProduction;
