import { useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { TextField } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import { type LargestSupplierItem } from '@/utils/factory/getLargestSupplierData';
import type { IconComponent, TableColumn } from '@/interfaces';

import c from './LargestSupplierCard.module.css';

export type Props = {
    year: number;
    tBase: string;
    items: LargestSupplierItem[];
    Icon: IconComponent;
};

type LargestSupplierColumns = TableColumn<LargestSupplierItem, keyof LargestSupplierItem>[];

const LargestSupplierCard: FunctionComponent<Props> = ({ year, tBase, items, Icon }) => {
    const t = useTranslate();

    const columns: LargestSupplierColumns = useMemo(
        () => [
            {
                name: 'product',
                render: (value?: LargestSupplierItem['product']) =>
                    value ? <TextField value={value} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'supplier',
                align: 'right',
                render: (value?: LargestSupplierItem['supplier']) =>
                    value ? <TextField value={value} /> : DEFAULT_NA_VALUE,
            },
        ],
        [],
    );

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <Icon />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.largestSupplier`, {
                            year,
                        })}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='product'
                className={c.list}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                })}
            </Table>
        </Card>
    );
};

export default LargestSupplierCard;
