import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, InputNumber, Select } from 'antd';

import type { FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';

export type Props = {
    isPvModules: boolean;
};

export type FormValues = Pick<
    IInspection,
    'psiTotalInspect' | 'viResult' | 'ivmResult' | 'eliResult'
>;

const tBase = 'inspections.form.psiBase';

type FormFields = FormField<keyof FormValues>[];

const InspectionPsiBaseFields: FunctionComponent<Props> = ({ isPvModules }) => {
    const t = useTranslate();

    const inspectionDefectResultList = useMemo(
        () => [
            { value: 'pass', label: t('inspections.defectResult.pass') },
            { value: 'fail', label: t('inspections.defectResult.fail') },
        ],
        [t],
    );

    const fields: FormFields = useMemo(() => {
        const newFields: FormFields = [
            {
                name: 'psiTotalInspect',
                field: (
                    <InputNumber
                        type='number'
                        min={0}
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.psiTotalInspect`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'viResult',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.viResult`)}
                        options={inspectionDefectResultList}
                    />
                ),
            },
        ];

        if (isPvModules) {
            newFields.push(
                {
                    name: 'ivmResult',
                    field: (
                        <Select
                            placeholder={t(`${tBase}.placeholders.ivmResult`)}
                            options={inspectionDefectResultList}
                        />
                    ),
                },
                {
                    name: 'eliResult',
                    field: (
                        <Select
                            placeholder={t(`${tBase}.placeholders.eliResult`)}
                            options={inspectionDefectResultList}
                        />
                    ),
                },
            );
        }

        return newFields;
    }, [isPvModules, inspectionDefectResultList, t]);

    return (
        <Fragment>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionPsiBaseFields;
