import { type UploadFile } from 'antd';

import getFactoryLocation from '@/utils/factory/getFactoryLocation';
import { type FormValues } from '@/pages/audits/form';
import type { IAuditNCValue } from '@/interfaces/audits';
import type { AntdSelectOption } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';
import { auditNcfields } from '@/interfaces/enums/audits';

const formValuesToAudit = (
    values: FormValues,
    factories: AntdSelectOption<IFactory>[],
): {
    data: FormValues;
    reportUpload?: UploadFile[];
    nccaReportUpload?: UploadFile[];
    isReportUploaded?: boolean;
    isNccaReportUploaded?: boolean;
} => {
    const {
        reportUpload,
        isReportUploaded,
        nccaReportUpload,
        isNccaReportUploaded,
        factoryBrandId,
        factoryLocation,
        ncsValues,
        keyItemsValues,
        ...data
    } = values;

    data.factoryId =
        values?.factoryBrandId != null && values?.factoryLocation != null
            ? factories.find(({ item }) => {
                  const brandId = item?.brandId ?? item?.brand?.id;
                  return (
                      brandId === values.factoryBrandId &&
                      getFactoryLocation(item) === values.factoryLocation
                  );
              })?.item?.id
            : undefined;

    data.ncs = [];
    if (ncsValues) {
        Object.values(ncsValues).forEach((value) => {
            const item: IAuditNCValue = { nameId: value.nameId };
            auditNcfields.forEach((field) => {
                item[field] = Number(value[field]) || 0;
            });
            data.ncs!.push(item);
        });
    }

    data.keyItems = [];
    if (keyItemsValues) {
        Object.entries(keyItemsValues).forEach(([keyItemId, value]) => {
            data.keyItems!.push({ keyItemId, value: !!value });
        });
    }

    return { data, reportUpload, nccaReportUpload, isReportUploaded, isNccaReportUploaded };
};

export default formValuesToAudit;
