import { ReactNode, useMemo, type FunctionComponent } from 'react';
import { Card, Collapse, CollapseProps, Table, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { TextField } from '@refinedev/antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { DEFAULT_NA_VALUE } from '@/constants';

import { auditNcfields } from '@/interfaces/enums/audits';
import useAuditNCItems from '@/hooks/useAuditNCItems';
import type { TableColumn } from '@/interfaces';
import type { IAudit } from '@/interfaces/audits';

import c from './AuditSummaryCard.module.css';

export type Props = {
    tBase: string;
    audit?: IAudit;
};

type SummaryItem = { name: string; value?: string | number | ReactNode };

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const AuditSummaryCard: FunctionComponent<Props> = ({ tBase, audit }) => {
    const t = useTranslate();

    const ncs = useAuditNCItems();

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: SummaryItem['name']) => (
                    <Typography.Title level={5} className='m-0'>
                        <TextField value={value} />
                    </Typography.Title>
                ),
            },
            {
                name: 'value',
                align: 'right',
                width: 80,
                render: (value?: SummaryItem['value']) => {
                    if (value == null) {
                        return <TextField value={DEFAULT_NA_VALUE} />;
                    }

                    if (typeof value === 'string') {
                        return <TextField value={value} />;
                    }

                    return value;
                },
            },
        ],
        [],
    );

    const items = useMemo((): CollapseProps['items'] => {
        const checked = t('audits.keyItemsDisplay.checked');
        const unchecked = t('audits.keyItemsDisplay.unchecked');
        const panels: { key: string; label: string; rows: SummaryItem[] }[] = [
            {
                key: 'ncs',
                label: t(`${tBase}.panels.ncs`),
                rows:
                    ncs?.map((ncsItem) => {
                        const ncItem = audit?.ncs?.find(
                            (item) => item.name?.id === ncsItem.id || item.nameId === ncsItem.id,
                        );
                        let ncValue: number | undefined = undefined;
                        auditNcfields.forEach((field) => {
                            if (field !== 'score' && ncItem?.[field] != null) {
                                const value = Number(ncItem[field]) || 0;
                                ncValue = ncValue == null ? value : ncValue + value;
                            }
                        });

                        return {
                            name: t(`audits.ncsNames.${ncsItem.value}`, ncsItem.value),
                            value: ncValue || undefined,
                        };
                    }) ?? [],
            },
            {
                key: 'keyItems',
                label: t(`${tBase}.panels.keyItems`),
                rows:
                    audit?.keyItems?.map(({ name, value }) => {
                        return {
                            name: t(`audits.keyItems.${name}`, name),
                            value: value ? checked : unchecked,
                        };
                    }) ?? [],
            },
        ];

        return panels.map(({ key, label, rows }) => {
            return {
                key,
                label,
                children: (
                    <Table
                        dataSource={rows}
                        bordered={false}
                        pagination={false}
                        showHeader={false}
                        rowKey='name'
                        className={c.list}
                    >
                        {columns.map(({ name, title, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                        })}
                    </Table>
                ),
            };
        });
    }, [audit, columns, ncs, t, tBase]);

    return (
        <Card className={c.card}>
            <Collapse
                defaultActiveKey={['numbers']}
                ghost={true}
                expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                items={items}
            />
        </Card>
    );
};

export default AuditSummaryCard;
