import { API_ROOT } from '@/constants';

import type { TokenResponseData } from '@/interfaces/auth';

const refreshAccessToken = async (refreshToken: string): Promise<TokenResponseData> => {
    const request = new Request(`${API_ROOT}/auth/refresh`, {
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${refreshToken}`,
        }),
    });

    const response = await fetch(request);
    if (response.status >= 400 && response.status < 500) {
        throw new Error('Failed to refresh token');
    }

    const data: TokenResponseData = await response.json();
    if (!data) {
        throw new Error('Failed to refresh token');
    }

    return data;
};

export default refreshAccessToken;
