import { type Dayjs } from 'dayjs';
import { CrudFilters } from '@refinedev/core';

import type { IFactory } from '@/interfaces/factories';

export type SearchParams = Partial<
    Pick<IFactory, 'id' | 'brandId' | 'country' | 'city' | 'mainProductTypeId'>
> & {
    createdAt?: [Dayjs, Dayjs];
};

const onSearch = ({
    id,
    brandId,
    mainProductTypeId,
    createdAt,
    country,
    city,
}: SearchParams): CrudFilters => {
    return [
        {
            field: 'id',
            operator: 'eq',
            value: id || undefined,
        },
        {
            field: 'brandId',
            operator: 'eq',
            value: brandId || undefined,
        },
        {
            field: 'mainProductTypeId',
            operator: 'eq',
            value: mainProductTypeId || undefined,
        },
        {
            field: 'createdAt',
            operator: 'gte',
            value: createdAt?.[0]?.isValid()
                ? createdAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt?.[1]?.isValid() ? createdAt[1].endOf('day').toISOString() : undefined,
        },
        {
            field: 'country',
            operator: 'startswith',
            value: country || undefined,
        },
        {
            field: 'city',
            operator: 'startswith',
            value: city || undefined,
        },
    ];
};

export default onSearch;
