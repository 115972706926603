import { useMemo } from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core';

import type { AntdSelectOption } from '@/interfaces';
import type { IUser } from '@/interfaces/users';
import { UserRole } from '@/interfaces/enums/users';

const useUserRolesOptions = () => {
    const t = useTranslate();
    const { data: user } = useGetIdentity<IUser>();

    return useMemo(() => {
        let roles = Object.values(UserRole);
        if (user?.role !== UserRole.ADMIN) {
            roles = roles.filter((role) => role !== UserRole.ADMIN);
        }

        return roles.map(
            (role): AntdSelectOption => ({
                value: role,
                label: t(`users.role.${role}`),
            }),
        );
    }, [user, t]);
};

export default useUserRolesOptions;
