import { useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { TextField } from '@refinedev/antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

import { DEFAULT_NA_VALUE } from '@/constants';

import type { IconComponent, TableColumn } from '@/interfaces';

import c from './SummaryCard.module.css';

export type SummaryItem = {
    title: string;
    value?: string | number;
    change?: 'up' | 'down';
};

export type Props = {
    title: string;
    Icon?: IconComponent;
    items: SummaryItem[];
};

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const SummaryCard: FunctionComponent<Props> = ({ title, Icon, items }) => {
    const hasChangeColumn = useMemo(() => items.some(({ change }) => change != null), [items]);

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'title',
                render: (value?: SummaryItem['title']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'value',
                align: 'right',
                render: (value?: SummaryItem['value']) => (
                    <TextField value={value ?? DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'change',
                width: 40,
                hidden: !hasChangeColumn,
                render: (value: SummaryItem['change']) => {
                    if (value === 'up') {
                        return <CaretUpFilled style={{ color: 'green', fontSize: '1.4rem' }} />;
                    } else if (value === 'down') {
                        return <CaretDownFilled style={{ color: 'red', fontSize: '1.4rem' }} />;
                    }
                    return DEFAULT_NA_VALUE;
                },
            },
        ],
        [hasChangeColumn],
    );

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    {!!Icon && <Icon />}
                    <Typography.Text className={c.cardTitleText}>{title}</Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='title'
                className={c.list}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                })}
            </Table>
        </Card>
    );
};

export default SummaryCard;
