import { OpenNotificationParams } from '@refinedev/core';

export const errorNotification = (
    data: any,
    _: any,
    resource: string | undefined,
): OpenNotificationParams => {
    let errorMessage: string | undefined = undefined;
    // if (data.response?.data?.message === 'There is another Inspection with this name.') {
    //     return {
    //         message: `The report with this name already exists. Please change the name of the report you want to create.`,
    //         description: 'Error',
    //         type: 'error',
    //     };
    // }
    if (data) {
        /** @todo Why such a malfrormed message? */
        /**
         * data.errors: { "": string[] }
         * data.message: string
         * data.statusCode: number
         */
        if (data.errors != null) {
            if (typeof data.errors === 'object') {
                if (
                    data.errors[''] != null &&
                    Array.isArray(data.errors['']) &&
                    data.errors[''].length
                ) {
                    errorMessage = data.errors[''].join('');
                } else {
                    const messages: string[] = [];
                    Object.values(data.errors).forEach((fieldErrors) => {
                        if (typeof fieldErrors === 'string' && fieldErrors) {
                            messages.push(fieldErrors);
                        } else if (Array.isArray(fieldErrors) && fieldErrors.length) {
                            fieldErrors.forEach((fieldMessage) => {
                                if (fieldMessage) {
                                    messages.push(fieldMessage);
                                }
                            });
                        }
                    });
                    if (messages.length) {
                        errorMessage = messages.join('; ');
                    }
                }
            } else if (Array.isArray(data.errors)) {
                errorMessage = data.errors.join('; ');
            }
        } else if (data.response?.data?.message) {
            const messages = data.response?.data?.message;
            if (typeof messages === 'string') {
                errorMessage = messages;
            } else if (Array.isArray(messages)) {
                errorMessage = messages.join('; ');
            }
        } else if (data.message) {
            const messages = data.message;
            if (typeof messages === 'string') {
                errorMessage = messages;
            } else if (Array.isArray(messages)) {
                errorMessage = messages.join('; ');
            }
        }
    }

    return {
        /** @todo How to format a message; e.g. add linebreaks */
        message:
            errorMessage ??
            `Error when creating ${resource} (status code: ${data.response?.data?.statusCode})`,
        description: 'Error',
        type: 'error',
    };
};
