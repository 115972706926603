import { type FunctionComponent } from 'react';
import { Form, InputProps } from 'antd';
import { useTranslate } from '@refinedev/core';

import PropertyTextField from '@/components/PropertyTextField';
import PropertySelectField from '@/components/PropertySelectField';

import type { IPropertyField, IPropertyValue } from '@/interfaces/properties';

export type Props = InputProps & {
    tBase: string;
    field: IPropertyField;
    fieldKey: number;
    onRemove: (fieldKey: number) => void;
};

const PropertyField: FunctionComponent<Props> = ({ tBase, field, fieldKey, onRemove }) => {
    const t = useTranslate();

    if (field.type === 'select') {
        return (
            <Form.Item
                fieldKey={fieldKey}
                name={[fieldKey, 'value']}
                label={t(`${tBase}.fields.${field.name}`)}
                required={true}
                rules={[
                    {
                        validator: async (_rule: any, value?: IPropertyValue) => {
                            if (value?.value == null || !value.value.trim()) {
                                throw new Error(
                                    t(`${tBase}.rules.${field.name}.required`, {
                                        index: fieldKey + 1,
                                    })
                                );
                            }
                        },
                    },
                ]}
            >
                <PropertySelectField
                    hasRemoveButton={true}
                    fieldKey={fieldKey}
                    readonly={field.readonly}
                    placeholder={t(`${tBase}.placeholders.${field.name}`)}
                    options={field.options}
                    disabled={field.readonly}
                    onRemove={onRemove}
                />
            </Form.Item>
        );
    }

    return (
        <Form.Item
            fieldKey={fieldKey}
            name={[fieldKey, 'value']}
            label={t(`${tBase}.fields.${field.name}`)}
            required={true}
            rules={[
                {
                    validator: async (_rule: any, value?: IPropertyValue) => {
                        if (value?.value == null || !value.value.trim()) {
                            throw new Error(
                                t(`${tBase}.rules.${field.name}.required`, {
                                    index: fieldKey + 1,
                                })
                            );
                        }
                    },
                },
            ]}
        >
            <PropertyTextField
                hasRemoveButton={true}
                fieldKey={fieldKey}
                readonly={field.readonly}
                disabled={field.readonly}
                autoComplete='off'
                placeholder={t(`${tBase}.placeholders.${field.name}`)}
                onRemove={onRemove}
            />
        </Form.Item>
    );
};

export default PropertyField;
