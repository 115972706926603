import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom, useTranslate } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IAuditType } from '@/interfaces/audits';

const useAuditTypeOptions = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { data: { can: canGetAudit } = {} } = useCan({
        resource: 'audits',
        action: 'get',
    });
    const { data } = useCustom<IAuditType[]>({
        url: `${apiUrl}/audits/types`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetAudit,
        },
    });

    const auditTypes = data?.data;

    return useMemo(
        () =>
            auditTypes?.map(
                (item): AntdSelectOption<IAuditType> => ({
                    value: item.id,
                    label: t(`audits.auditType.${item.value}`, item.value),
                    item,
                }),
            ) ?? [],
        [auditTypes, t],
    );
};

export default useAuditTypeOptions;
