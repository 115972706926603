import { FireOutlined, HomeOutlined } from '@ant-design/icons';

import type { IconComponent } from '@/interfaces';
import type { IFactorySummaryItem } from '@/interfaces/factories';

export type LargestSupplierItem = {
    product: string;
    production: number;
    supplier: string;
};

const getLargestSupplierData = (factoriesSummary?: IFactorySummaryItem[]) => {
    const currentYear = new Date().getFullYear();
    const result: {
        year: number;
        items: LargestSupplierItem[];
        Icon: IconComponent;
    }[] = [
        { year: currentYear, Icon: FireOutlined, items: [] },
        { year: currentYear - 1, Icon: HomeOutlined, items: [] },
    ];
    factoriesSummary?.forEach((summary) => {
        const item = result.find(({ year }) => year === summary.reportingPeriod);
        if (item) {
            const product = item.items.find(({ product }) => product === summary.product);
            if (product) {
                if (product.production > summary.actualProduction) {
                    product.production = summary.actualProduction;
                    product.supplier = summary.largestSupplier;
                }
            } else {
                item.items.push({
                    product: summary.product,
                    production: summary.actualProduction,
                    supplier: summary.largestSupplier,
                });
            }
        }
    });

    const sortSuppliers = (a: LargestSupplierItem, b: LargestSupplierItem) =>
        a.product.localeCompare(b.product);

    for (let i = 0; i < 2; i++) {
        result[i].items.sort(sortSuppliers);
    }

    return result;
};

export default getLargestSupplierData;
