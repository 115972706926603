import { type FunctionComponent } from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslate } from '@refinedev/core';
import clsx from 'clsx';

import AuditNCField from './AuditNCField';

import type { IAuditNCInfo } from '@/interfaces/audits';
import { auditNcfields } from '@/interfaces/enums/audits';

import c from './AuditNCsField.module.css';

export type Props = {
    disabled?: boolean;
    ncs?: IAuditNCInfo[];
    className?: string;
};

const AuditNCsField: FunctionComponent<Props> = ({ ncs, disabled, className }) => {
    const t = useTranslate();

    return (
        <div className={className}>
            <Row className={c.row}>
                <Col className='flex-grow-1'></Col>
                {auditNcfields.map((field) => {
                    return (
                        <Col key={field} className={c.headerCol}>
                            {t(`audits.ncsFields.${field}`)}
                        </Col>
                    );
                })}
            </Row>

            {ncs?.map((nc) => {
                return (
                    <Form.Item key={nc.id} name={['ncsValues', nc.id]} noStyle={true}>
                        <AuditNCField
                            ncInfo={nc}
                            disabled={disabled}
                            className={clsx(c.row, c.nc)}
                        />
                    </Form.Item>
                );
            })}
        </div>
    );
};

export default AuditNCsField;
