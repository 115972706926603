import React from 'react';
import { useLogin, useTranslate } from '@refinedev/core';
import { Row, Col, Layout as AntdLayout, Card, Typography, Form, Input, Button } from 'antd';

import c from './login.module.css';

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const t = useTranslate();
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Typography.Title level={3} className={c.title}>
            {t('users.login.title')}
        </Typography.Title>
    );

    return (
        <AntdLayout className={c.layout}>
            <Row justify='center' align='middle' className={c.layoutInner}>
                <Col xs={22}>
                    <div className={c.container}>
                        <Card className={c.imageContainer}>
                            <div className={c.image}>
                                <img src='/sinovoltaics-logo-full-big.svg' alt='Sinovoltaics' />
                            </div>
                        </Card>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<ILoginForm>
                                layout='vertical'
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name='username'
                                    label={t('users.login.fields.username')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('users.login.rules.username.required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                        placeholder={t('users.login.placeholders.username')}
                                        autoComplete='off'
                                        autoFocus={true}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='password'
                                    label={t('users.login.fields.password')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('users.login.rules.password.required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        type='password'
                                        placeholder={t('users.login.placeholders.password')}
                                        size='large'
                                        autoComplete='off'
                                    />
                                </Form.Item>
                                <Button
                                    type='primary'
                                    size='large'
                                    htmlType='submit'
                                    block
                                    className={c.submit}
                                >
                                    {t('users.login.buttons.submit')}
                                </Button>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
