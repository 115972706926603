import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';

import ProductTypesForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IProductType } from '@/interfaces/productTypes';

export const ProductTypesCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, onFinish } = useForm<IProductType, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Create title={t('productTypes.titles.create')} saveButtonProps={saveButtonProps}>
            <ProductTypesForm
                isPvModules={false}
                formProps={{ ...formProps, initialValues: { uoms: [{ name: '', factor: '' }] } }}
                onFinish={onFinish}
            />
        </Create>
    );
};
