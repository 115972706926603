import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, Input, Select } from 'antd';

import type { AntdSelectOption, FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';
import { InspectionBomCdfCheck } from '@/interfaces/enums/inspections';

export type Props = {
    isPvModules: boolean;
};

export type FormValues = Pick<
    IInspection,
    'bomCheckResult' | 'viStandard' | 'ivmStandard' | 'eliStandard' | 'qualityStandard'
>;

const tBase = 'inspections.form.standards';

type FormFields = FormField<keyof FormValues>[];

const InspectionStandardsFields: FunctionComponent<Props> = ({ isPvModules }) => {
    const t = useTranslate();

    const bomCdfCheckOptions = useMemo(
        () =>
            Object.values(InspectionBomCdfCheck).map(
                (item): AntdSelectOption => ({
                    value: item,
                    label: t(`inspections.bomCheckResult.${item}`),
                }),
            ),
        [t],
    );

    const fields: FormFields = useMemo(() => {
        const newFields: FormFields = [];
        if (isPvModules) {
            newFields.push(
                {
                    name: 'bomCheckResult',
                    field: (
                        <Select
                            placeholder={t(`${tBase}.placeholders.bomCheckResult`)}
                            showSearch={true}
                            optionFilterProp='label'
                            options={bomCdfCheckOptions}
                        />
                    ),
                },
                {
                    name: 'viStandard',
                    field: (
                        <Input
                            placeholder={t(`${tBase}.placeholders.viStandard`)}
                            autoComplete='off'
                        />
                    ),
                },
                {
                    name: 'ivmStandard',
                    field: (
                        <Input
                            placeholder={t(`${tBase}.placeholders.ivmStandard`)}
                            autoComplete='off'
                        />
                    ),
                },
                {
                    name: 'eliStandard',
                    field: (
                        <Input
                            placeholder={t(`${tBase}.placeholders.eliStandard`)}
                            autoComplete='off'
                        />
                    ),
                },
            );
        } else {
            newFields.push({
                name: 'qualityStandard',
                field: (
                    <Input
                        placeholder={t(`${tBase}.placeholders.qualityStandard`)}
                        autoComplete='off'
                    />
                ),
            });
        }

        return newFields;
    }, [isPvModules, bomCdfCheckOptions, t]);

    return (
        <Fragment>
            {isPvModules && (
                <Form.Item className='font-bold uppercase'>{t(`${tBase}.title`)}</Form.Item>
            )}
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionStandardsFields;
