import { Fragment, useCallback, useMemo, type FunctionComponent } from 'react';
import { useCan, useTranslate } from '@refinedev/core';
import {
    DatePicker,
    Form,
    type FormProps,
    Input,
    InputNumber,
    Select,
    Upload,
    type UploadFile,
} from 'antd';
import { getValueFromEvent } from '@refinedev/antd';
import dayjs from 'dayjs';

import { DEFAULT_MIN_DATE, MAX_NAME_LENGTH } from '@/constants';

import useAuditTypeOptions from '@/hooks/options/useAuditTypeOptions';
import useAuditStatusOptions from '@/hooks/options/useAuditStatusOptions';
import useAuditNccaStatusOptions from '@/hooks/options/useAuditNccaStatusOptions';
import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import useFactoryLocationsOptions from '@/hooks/options/useFactoryLocationsOptions';
import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import useProjectsOptions from '@/hooks/options/useProjectsOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import type { FormField } from '@/interfaces';
import type { IAudit } from '@/interfaces/audits';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { ICompany } from '@/interfaces/companies';

export type Props = {
    formProps: FormProps;
    tBase: string;
    factoryBrandId?: IFactoryBrand['id'];
    clientId?: ICompany['id'];
};

export type FormValues = Pick<
    IAudit,
    | 'name'
    | 'typeId'
    | 'projectId'
    | 'factoryId'
    | 'productTypeId'
    | 'clientId'
    | 'totalScore'
    | 'scoreResult'
    | 'nccaStatus'
    | 'auditStatus'
    | 'startDate'
    | 'completeDate'
    | 'reportDate'
    | 'nccaCompleteDate'
    | 'isReportUploaded'
    | 'isNccaReportUploaded'
> & {
    reportUpload?: UploadFile[];
    nccaReportUpload?: UploadFile[];
    factoryBrandId?: IFactoryBrand['id'];
    factoryLocation?: string;
};

type FormFields = FormField<keyof FormValues>[];

const AuditFormBase: FunctionComponent<Props> = ({
    formProps,
    tBase,
    factoryBrandId,
    clientId,
}) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const factoryBrandsOptions = useFactoryBrandsOptions();
    const factoryLocationsOptions = useFactoryLocationsOptions(factoryBrandId);
    const { data: productTypesOptions } = useProductTypesOptions();
    const clientsOptions = useCompaniesOptions();
    const projectsOptions = useProjectsOptions(clientId, true);
    const typeOptions = useAuditTypeOptions();
    const auditStatusOptions = useAuditStatusOptions();
    const auditNccaStatusOptions = useAuditNccaStatusOptions();

    const setFieldValue = formProps?.form?.setFieldValue;

    const onFactoryBrandChange = useCallback(() => {
        setFieldValue?.('factoryLocation', undefined);
    }, [setFieldValue]);

    const onClientChange = useCallback(() => {
        setFieldValue?.('projectId', undefined);
    }, [setFieldValue]);

    const fields: FormFields = useMemo(() => {
        const newFields: FormFields = [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
            {
                name: 'typeId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.typeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={typeOptions}
                    />
                ),
            },
        ];

        if (canGetCompanies) {
            newFields.push({
                name: 'clientId',
                rules: [{ required: true, message: t(`${tBase}.rules.clientId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.clientId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={clientsOptions}
                        onChange={onClientChange}
                    />
                ),
            });
        }

        newFields.push(
            {
                name: 'projectId',
                field: (
                    <Select
                        placeholder={
                            !!clientId && !projectsOptions.length
                                ? t(`${tBase}.placeholders.projectIdNoneForClient`)
                                : t(`${tBase}.placeholders.projectId`)
                        }
                        showSearch={true}
                        allowClear={true}
                        disabled={!clientId || !projectsOptions.length ? true : undefined}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={projectsOptions}
                    />
                ),
            },
            {
                name: 'factoryBrandId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryBrandId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                        onChange={onFactoryBrandChange}
                    />
                ),
            },
            {
                name: 'factoryLocation',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryLocation`)}
                        showSearch={true}
                        allowClear={true}
                        disabled={!factoryBrandId ? true : undefined}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryLocationsOptions}
                    />
                ),
            },
            {
                name: 'productTypeId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.productTypeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
            {
                name: 'totalScore',
                rules: [
                    { pattern: /^\d+$/i, message: t(`${tBase}.rules.totalScore.range`) },
                    { min: 0, type: 'number', message: t(`${tBase}.rules.totalScore.range`) },
                    { max: 100, type: 'number', message: t(`${tBase}.rules.totalScore.range`) },
                ],
                field: (
                    <InputNumber
                        type='number'
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.totalScore`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'auditStatus',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.auditStatus`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={auditStatusOptions}
                    />
                ),
            },
            {
                name: 'nccaStatus',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.nccaStatus`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={auditNccaStatusOptions}
                    />
                ),
            },
            {
                name: 'startDate',
                getValueProps: (value?: IAudit['startDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: (
                    <DatePicker minDate={DEFAULT_MIN_DATE} allowClear={true} className='w-full' />
                ),
            },
            {
                name: 'completeDate',
                getValueProps: (value?: IAudit['completeDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: (
                    <DatePicker minDate={DEFAULT_MIN_DATE} allowClear={true} className='w-full' />
                ),
            },
            {
                name: 'reportDate',
                getValueProps: (value?: IAudit['reportDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: (
                    <DatePicker minDate={DEFAULT_MIN_DATE} allowClear={true} className='w-full' />
                ),
            },
            {
                name: 'nccaCompleteDate',
                getValueProps: (value?: IAudit['nccaCompleteDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: (
                    <DatePicker minDate={DEFAULT_MIN_DATE} allowClear={true} className='w-full' />
                ),
            },
            {
                name: 'reportUpload',
                valuePropName: 'fileList',
                getValueFromEvent,
                rules: [
                    {
                        validator: async (_rule: any, value?: UploadFile[]) => {
                            if (!Array.isArray(value) || !value.length) {
                                return;
                            }
                            if (value[0].type !== 'application/pdf') {
                                throw new Error(
                                    t(`${tBase}.rules.reportUpload.invalidFileType`, {
                                        types: '.pdf',
                                    }),
                                );
                            }
                        },
                    },
                ],
                field: (
                    <Upload.Dragger
                        listType='picture'
                        maxCount={1}
                        multiple={false}
                        accept='.pdf,application/pdf'
                        beforeUpload={() => false}
                    >
                        <p className='ant-upload-text'>{t(`${tBase}.fields.reportUploadText`)}</p>
                    </Upload.Dragger>
                ),
            },
            {
                name: 'nccaReportUpload',
                valuePropName: 'fileList',
                getValueFromEvent,
                rules: [
                    {
                        validator: async (_rule: any, value?: UploadFile[]) => {
                            if (!Array.isArray(value) || !value.length) {
                                return;
                            }
                            if (value[0].type !== 'application/pdf') {
                                throw new Error(
                                    t(`${tBase}.rules.nccaReportUpload.invalidFileType`, {
                                        types: '.pdf',
                                    }),
                                );
                            }
                        },
                    },
                ],
                field: (
                    <Upload.Dragger
                        listType='picture'
                        maxCount={1}
                        multiple={false}
                        accept='.pdf,application/pdf'
                        beforeUpload={() => false}
                    >
                        <p className='ant-upload-text'>
                            {t(`${tBase}.fields.nccaReportUploadText`)}
                        </p>
                    </Upload.Dragger>
                ),
            },
        );

        return newFields;
    }, [
        factoryBrandId,
        clientId,
        projectsOptions,
        factoryBrandsOptions,
        factoryLocationsOptions,
        productTypesOptions,
        clientsOptions,
        typeOptions,
        auditStatusOptions,
        auditNccaStatusOptions,
        canGetCompanies,
        onFactoryBrandChange,
        onClientChange,
        t,
        tBase,
    ]);

    return (
        <Fragment>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default AuditFormBase;
