import { useMemo, type FunctionComponent } from 'react';
import { Card, Spin, Typography } from 'antd';
import { CrudFilters, useCustom, useTranslate } from '@refinedev/core';
import { BarChartOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { API_ROOT, PRODUCT_TYPE_PV_MODULES_ID } from '@/constants';

import FactoriesProductionChart from './FactoriesProductionChart';

import type { IFactoryHistogramItem } from '@/interfaces/factories';
import type { IProductType } from '@/interfaces/productTypes';

import c from './FactoriesProductionCard.module.css';

export type Props = {
    tBase: string;
    filters: CrudFilters;
    productType?: IProductType;
};

const FactoriesProductionCard: FunctionComponent<Props> = ({ tBase, filters, productType }) => {
    const t = useTranslate();

    const filtersInner = useMemo(() => {
        // @ts-ignore
        let productTypeFilter = filters?.find(({ field }) => field === 'mainProductTypeId');
        if (productTypeFilter) {
            return filters;
        }
        productTypeFilter = {
            field: 'mainProductTypeId',
            operator: 'eq',
            value: PRODUCT_TYPE_PV_MODULES_ID,
        };
        return filters == null ? [productTypeFilter] : [...filters, productTypeFilter];
    }, [filters]);

    const { data: histogramResponse, isLoading } = useCustom<IFactoryHistogramItem[]>({
        url: `${API_ROOT}/factories/histogram`,
        method: 'get',
        config: { filters: filtersInner },
        queryOptions: {
            enabled: productType?.id != null,
        },
    });

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <BarChartOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.monthlyProductionAll`, {
                            productTypeName: productType?.name?.value ?? '',
                        })}
                    </Typography.Text>
                </div>
            }
            className={clsx(c.card, isLoading && c.cardLoading)}
        >
            {isLoading ? (
                <Spin className={c.spinner} key='spinner' />
            ) : (
                <FactoriesProductionChart data={histogramResponse?.data} key='asd' />
            )}
        </Card>
    );
};

export default FactoriesProductionCard;
