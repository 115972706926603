import axios, { AxiosHeaders } from 'axios';

import getAccessToken from '@/utils/auth/getAccessToken';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
    const token = getAccessToken();
    if (!token) {
        return request;
    }

    if (!request.headers) {
        request.headers = new AxiosHeaders();
    }

    request.headers['Authorization'] = `Bearer ${token}`;

    return request;
});

export default axiosInstance;
