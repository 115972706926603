import { type FunctionComponent, useCallback, MouseEventHandler } from 'react';
import { Button, type ButtonProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslate } from '@refinedev/core';

export type Props = ButtonProps & {
    hideText?: boolean;
};

const DownloadButton: FunctionComponent<Props> = ({
    hideText = false,
    children,
    onClick,
    ...buttonProps
}) => {
    const t = useTranslate();

    const onClickInner: MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            if (onClick) {
                event?.preventDefault();
                onClick(event as React.MouseEvent<HTMLButtonElement>);
            }
        },
        [onClick],
    );

    return (
        <Button {...buttonProps} icon={<DownloadOutlined />} onClick={onClickInner}>
            {!hideText && (children ?? t('buttons.download'))}
        </Button>
    );
};

export default DownloadButton;
