import { useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { useCustom, useTranslate } from '@refinedev/core';
import { TextField } from '@refinedev/antd';
import clsx from 'clsx';

import { API_ROOT, DEFAULT_NA_VALUE } from '@/constants';

import type { TableColumn } from '@/interfaces';
import type { IFactory, IFactorySummary } from '@/interfaces/factories';

import c from './FactorySummaryCard.module.css';

export type Props = {
    tBase: string;
    factory?: IFactory;
};

type SummaryItem = { name: string; value?: string };

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const FactorySummaryCard: FunctionComponent<Props> = ({ tBase, factory }) => {
    const t = useTranslate();

    const { data: summaryResponse } = useCustom<IFactorySummary>({
        url: `${API_ROOT}/factories/${factory?.id}/summary`,
        method: 'get',
        queryOptions: {
            enabled: factory?.id != null,
        },
    });
    const factorySummary = summaryResponse?.data;

    const items = useMemo((): SummaryItem[] => {
        const now = new Date();
        const currentYear = now.getFullYear();
        const previousYear = currentYear - 1;

        return [
            { name: t(`${tBase}.fields.name`), value: factory?.name },
            { name: t(`${tBase}.fields.brand`), value: factory?.brand?.name },
            { name: t(`${tBase}.fields.address`), value: factory?.address },
            {
                name: t(`${tBase}.fields.mainProductType`),
                value: factory?.mainProductType?.name?.value,
            },
            {
                name: t(`${tBase}.fields.actualProduction`, { year: previousYear }),
                value:
                    factorySummary?.actualProductionLastYear == null
                        ? DEFAULT_NA_VALUE
                        : `${factorySummary?.actualProductionLastYear.toFixed(2)} ${factorySummary?.uom || ''}`,
            },
            {
                name: t(`${tBase}.fields.actualProduction`, { year: currentYear }),
                value:
                    factorySummary?.actualProductionThisYear == null
                        ? DEFAULT_NA_VALUE
                        : `${factorySummary?.actualProductionThisYear.toFixed(2)} ${factorySummary?.uom || ''}`,
            },
            {
                name: t(`${tBase}.fields.rankingReport`, { period: '' }),
                value: factorySummary?.rankingReport,
            },
            {
                name: t(`${tBase}.fields.traceabilityAvg`),
                value: factorySummary?.traceabilityAvg
                    ? `${factorySummary?.traceabilityAvg}%`
                    : DEFAULT_NA_VALUE,
            },
            {
                name: t(`${tBase}.fields.techScore`),
                value: factorySummary?.techScore
                    ? `${factorySummary?.techScore}%`
                    : DEFAULT_NA_VALUE,
            },
            {
                name: t(`${tBase}.fields.esgScore`),
                value: factorySummary?.esgScore ? `${factorySummary?.esgScore}` : DEFAULT_NA_VALUE,
            },
        ];
    }, [factory, factorySummary, t, tBase]);

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: SummaryItem['name']) => (
                    <Typography.Title level={5}>
                        <TextField value={value} />
                    </Typography.Title>
                ),
            },
            {
                name: 'value',
                align: 'right',
                render: (value?: SummaryItem['value']) =>
                    value ? <TextField value={value} /> : DEFAULT_NA_VALUE,
            },
        ],
        [],
    );

    return (
        <Card className={c.card}>
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='name'
                className={clsx('table-with-header-cell-nowrap', c.list)}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                })}
            </Table>
        </Card>
    );
};

export default FactorySummaryCard;
