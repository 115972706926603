import { ACCESS_TOKEN_STORAGE_KEY } from '@/constants';

const getAccessToken = (): string | null => {
    try {
        return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    } catch (error) {
        console.error(error);
    }

    return null;
};

export default getAccessToken;
