import { type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useShow,
    useCan,
    useCustom,
    useApiUrl,
} from '@refinedev/core';
import { Row, Col } from 'antd';
import { Show } from '@refinedev/antd';

import { PRODUCT_TYPE_PV_MODULES_ID, REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import ProjectCard from './ProjectCard';
import ProjectInspections from './ProjectInspections';
import ProjectAudits from './ProjectAudits';

import type { IProjectDisplay, IProjectSummary } from '@/interfaces/projects';

const tBase = 'projects.show';

export const ProjectShow: FunctionComponent<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'projects', action: 'edit' });

    const { queryResult } = useShow<IProjectDisplay>();
    const { data, isLoading } = queryResult;
    const project = data?.data;

    const { data: projectSummaryData } = useCustom<IProjectSummary>({
        url: `${apiUrl}/projects/${project?.id}/summary`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: project?.id != null,
        },
    });
    const projectSummary = projectSummaryData?.data;

    const isPvModules = projectSummary?.summary?.some(({ productTypeId, productTypeName }) => {
        return (
            (productTypeId != null && productTypeId === PRODUCT_TYPE_PV_MODULES_ID) ||
            (productTypeName != null && productTypeName === 'PV Modules')
        );
    });

    return (
        <Show
            title={t('projects.titles.show')}
            canEdit={canEdit}
            isLoading={isLoading}
            contentProps={{
                style: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    boxShadow: 'none',
                },
                styles: {
                    body: {
                        padding: 0,
                    },
                },
            }}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={8}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <ProjectCard
                                tBase={tBase}
                                project={project}
                                projectSummary={projectSummary}
                            />
                        </Col>
                        {isPvModules && (
                            <Col xs={24}>
                                <ProjectAudits project={project} tBase={tBase} />
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={12} lg={16}>
                    <ProjectInspections tBase={tBase} project={project} />
                </Col>
            </Row>
        </Show>
    );
};
