import { useCallback, useMemo, type FunctionComponent } from 'react';
import { Bar } from '@ant-design/plots';
import { useTranslate } from '@refinedev/core';

import type { InspectionDefectChartItem } from '@/interfaces/inspections';
import { DefectSeverity, DefectSeverityColor } from '@/interfaces/enums/defects';

export type Props = {
    data?: InspectionDefectChartItem[];
    count?: number;
};

const colors = {
    [DefectSeverity.MINOR]: DefectSeverityColor.MINOR,
    [DefectSeverity.MAJOR]: DefectSeverityColor.MAJOR,
    [DefectSeverity.CRITICAL]: DefectSeverityColor.CRITICAL,
};

const insetTop = 0;
const insetBottom = 2;
const itemHeight = 20;
const spacingTop = 2;
const spacingBottom = 2;

const itemTotalHeight = itemHeight + insetBottom + insetTop + spacingTop + spacingBottom;

const Chart: FunctionComponent<Props> = ({ data, count }) => {
    const t = useTranslate();

    const getDefectName = useCallback(
        (severity: DefectSeverity) => t(`defects.severities.${severity}`),
        [t],
    );

    const colorsRange = useMemo(() => {
        const indexes = Object.values(colors).map((_value, index) => index);
        return data?.length
            ? indexes.map((index) => colors[data[index].severity])
            : Object.values(colors);
    }, [data]);

    return (
        <Bar
            data={data}
            xField='name'
            yField='count'
            colorField='severity'
            height={count == null ? undefined : count * itemTotalHeight}
            style={{
                radius: 3,
                height: itemHeight,
                insetTop: insetTop,
                insetBottom: insetBottom,
                // cursor: 'pointer',
                // opacity: 0.7,
                // fillOpacity: 0.7,
                fill: ({ severity }: InspectionDefectChartItem) => colors[severity],
            }}
            scale={{
                color: {
                    range: colorsRange,
                },
            }}
            axis={{
                x: {
                    tickLength: 0,
                },
                y: {
                    tickLength: 0,
                },
            }}
            interaction={{
                legendFilter: false,
            }}
            legend={{
                color: {
                    itemMarker: 'circle',
                    position: 'bottom',
                    labelFormatter: getDefectName,
                    layout: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                },
            }}
            animate={false}
        />
    );
};

export default Chart;
