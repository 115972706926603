import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Typography, Spin } from 'antd';
import { Show } from '@refinedev/antd';

import { DEFECT_SUB_TYPES, DEFECT_TYPES, DEFAULT_NA_VALUE } from '@/constants';

import type { IDefect } from '@/interfaces/defects';

const tBase = 'defects.show';

export const DefectsShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'defects', action: 'edit' });

    const { queryResult } = useShow<IDefect>();
    const { data, isLoading, isError } = queryResult;
    const entity = data?.data;

    if (isLoading) {
        return <Spin />;
    }

    if (isError || !entity) {
        return <div>{t(`${tBase}.errors.notFound`)}</div>;
    }

    return (
        <Show title={t('defects.titles.show')} canEdit={canEdit} isLoading={isLoading}>
            <Typography.Title level={5}>{t(`${tBase}.fields.name`)}</Typography.Title>
            <Typography.Paragraph>{entity.name ?? DEFAULT_NA_VALUE}</Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.productType`)}</Typography.Title>
            <Typography.Paragraph>
                {entity.productType?.name?.value ?? DEFAULT_NA_VALUE}
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.typeId`)}</Typography.Title>
            <Typography.Paragraph>
                {DEFECT_TYPES.find(({ id }) => id === entity.typeId)?.text ?? DEFAULT_NA_VALUE}
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.subtypeId`)}</Typography.Title>
            <Typography.Paragraph>
                {DEFECT_SUB_TYPES.find(({ id }) => id === entity.subtypeId)?.text ??
                    DEFAULT_NA_VALUE}
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.color`)}</Typography.Title>
            <Typography.Paragraph>{entity.color ?? DEFAULT_NA_VALUE}</Typography.Paragraph>
        </Show>
    );
};
