import { type FunctionComponent } from 'react';

import AuditNCsField from './AuditNCsField';

import useAuditNCItems from '@/hooks/useAuditNCItems';
import type { IAudit, IAuditNCValue, IAuditVersion } from '@/interfaces/audits';

import c from './AuditFormNcs.module.css';

export type Props = {
};

export type FormValues = Partial<Pick<IAudit, 'ncs'>> & {
    ncsValues?: Record<string, IAuditNCValue>;
};

const AuditFormNcs: FunctionComponent<Props> = () => {
    const ncs = useAuditNCItems();

    return <AuditNCsField ncs={ncs} className={c.ncs} />;
};

export default AuditFormNcs;
