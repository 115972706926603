import { type FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslate } from '@refinedev/core';
import { Form, type FormProps, Input, Select } from 'antd';

import {
    DEFECT_SUB_TYPES,
    DEFECT_TYPES,
    PRODUCT_TYPE_PV_MODULES_ID,
    DEFECT_TYPE_PSI_ID,
    MAX_NAME_LENGTH,
} from '@/constants';

import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import type { FormField } from '@/interfaces';
import type { IDefect } from '@/interfaces/defects';

export type Props = {
    formProps: FormProps<FormValues>;
};

export type FormValues = Pick<IDefect, 'name' | 'productTypeId' | 'typeId' | 'subtypeId' | 'color'>;

const tBase = 'defects.form';

const defectTypesOptions = DEFECT_TYPES.map(({ id, text }) => ({ value: id, label: text }));

const defectSubTypesOptions = DEFECT_SUB_TYPES.map(({ id, text }) => ({ value: id, label: text }));

const DefectsForm: FunctionComponent<Props> = ({ formProps: { onFinish, ...formProps } }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const isPvModules =
        Form.useWatch('productTypeId', formProps.form) === PRODUCT_TYPE_PV_MODULES_ID;

    const isPsiType = Form.useWatch('typeId', formProps.form) === DEFECT_TYPE_PSI_ID;

    const { data: productTypesOptions } = useProductTypesOptions();

    const fields: FormField<keyof FormValues>[] = useMemo(() => {
        const newFields: FormField<keyof FormValues>[] = [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
            {
                name: 'productTypeId',
                rules: [{ required: true, message: t(`${tBase}.rules.productTypeId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.productTypeId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
        ];

        if (isPvModules) {
            newFields.push({
                name: 'typeId',
                rules: [{ required: true, message: t(`${tBase}.rules.typeId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.typeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={defectTypesOptions}
                    />
                ),
            });
            if (isPsiType) {
                newFields.push({
                    name: 'subtypeId',
                    rules: [{ required: true, message: t(`${tBase}.rules.subtypeId.required`) }],
                    field: (
                        <Select
                            placeholder={t(`${tBase}.placeholders.subtypeId`)}
                            showSearch={true}
                            allowClear={true}
                            optionFilterProp='label'
                            filterOption={antdSelectFilter}
                            filterSort={antdSelectSort}
                            options={defectSubTypesOptions}
                        />
                    ),
                });
            }
        }

        newFields.push({
            name: 'color',
            rules: [{ pattern: /^#([0-9a-f]{3}){1,2}$/i }],
            field: <Input placeholder={t(`${tBase}.placeholders.color`)} autoComplete='off' />,
        });

        return newFields;
    }, [isPvModules, isPsiType, productTypesOptions, t]);

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data: FormValues = {
                name: values.name,
                productTypeId: values.productTypeId,
                typeId: isPvModules ? values.typeId : DEFECT_TYPE_PSI_ID,
                subtypeId: isPvModules && isPsiType ? values.subtypeId : null,
                color: values.color,
            };

            setIsSaving(true);
            try {
                /** @todo Typings for onFinish are incorrect: it returns a promise */
                await onFinish?.(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(true);
            }
        },
        [onFinish, isPvModules, isPsiType],
    );

    return (
        <Form {...formProps} disabled={isSaving} layout='vertical' onFinish={onFinishInner}>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default DefectsForm;
