import { useCallback, type FunctionComponent } from 'react';
import { Button, Popconfirm } from 'antd';
import { useDeleteButton } from '@refinedev/core';
import { DeleteOutlined } from '@ant-design/icons';
import { RefineButtonClassNames, RefineButtonTestIds } from '@refinedev/ui-types';
import { type DeleteButtonProps } from '@refinedev/antd';

import c from './CustomDeleteButton.module.css';

/**
 * Copy of DeleteButton from @refinedev/antd
 *
 * Why: if rendered in a table where each row has onClick handler and when
 * clicking Confirm or Cancel button in popup row's onClick handler fires.
 * We need to prevent that.
 */
const CustomDeleteButton: FunctionComponent<DeleteButtonProps> = ({
    resource,
    recordItemId,
    onSuccess,
    mutationMode: mutationModeProp,
    children,
    successNotification,
    errorNotification,
    hideText = false,
    accessControl,
    meta,
    dataProviderName,
    confirmTitle,
    confirmOkText,
    confirmCancelText,
    invalidates,
    ...rest
}) => {
    const {
        title,
        label,
        hidden,
        disabled,
        loading,
        confirmTitle: defaultConfirmTitle,
        confirmOkLabel: defaultConfirmOkLabel,
        cancelLabel: defaultCancelLabel,
        onConfirm,
    } = useDeleteButton({
        resource,
        id: recordItemId,
        dataProviderName,
        invalidates,
        meta,
        onSuccess,
        mutationMode: mutationModeProp,
        errorNotification,
        successNotification,
        accessControl,
    });

    // Prevent passing click event further down dom tree.
    const onPopupClick = useCallback((event?: React.MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
    }, []);

    if (hidden) return null;

    return (
        <Popconfirm
            key='delete'
            okText={confirmOkText ?? defaultConfirmOkLabel}
            cancelText={confirmCancelText ?? defaultCancelLabel}
            okType='danger'
            title={confirmTitle ?? defaultConfirmTitle}
            okButtonProps={{ disabled: loading }}
            disabled={typeof rest?.disabled !== 'undefined' ? rest.disabled : disabled}
            rootClassName={c.popup}
            onConfirm={onConfirm}
            onPopupClick={onPopupClick}
        >
            <Button
                danger
                loading={loading}
                icon={<DeleteOutlined />}
                title={title}
                disabled={disabled}
                data-testid={RefineButtonTestIds.DeleteButton}
                className={RefineButtonClassNames.DeleteButton}
                {...rest}
            >
                {!hideText && (children ?? label)}
            </Button>
        </Popconfirm>
    );
};

export default CustomDeleteButton;
