import { jwtDecode } from 'jwt-decode';

import { TOKEN_EXPIRATION_TOLERANCE } from '@/constants';

const isTokenActive = (token?: string | null): boolean => {
    if (token == null) {
        return false;
    }

    try {
        const payload = jwtDecode(token);
        if (payload.exp == null) {
            return false;
        }

        const expiresAt = (payload.exp - TOKEN_EXPIRATION_TOLERANCE) * 1000;
        const now = new Date().getTime();

        return expiresAt > now;
    } catch (error) {
        console.error(`Failed to decode token: ${token}`, error);
    }

    return false;
};

export default isTokenActive;
