import { Fragment, type FunctionComponent } from 'react';
import { Form, Button } from 'antd';
import { useTranslate } from '@refinedev/core';
import { PlusOutlined } from '@ant-design/icons';

import { PROPERTIES_MAX_DEPTH } from '@/constants';

import PropertyField from '@/components/PropertyField';

import type { IPropertyTree } from '@/interfaces/properties';

import c from './PropertiesTreeField.module.css';

export type Props = {
    tBase: string;
    tree: IPropertyTree;
    nameBase?: (string | number)[];
    maxDepth?: number;
    depth?: number;
};

const PropertiesTreeField: FunctionComponent<Props> = ({
    tree,
    tBase,
    maxDepth = PROPERTIES_MAX_DEPTH,
    depth = 0,
    nameBase = [],
}) => {
    const t = useTranslate();

    if (maxDepth <= 0) {
        return null;
    }

    return (
        <Fragment>
            {tree.map((field) => {
                return (
                    <Form.Item
                        key={field.name}
                        label={t(`${tBase}.fields.${field.name}_group`)}
                        className={depth > 0 ? c.PropertiesTreeField : ''}
                    >
                        <Form.List name={[...nameBase, field.name]}>
                            {(fields, { add, remove }, { errors }) => (
                                <Fragment>
                                    {fields.map(({ key, name: fieldKey }) => (
                                        <Fragment key={key}>
                                            <PropertyField
                                                tBase={tBase}
                                                field={field}
                                                fieldKey={fieldKey}
                                                onRemove={remove}
                                            />

                                            {!!field.children && (
                                                <PropertiesTreeField
                                                    tree={field.children}
                                                    tBase={tBase}
                                                    nameBase={[fieldKey]}
                                                    maxDepth={maxDepth - 1}
                                                    depth={depth + 1}
                                                />
                                            )}
                                        </Fragment>
                                    ))}

                                    {!field.readonly && (
                                        <Button
                                            type='dashed'
                                            icon={<PlusOutlined />}
                                            style={{ marginBottom: 16 }}
                                            onClick={() => add()}
                                        >
                                            {t(`${tBase}.buttons.${field.name}`, 'Add')}
                                        </Button>
                                    )}
                                    <Form.ErrorList errors={errors} />
                                </Fragment>
                            )}
                        </Form.List>
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default PropertiesTreeField;
