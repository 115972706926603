import { useCallback, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import { Button, Card, DatePicker, Form, type FormProps, Select } from 'antd';
import { useTranslate } from '@refinedev/core';

import useFactoryCountriesOptions from '@/hooks/options/useFactoryCountriesOptions';
import useFactoryCitiesOptions from '@/hooks/options/useFactoryCitiesOptions';
import useFactoriesOptions from '@/hooks/options/useFactoriesOptions';
import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import { type SearchParams } from './onSearch';
import type { AntdSelectOption, FormField } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

export type Props = {
    formProps: FormProps;
    productTypes?: IProductType[];
    onProductTypeSelect: (value?: IProductType) => void;
};

const tBase = 'factories.filters';

const FactoriesFilters: FunctionComponent<Props> = ({
    productTypes,
    formProps: { onFinish, ...formProps },
    onProductTypeSelect,
}) => {
    const t = useTranslate();
    const [productTypesOptions, setProductTypesOptions] = useState<AntdSelectOption[]>([]);
    const [productType, setProductType] = useState<IProductType | undefined>();

    const formWatchParams = { form: formProps.form, preserve: true };
    const factoryBrandId: IFactoryBrand['id'] | undefined = Form.useWatch(
        'factoryBrandId',
        formWatchParams,
    );

    const nameOptions = useFactoriesOptions();
    const factoryBrandsOptions = useFactoryBrandsOptions();
    const countryOptions = useFactoryCountriesOptions(factoryBrandId);
    const cityOptions = useFactoryCitiesOptions(factoryBrandId);

    useEffect(() => {
        setProductTypesOptions(
            productTypes?.map(({ id, name }) => ({ value: id, label: name.value ?? '' })) ?? [],
        );
    }, [productTypes]);

    const onProductTypeSelectInner = useCallback(
        (value: IProductType['id']) => {
            setProductType(value ? productTypes?.find(({ id }) => id === value) : undefined);
        },
        [productTypes],
    );

    const onFinishInner = useCallback(
        (values: any) => {
            onProductTypeSelect(productType);
            onFinish?.(values);
        },
        [onFinish, onProductTypeSelect, productType],
    );

    const fields: FormField<keyof SearchParams>[] = useMemo(
        () => [
            {
                name: 'id',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.id`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={nameOptions}
                    />
                ),
            },
            {
                name: 'brandId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.brandId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                    />
                ),
            },
            {
                name: 'country',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.country`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={countryOptions}
                    />
                ),
            },
            {
                name: 'city',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.city`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={cityOptions}
                    />
                ),
            },
            {
                name: 'mainProductTypeId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.mainProductTypeId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                        onSelect={onProductTypeSelectInner}
                    />
                ),
            },
            {
                name: 'createdAt',
                field: <DatePicker.RangePicker className='w-full' />,
            },
        ],
        [
            t,
            onProductTypeSelectInner,
            nameOptions,
            factoryBrandsOptions,
            countryOptions,
            cityOptions,
            productTypesOptions,
        ],
    );

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps} onFinish={onFinishInner}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            label={t(`${tBase}.fields.${name}`)}
                            name={name}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item key='actions'>
                    <Button
                        type='primary'
                        size='large'
                        block={true}
                        onClick={formProps.form?.submit}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default FactoriesFilters;
