import { type FunctionComponent } from 'react';
import { Badge } from 'antd';
import { useTranslate } from '@refinedev/core';

import getAuditScoreResultColor from '@/utils/audit/getAuditScoreResultColor';
import type { IAudit } from '@/interfaces/audits';

import c from './AuditResultBadge.module.css';

export type Props = {
    totalScore?: IAudit['totalScore'];
    scoreResult?: IAudit['scoreResult'];
};

const AuditResultBadge: FunctionComponent<Props> = ({ scoreResult, totalScore }) => {
    const t = useTranslate();

    return (
        <Badge
            count={
                totalScore == null ? t('audits.scoreResult.unknown') : `${totalScore.toFixed(0)}%`
            }
            color={getAuditScoreResultColor(scoreResult)}
            className={c.root}
        />
    );
};

export default AuditResultBadge;
