import { type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';

import Chart, { type Props as ChartProps } from './Chart';

import type { IconComponent } from '@/interfaces';

import c from './ChartCard.module.css';

export type Props = ChartProps & {
    cardTitle: string;
    Icon?: IconComponent;
};

const ChartCard: FunctionComponent<Props> = ({
    cardTitle,
    title,
    Icon,
    items,
    colors,
    legendLabelFormatter,
}) => {
    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    {!!Icon && <Icon />}
                    <Typography.Text className={c.cardTitleText}>{cardTitle}</Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Chart
                items={items}
                title={title}
                colors={colors}
                legendLabelFormatter={legendLabelFormatter}
            />
        </Card>
    );
};

export default ChartCard;
