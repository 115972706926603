import downloadFile from '@/utils/downloadFile';
import getNccaAuditReportName from '@/utils/audit/getNccaAuditReportName';
import type { IAudit } from '@/interfaces/audits';

const downloadNccaAuditReport = (audit?: IAudit) => {
    if (audit && audit.isNccaReportUploaded && audit.nccaReportDownloadingUrl) {
        downloadFile(audit.nccaReportDownloadingUrl, getNccaAuditReportName(audit));
    }
};

export default downloadNccaAuditReport;
