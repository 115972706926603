import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import ProjectForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IProject } from '@/interfaces/projects';

export const ProjectsEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IProject, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <ProjectForm formProps={formProps} onFinish={onFinish} />
        </Edit>
    );
};
