import { type FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core';
import { Checkbox, Form, Input, Select, type FormProps } from 'antd';

import { MAX_EMAIL_LENGTH, MAX_NAME_LENGTH, MIN_PASSWORD_LENGTH } from '@/constants';

import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import useUserRolesOptions from '@/hooks/options/useUserRolesOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import type { FormField, FormOnFinish } from '@/interfaces';
import type { IUser } from '@/interfaces/users';
import { UserRole } from '@/interfaces/enums/users';

export type FormValues = Pick<IUser, 'email' | 'firstName' | 'lastName' | 'role' | 'companyId'> & {
    password?: string;
    passwordConfirmation?: string;
    isActive?: boolean;
    invite?: boolean;
};

export type Props = {
    formProps: FormProps<FormValues>;
    isEdit?: boolean;
    onFinish: FormOnFinish<FormValues, IUser>;
};

const tBase = 'users.form';

const UserForm: FunctionComponent<Props> = ({ formProps, isEdit, onFinish }) => {
    const t = useTranslate();
    const { data: user } = useGetIdentity<IUser>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const companiesOptions = useCompaniesOptions();
    const userRolesOptions = useUserRolesOptions();

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data: FormValues = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                role: values.role,
                companyId: values.companyId,
            };

            if (!isEdit) {
                data.password = values.password;
                data.passwordConfirmation = values.passwordConfirmation;
                data.isActive = !!values.isActive;
                data.invite = !!values.invite;
            }

            if (user?.role === UserRole.ADMIN) {
                data.isActive = !!values.isActive;
            } else if (!isEdit) {
                data.isActive = true;
            }

            setIsSaving(true);
            try {
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [isEdit, user, onFinish],
    );

    const fields: FormField<keyof FormValues>[] = useMemo(() => {
        const newFields: FormField<keyof FormValues>[] = [
            {
                name: 'email',
                rules: [
                    { required: true, message: t(`${tBase}.rules.email.required`) },
                    { type: 'email', message: t(`${tBase}.rules.email.invalid`) },
                    {
                        max: MAX_EMAIL_LENGTH,
                        message: t(`${tBase}.rules.email.tooLong`, { length: MAX_EMAIL_LENGTH }),
                    },
                ],
                field: <Input autoComplete='off' placeholder={t(`${tBase}.placeholders.email`)} />,
            },
            {
                name: 'firstName',
                rules: [
                    { required: true, message: t(`${tBase}.rules.firstName.required`) },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.firstName.tooLong`, {
                            length: MAX_NAME_LENGTH,
                        }),
                    },
                    {
                        pattern: new RegExp('^[a-zA-Z- ]*$'),
                        message: t(`${tBase}.rules.firstName.pattern`),
                    },
                ],
                field: (
                    <Input autoComplete='off' placeholder={t(`${tBase}.placeholders.firstName`)} />
                ),
            },
            {
                name: 'lastName',
                rules: [
                    { required: true, message: t(`${tBase}.rules.lastName.required`) },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.lastName.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                    {
                        pattern: new RegExp('^[a-zA-Z- ]*$'),
                        message: t(`${tBase}.rules.lastName.pattern`),
                    },
                ],
                field: (
                    <Input autoComplete='off' placeholder={t(`${tBase}.placeholders.lastName`)} />
                ),
            },
            {
                name: 'role',
                rules: [{ required: true, message: t(`${tBase}.rules.role.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.role`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={userRolesOptions}
                    />
                ),
            },
            {
                name: 'companyId',
                rules: [{ required: true, message: t(`${tBase}.rules.companyId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.companyId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={companiesOptions}
                    />
                ),
            },
        ];

        if (!isEdit) {
            newFields.push(
                {
                    name: 'password',
                    hasFeedback: true,
                    rules: [
                        { required: true, message: t(`${tBase}.rules.password.required`) },
                        {
                            min: MIN_PASSWORD_LENGTH,
                            message: t(`${tBase}.rules.password.tooShort`, {
                                length: MIN_PASSWORD_LENGTH,
                            }),
                        },
                    ],
                    field: (
                        <Input.Password
                            autoComplete='off'
                            placeholder={t(`${tBase}.placeholders.password`)}
                        />
                    ),
                },
                {
                    name: 'passwordConfirmation',
                    hasFeedback: true,
                    dependencies: ['password'],
                    rules: [
                        {
                            required: true,
                            message: t(`${tBase}.rules.passwordConfirmation.required`),
                        },
                        {
                            min: MIN_PASSWORD_LENGTH,
                            message: t(`${tBase}.rules.passwordConfirmation.tooShort`, {
                                length: MIN_PASSWORD_LENGTH,
                            }),
                        },
                        ({ getFieldValue }) => ({
                            async validator(_rule, value) {
                                if (value && getFieldValue('password') !== value) {
                                    throw new Error(
                                        t(`${tBase}.rules.passwordConfirmation.doNotMatch`),
                                    );
                                }
                            },
                        }),
                    ],
                    field: (
                        <Input.Password
                            autoComplete='off'
                            placeholder={t(`${tBase}.placeholders.passwordConfirmation`)}
                        />
                    ),
                },
            );
        }

        if (user?.role === UserRole.ADMIN) {
            newFields.push({
                name: 'isActive',
                label: null,
                valuePropName: 'checked',
                field: <Checkbox>{t(`${tBase}.fields.isActive`)}</Checkbox>,
            });
        }

        if (!isEdit) {
            newFields.push({
                name: 'invite',
                label: null,
                valuePropName: 'checked',
                field: <Checkbox>{t(`${tBase}.fields.invite`)}</Checkbox>,
            });
        }

        return newFields;
    }, [companiesOptions, userRolesOptions, isEdit, user, t]);

    return (
        <Form
            {...formProps}
            disabled={isSaving}
            layout='vertical'
            onFinish={onFinishInner}
            style={{ maxWidth: 600 }}
        >
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        label={t(`${tBase}.fields.${name}`)}
                        {...fieldProps}
                        key={name}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default UserForm;
