import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import FactoriesForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IFactory } from '@/interfaces/factories';

export const FactoriesEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IFactory, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            {formProps.initialValues ? (
                <FactoriesForm
                    formProps={{
                        ...formProps,
                        initialValues: {
                            ...formProps.initialValues,
                            brandId:
                                formProps.initialValues.brandId ??
                                formProps.initialValues.brand?.id,
                        },
                    }}
                    onFinish={onFinish}
                />
            ) : null}
        </Edit>
    );
};
