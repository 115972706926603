import { useMemo, type FunctionComponent } from 'react';
import { Column } from '@ant-design/plots';

import getMonthName from '@/utils/getMonthName';
import type { IFactoryHistogramItem } from '@/interfaces/factories';

export type Props = {
    data?: IFactoryHistogramItem[];
};

type DataItem = Omit<IFactoryHistogramItem, 'month'> & {
    month: string;
};

const FactoriesProductionChart: FunctionComponent<Props> = ({ data }) => {
    const chartData: DataItem[] = useMemo(
        () =>
            data?.map(({ month, production, factory }) => ({
                production: Number((production ?? 0).toFixed(2)),
                factory,
                month: getMonthName(month),
            })) ?? [],
        [data],
    );

    return (
        <Column
            data={chartData}
            xField='month'
            yField='production'
            colorField='factory'
            stack={true}
            label={{
                style: {
                    fill: '#FFFFFF',
                    opacity: 0.9,
                    insetTop: 4,
                },
            }}
            xAxis={{
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            }}
            legend={{
                color: {
                    itemMarker: 'circle',
                    position: 'bottom',
                    layout: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                },
            }}
            interaction={{
                legendFilter: false,
            }}
            animation={{
                appear: {
                    duration: 2000,
                },
            }}
        />
    );
};

export default FactoriesProductionChart;
