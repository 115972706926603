import { type FunctionComponent } from 'react';
import { useTranslate, IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';

import DefectsForm, { FormValues as DetailsFormValues } from './form';

import { errorNotification } from '@/notifications';

export const DefectsCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps } = useForm<DetailsFormValues, HttpError, DetailsFormValues>(
        {
            errorNotification,
        },
    );

    return (
        <Create title={t('defects.titles.create')} saveButtonProps={saveButtonProps}>
            <DefectsForm formProps={formProps} />
        </Create>
    );
};
