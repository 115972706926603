import { useMemo } from 'react';

import type { AntdSelectOption, UnitOfMeasure } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';

const useUomsOptions = (productType?: IProductType) => {
    return useMemo(() => {
        return (
            productType?.uoms?.map(
                (uom): AntdSelectOption<UnitOfMeasure> => ({
                    value: uom.id,
                    label: uom.name,
                    item: uom,
                }),
            ) ?? []
        );
    }, [productType]);
};

export default useUomsOptions;
