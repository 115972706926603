import { type UploadFile } from 'antd';

import { PROPERTIES_MAX_DEPTH } from '@/constants';

import getFactoryLocation from '@/utils/factory/getFactoryLocation';
import { type FormValues } from '@/pages/inspections/form';
import type { AntdSelectOption } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';
import type { IInspectionDefectValue } from '@/interfaces/inspections';
import type { IProductType } from '@/interfaces/productTypes';
import type { IProperty, IPropertyData } from '@/interfaces/properties';
import type { IDefect } from '@/interfaces/defects';
import { DefectSeverity } from '@/interfaces/enums/defects';

const severities = Object.values(DefectSeverity);

const defectValuesToData = (
    defects?: Record<IDefect['id'], IInspectionDefectValue>,
): IInspectionDefectValue[] => {
    return defects
        ? Object.entries(defects).map(([defectId, defectValues]) => {
              const defect: IInspectionDefectValue = { defectId };
              severities.forEach((severity) => {
                  defect[severity] = Number(defectValues[severity]) || 0;
              });
              return defect;
          })
        : [];
};

const extractPropertyFormValues = (
    data: IPropertyData | undefined,
    values: any,
    depth: number = PROPERTIES_MAX_DEPTH,
) => {
    const properties: { nameId: string; value: any }[] = [];
    if (depth <= 0 || !data) {
        return properties;
    }

    data.forEach((field) => {
        const name = `property_${field.id}`;
        const value: string | undefined = values[name];
        properties.push({ nameId: field.id, value });
        let subfields: IPropertyData | undefined = undefined;
        field.vals?.some(({ id, children }) => {
            if (value === id) {
                if (children != null) {
                    subfields = children;
                }

                return true;
            }

            return false;
        });

        const subProperties = extractPropertyFormValues(subfields, values, depth - 1);
        properties.push(...subProperties);
    });

    return properties;
};

const formValuesToInspection = (
    values: FormValues,
    factories: AntdSelectOption<IFactory>[],
    productTypes: AntdSelectOption<IProductType>[],
): {
    data: FormValues;
    reportUpload?: UploadFile[];
    flashDataUpload?: UploadFile[];
    isReportUploaded?: boolean;
    isFlashDataUploaded?: boolean;
} => {
    const {
        reportUpload,
        isReportUploaded,
        flashDataUpload,
        isFlashDataUploaded,
        factoryBrandId,
        factoryLocation,
        psiDefectsValues,
        selmaDefectsValues,
        ...data
    } = values;

    if (psiDefectsValues) {
        data.inspectionDefects = defectValuesToData(psiDefectsValues);
    }
    if (selmaDefectsValues) {
        data.inspectionDefects = [
            ...(data.inspectionDefects ? data.inspectionDefects : []),
            ...defectValuesToData(selmaDefectsValues),
        ];
    }

    data.factoryId =
        values?.factoryBrandId != null && values?.factoryLocation != null
            ? factories.find(({ item }) => {
                  const brandId = item?.brandId ?? item?.brand?.id;
                  return (
                      brandId === values.factoryBrandId &&
                      getFactoryLocation(item) === values.factoryLocation
                  );
              })?.item?.id
            : undefined;

    const productType =
        values.productTypeId == null
            ? undefined
            : productTypes.find(({ item }) => item?.id === values.productTypeId)?.item;

    const properties = extractPropertyFormValues(productType?.properties, values) as IProperty[];
    // @ts-ignore
    data.properties = properties.filter(({ value }) => !!value).map(({ value }) => value);

    Object.keys(data).forEach((key) => {
        if (key.startsWith('property_')) {
            // @ts-ignore
            delete data[key];
        }
    });

    return { data, reportUpload, flashDataUpload, isReportUploaded, isFlashDataUploaded };
};

export default formValuesToInspection;
