import { useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { TextField } from '@refinedev/antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

import { DEFAULT_NA_VALUE } from '@/constants';

import { type ActualProductionItem } from '@/utils/factory/getActualProductionData';
import type { IconComponent, TableColumn } from '@/interfaces';

import c from './ActualProductionCard.module.css';

export type Props = {
    year: number;
    tBase: string;
    items: ActualProductionItem[];
    Icon?: IconComponent;
};

type ActualProductionColumns = TableColumn<ActualProductionItem, keyof ActualProductionItem>[];

const ActualProductionCard: FunctionComponent<Props> = ({ year, tBase, items, Icon }) => {
    const t = useTranslate();

    const hasChangeColumn = useMemo(() => items.some(({ change }) => change != null), [items]);

    const columns: ActualProductionColumns = useMemo(
        () => [
            {
                name: 'product',
                render: (value?: ActualProductionItem['product']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'productionDisplay',
                align: 'right',
                render: (value?: ActualProductionItem['productionDisplay']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'change',
                width: 40,
                hidden: !hasChangeColumn,
                render: (value: ActualProductionItem['change']) => {
                    if (value === 'up') {
                        return <CaretUpFilled style={{ color: 'green', fontSize: '1.4rem' }} />;
                    } else if (value === 'down') {
                        return <CaretDownFilled style={{ color: 'red', fontSize: '1.4rem' }} />;
                    }
                    return DEFAULT_NA_VALUE;
                },
            },
        ],

        [hasChangeColumn],
    );

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    {!!Icon && <Icon />}
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.actualProduction`, {
                            year,
                        })}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='product'
                className={c.list}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return (
                        <Table.Column
                            {...columnProps}
                            dataIndex={name}
                            key={key}
                            title={title ?? t(`${tBase}.actualProduction.fields.${key}`)}
                        />
                    );
                })}
            </Table>
        </Card>
    );
};

export default ActualProductionCard;
