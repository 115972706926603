import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Edit } from '@refinedev/antd';

import InspectionForm from './form';

import c from './form/InspectionForm.module.css';

export const InspectionsEdit: FunctionComponent<IResourceComponentsProps> = () => {
    return (
        <Edit footerButtonProps={{ className: c.footer }} footerButtons={() => null}>
            <InspectionForm />
        </Edit>
    );
};
