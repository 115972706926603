import { type FunctionComponent } from 'react';

import AuditKeyItemsField from './AuditKeyItemsField';

import useAuditKeyItems from '@/hooks/useAuditKeyItems';
import type { IAudit, IAuditType } from '@/interfaces/audits';
import type { IProductType } from '@/interfaces/productTypes';
import type { ICompany } from '@/interfaces/companies';

import c from './AuditFormKeyItems.module.css';

export type Props = {
    auditTypeId?: IAuditType['id'],
    productTypeId?: IProductType['id'],
    clientId?: ICompany['id'],
};

export type FormValues = Partial<Pick<IAudit, 'keyItems'>> & {
    keyItemsValues?: Record<string, boolean | undefined>;
};

const AuditFormKeyItems: FunctionComponent<Props> = ({ auditTypeId, productTypeId, clientId }) => {
    const keyItems = useAuditKeyItems(auditTypeId, productTypeId, clientId);

    return <AuditKeyItemsField keyItems={keyItems} className={c.keyItems} />;
};

export default AuditFormKeyItems;
