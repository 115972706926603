import { type FunctionComponent } from 'react';
import { Badge } from 'antd';
import { useTranslate } from '@refinedev/core';

import getInspectionResultColor from '@/utils/inspection/getInspectionResultColor';
import { InspectionResult } from '@/interfaces/enums/inspections';

import c from './InspectionResultBadge.module.css';

export type Props = {
    result?: InspectionResult;
};

const InspectionResultBadge: FunctionComponent<Props> = ({ result }) => {
    const t = useTranslate();

    return (
        <Badge
            count={t(`inspections.result.${result}`, '') || t('inspections.result.unknown')}
            color={getInspectionResultColor(result)}
            className={c.root}
        />
    );
};

export default InspectionResultBadge;
