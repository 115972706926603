import { type FunctionComponent, useMemo } from 'react';
import { Anchor, Col, Row, Table } from 'antd';
import { TextField, useTable } from '@refinedev/antd';
import { useNavigation, useTranslate } from '@refinedev/core';

import { PRODUCT_TYPE_PV_MODULES_ID, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import InspectionResultBadge from '@/components/InspectionResultBadge';

import useTableSettings from '@/hooks/useTableSettings';
import downloadInspectionReport from '@/utils/inspection/downloadInspectionReport';
import downloadInspectionFlashFile from '@/utils/inspection/downloadInspectionFlashFile';
import type { TableColumn } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';
import type { IProjectListItem } from '@/interfaces/projects';

export type Props = {
    tBase: string;
    project?: IProjectListItem;
};

const { Link } = Anchor;

type InspectionColumns = TableColumn<IInspection, keyof IInspection>[];

const ProjectInspections: FunctionComponent<Props> = ({ tBase, project }) => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { tableProps } = useTable<IInspection>({
        resource: 'inspections',
        filters: {
            permanent: [{ field: 'projectId', operator: 'eq', value: project?.id }],
        },
        sorters: {
            initial: [{ field: 'startDate', order: 'desc' }],
        },
        queryOptions: { enabled: project?.id != null },
    });

    const columns: InspectionColumns = useMemo(
        () => [
            {
                name: 'name',
                sorter: true,
                render: (value: IInspection['name']) => <TextField value={value} />,
            },
            {
                name: 'uomTotal',
                sorter: true,
                render: (value: IInspection['uomTotal']) => (
                    <TextField value={value ? `${value.toFixed(2)}` : DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'result',
                sorter: true,
                render: (value?: IInspection['result']) => <InspectionResultBadge result={value} />,
            },
            {
                name: 'imagesLink',
                sorter: true,
                hidden:
                    (project?.productTypeId ?? project?.productType?.id) !==
                    PRODUCT_TYPE_PV_MODULES_ID,
                render: (value: IInspection['imagesLink']) => {
                    if (!value) {
                        return DEFAULT_NA_VALUE;
                    }

                    return (
                        <Link
                            href={value}
                            target='_blank'
                            title={t(`${tBase}.inspections.list.fields.imagesLinkTitle`)}
                        >
                            {t(`${tBase}.inspections.list.fields.imagesLinkTitle`)}
                        </Link>
                    );
                },
            },

            {
                name: 'flashDataDownloadingUrl',
                render: (_value: IInspection['flashDataDownloadingUrl'], entity) => (
                    <DownloadButton
                        hideText={true}
                        size='small'
                        disabled={!entity.isFlashDataUploaded || !entity.flashDataDownloadingUrl}
                        title={t(`${tBase}.inspections.list.buttons.downloadFlashData`)}
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadInspectionFlashFile(entity);
                        }}
                    />
                ),
            },

            {
                name: 'reportDownloadingUrl',
                render: (_value: IInspection['reportDownloadingUrl'], entity) => (
                    <DownloadButton
                        hideText={true}
                        size='small'
                        disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                        title={t(`${tBase}.inspections.list.buttons.downloadReport`)}
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadInspectionReport(entity);
                        }}
                    />
                ),
            },
        ],
        [project, tBase, t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        `${tBase}.inspections.list`,
        'project-inspections',
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} className='text-right hidden'>
                <TableSettingsButton
                    title={t(`${tBase}.inspections.list.tableSettings.title`)}
                    columnsList={columnsList}
                    settings={settings}
                    onSettingsChange={onSettingsChange}
                />
            </Col>
            <Col xs={24}>
                <Table
                    {...tableProps}
                    rowKey='id'
                    rowClassName='cursor-pointer'
                    className='table-with-header-cell-nowrap'
                    onRow={(entity) => {
                        return {
                            onClick: () => show('inspections', entity.id),
                        };
                    }}
                >
                    {columns.map(({ name, title, hidden, ...columnProps }) => {
                        const key = Array.isArray(name) ? name.join('.') : name;
                        return (
                            <Table.Column
                                {...columnProps}
                                dataIndex={name}
                                key={key}
                                hidden={hidden ?? !settings[key]}
                                title={title ?? t(`${tBase}.inspections.list.fields.${key}`)}
                            />
                        );
                    })}
                </Table>
            </Col>
        </Row>
    );
};

export default ProjectInspections;
