import { type FunctionComponent, useState, useCallback, useMemo } from 'react';
import { Form, Input, type FormProps } from 'antd';
import { useTranslate } from '@refinedev/core';

import { MAX_NAME_LENGTH } from '@/constants';

import type { FormField, FormOnFinish } from '@/interfaces';
import type { IProject } from '@/interfaces/projects';

export type Props = {
    formProps: FormProps<FormValues>;
    onFinish: FormOnFinish<FormValues, IProject>;
};

export type FormValues = Pick<IProject, 'name'>;

const tBase = 'projects.form';

const ProjectForm: FunctionComponent<Props> = ({ formProps, onFinish }) => {
    const t = useTranslate();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onFinishInner = useCallback(
        async (values: FormValues) => {
            const data: FormValues = {
                name: values.name,
            };

            setIsSaving(true);
            try {
                await onFinish(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [onFinish],
    );

    const fields: FormField<keyof FormValues>[] = useMemo(
        () => [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
        ],
        [t],
    );

    return (
        <Form {...formProps} layout='vertical' disabled={isSaving} onFinish={onFinishInner}>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default ProjectForm;
