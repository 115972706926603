import { type FormValues } from '@/pages/factories/form';
import type { IFactory } from '@/interfaces/factories';

const formValuesFromFactory = (entity?: IFactory): FormValues | undefined => {
    if (!entity) {
        return;
    }

    const initialValues: FormValues = {
        name: entity.name,
        brandId: entity.brandId ?? entity.brand?.id,
        mainProductTypeId: entity.mainProductTypeId ?? entity.mainProductType?.id,
        address: entity.address,
        city: entity.city,
        country: entity.country,
        latitude: entity.latitude,
        longitude: entity.longitude,
    };

    return initialValues;
};

export default formValuesFromFactory;
