import { type FunctionComponent, useMemo, Fragment } from 'react';
import { useTranslate, useNavigation, useCan, type HttpError } from '@refinedev/core';
import { List, TextField, useTable, DateField } from '@refinedev/antd';
import { Table, Space } from 'antd';

import { DATE_FORMAT, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import AuditResultBadge from '@/components/AuditResultBadge';
import AuditNccaStatusBadge from '@/components/AuditNccaStatusBadge';

import useTableSettings from '@/hooks/useTableSettings';
import downloadAuditReport from '@/utils/audit/downloadAuditReport';
import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import { errorNotification } from '@/notifications';
import type { TableColumn } from '@/interfaces';
import type { IAudit } from '@/interfaces/audits';
import type { IProject } from '@/interfaces/projects';
import type { IProductType } from '@/interfaces/productTypes';

export type Props = {};

type AuditColumns = TableColumn<
    IAudit,
    keyof IAudit | ['project', 'name'] | ['productType', 'name'] | 'actions'
>[];

const tBase = 'dashboard.audits.list';

const DashboardAuditsList: FunctionComponent<Props> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'audits', action: 'create' });

    const { tableProps } = useTable<IAudit, HttpError>({
        resource: 'audits',
        errorNotification,
        sorters: {
            initial: [{ field: 'updatedAt', order: 'desc' }],
        },
        pagination: {
            pageSize: 5,
        },
    });

    const columns: AuditColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: IAudit['name']) => <TextField value={value || DEFAULT_NA_VALUE} />,
            },
            {
                name: ['project', 'name'],
                render: (value?: IProject['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: ['productType', 'name'],
                render: (value?: IProductType['name']) => (
                    <TextField value={value?.value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'factory',
                render: (value?: IAudit['factory']) => (
                    <TextField value={getFactoryTitle(value) || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'auditStatus',
                render: (value?: IAudit['auditStatus']) => (
                    <TextField
                        value={
                            value
                                ? t(`audits.auditStatus.${value}`, DEFAULT_NA_VALUE)
                                : DEFAULT_NA_VALUE
                        }
                    />
                ),
            },
            {
                name: 'nccaStatus',
                render: (value?: IAudit['nccaStatus']) => <AuditNccaStatusBadge status={value} />,
            },
            {
                name: 'scoreResult',
                render: (value: IAudit['scoreResult'] | undefined, entity: IAudit) => (
                    <AuditResultBadge scoreResult={value} totalScore={entity.totalScore} />
                ),
            },
            {
                name: 'updatedAt',
                render: (value?: IAudit['updatedAt']) =>
                    value ? <DateField value={value} format={DATE_FORMAT} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'actions',
                render: (_value: void, entity: IAudit) => (
                    <Space>
                        <DownloadButton
                            hideText={true}
                            size='small'
                            title={t(`${tBase}.buttons.downloadAuditReport`)}
                            disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadAuditReport(entity);
                            }}
                        />
                    </Space>
                ),
            },
        ],
        [t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'dashboard-audits',
    );

    return (
        <List
            title={t(`${tBase}.title`)}
            canCreate={canCreate}
            resource='audits'
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Table
                {...tableProps}
                rowKey='id'
                pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true,
                }}
                rowClassName='cursor-pointer'
                className='table-with-header-cell-nowrap'
                onRow={(entity) => {
                    return {
                        onClick: () => show('audits', entity.id),
                    };
                }}
            >
                {columns.map(({ name, title, hidden, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return (
                        <Table.Column
                            {...columnProps}
                            dataIndex={name}
                            key={key}
                            hidden={hidden ?? !settings[key]}
                            title={title ?? t(`${tBase}.fields.${key}`)}
                        />
                    );
                })}
            </Table>
        </List>
    );
};

export default DashboardAuditsList;
