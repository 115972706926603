import { Fragment, useEffect, useState, type FunctionComponent } from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslate } from '@refinedev/core';
import clsx from 'clsx';

import DefectField from '@/components/DefectField';

import type { IDefect } from '@/interfaces/defects';
import { DefectSeverity, DefectType } from '@/interfaces/enums/defects';

import c from './DefectsField.module.css';

export type Props = {
    defectType: DefectType;
    disabled?: boolean;
    defects?: IDefect[];
    className?: string;
};

type FieldData = {
    groupName?: string;
    defects: IDefect[];
};

const severities = Object.values(DefectSeverity);

const fieldNames = {
    [DefectType.PSI]: 'psiDefectsValues',
    [DefectType.SELMA]: 'selmaDefectsValues',
};

const DefectsField: FunctionComponent<Props> = ({ defectType, disabled, defects, className }) => {
    const t = useTranslate();
    const [fields, setFields] = useState<FieldData[]>([]);

    const isSelma = defectType === DefectType.SELMA;
    const fieldName = fieldNames[defectType];

    useEffect(() => {
        const newFields: FieldData[] = [];
        defects?.forEach((defect) => {
            if (defect.type !== defectType) {
                return;
            }

            const groupName = (isSelma ? defect.groupName : defect.subtype) || '';
            let group = newFields.find((item) => item.groupName === groupName);
            if (!group) {
                group = { groupName, defects: [] };
                newFields.push(group);
            }
            group.defects.push(defect);
        });

        const emptyGroupIndex = newFields.findIndex(({ groupName }) => groupName === '');
        if (emptyGroupIndex >= 0) {
            const emptyGroup = newFields.splice(emptyGroupIndex, 1)[0];
            newFields.push(emptyGroup);
        }

        setFields(newFields);
    }, [isSelma, defects, defectType]);

    return (
        <div className={className}>
            <Row className={c.row}>
                <Col className='flex-grow-1'></Col>
                {severities.map((severity) => {
                    return (
                        <Col key={severity} className={c.headerCol}>
                            {t(`defects.severities.${severity}`)}
                        </Col>
                    );
                })}
            </Row>
            {fields.map(({ groupName, defects }) => {
                const hasGroup = !!groupName;
                return (
                    <Fragment key={`group-${groupName}`}>
                        {hasGroup && (
                            <Row className={c.row}>
                                <Col xs={24}>
                                    {t(
                                        isSelma
                                            ? `defects.groups.${groupName}`
                                            : `defects.subtypes.${groupName}`,
                                        groupName,
                                    )}
                                </Col>
                            </Row>
                        )}

                        {defects.map((defect) => {
                            return (
                                <Form.Item
                                    key={defect.id}
                                    name={[fieldName, defect.id]}
                                    noStyle={true}
                                >
                                    <DefectField
                                        defect={defect}
                                        disabled={disabled}
                                        className={clsx(c.row, c.defect, hasGroup && c.indent)}
                                    />
                                </Form.Item>
                            );
                        })}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default DefectsField;
