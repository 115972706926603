import { IFactoryBrand } from '@/interfaces/factoryBrands';
import { type CrudFilters } from '@refinedev/core';

export type SearchParams = Partial<Pick<IFactoryBrand, 'id'>>;

const onSearch = ({ id }: SearchParams): CrudFilters => {
    return [
        {
            field: 'id',
            operator: 'eq',
            value: id,
        },
    ];
};

export default onSearch;
