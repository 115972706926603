import { type FunctionComponent, useCallback } from 'react';
import { Row, Col, InputNumber } from 'antd';
import { useTranslate } from '@refinedev/core';

import type { AuditNCFieldKey, IAuditNCInfo, IAuditNCValue } from '@/interfaces/audits';
import { auditNcfields } from '@/interfaces/enums/audits';

import c from './AuditNCField.module.css';

export type Props = {
    value?: IAuditNCValue;
    ncInfo: IAuditNCInfo;
    disabled?: boolean;
    className?: string;
    onChange?: (value?: IAuditNCValue) => void;
};

const AuditNCField: FunctionComponent<Props> = ({
    value,
    ncInfo,
    disabled,
    className,
    onChange,
}) => {
    const t = useTranslate();

    const onChangeInner = useCallback(
        (field: AuditNCFieldKey, fieldValue: string | number | null) => {
            const newValue: IAuditNCValue = {
                ...(value ?? {}),
                nameId: ncInfo.id,
            };
            newValue[field] = fieldValue;
            onChange?.(newValue);
        },
        [value, ncInfo, onChange],
    );

    return (
        <Row className={className}>
            <Col className='flex-grow-1'>{t(`audits.ncsNames.${ncInfo.value}`, ncInfo.value)}</Col>
            {auditNcfields.map((field) => {
                return (
                    <Col key={field}>
                        <InputNumber
                            type='number'
                            value={value?.[field]}
                            disabled={disabled}
                            precision={0}
                            min={0}
                            autoComplete='off'
                            controls={false}
                            changeOnWheel={false}
                            placeholder={t(`audits.ncsFields.${field}`)}
                            className={c.input}
                            onChange={(value) => onChangeInner(field, value)}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default AuditNCField;
