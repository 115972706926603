import { type FunctionComponent, Fragment } from 'react';
import { type FormProps } from 'antd';

import { PRODUCT_TYPE_PV_MODULES_ID } from '@/constants';

import InspectionAdditionalFields, {
    type FormValues as AdditionalFormValues,
} from './InspectionAdditionalFields';
import InspectionsBaseFields, { type FormValues as BaseFormValues } from './InspectionBaseFields';
import InspectionStandardsFields, {
    type FormValues as StandardsFormValues,
} from './InspectionStandardsFields';
import InspectionTechnologyFields, {
    type FormValues as TechnologyFormValues,
} from './InspectionTechnologyFields';
import type { IProductType } from '@/interfaces/productTypes';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { ICompany } from '@/interfaces/companies';

export type Props = {
    formProps: FormProps;
    productType?: IProductType;
    factoryBrandId?: IFactoryBrand['id'];
    clientId?: ICompany['id'];
};

export type FormValues = AdditionalFormValues &
    BaseFormValues &
    StandardsFormValues &
    TechnologyFormValues;

const InspectionFormBase: FunctionComponent<Props> = ({
    formProps,
    productType,
    factoryBrandId,
    clientId,
}) => {
    const isPvModules = productType?.id === PRODUCT_TYPE_PV_MODULES_ID;

    return (
        <Fragment>
            <InspectionsBaseFields
                formProps={formProps}
                isPvModules={isPvModules}
                productType={productType}
                factoryBrandId={factoryBrandId}
                clientId={clientId}
            />
            <InspectionTechnologyFields
                formProps={formProps}
                productType={productType}
                isPvModules={isPvModules}
            />
            <InspectionStandardsFields isPvModules={isPvModules} />
            <InspectionAdditionalFields isPvModules={isPvModules} />
        </Fragment>
    );
};

export default InspectionFormBase;
