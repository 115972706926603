import downloadFile from '@/utils/downloadFile';
import getAuditReportName from '@/utils/audit/getAuditReportName';
import type { IAudit } from '@/interfaces/audits';

const downloadAuditReport = (audit?: IAudit) => {
    if (audit && audit.isReportUploaded && audit.reportDownloadingUrl) {
        downloadFile(audit.reportDownloadingUrl, getAuditReportName(audit));
    }
};

export default downloadAuditReport;
