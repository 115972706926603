import { SimpleLngLat } from '@/interfaces';

const coordTypes = ['string', 'number'];

const coordsToSimpleLngLat = (
    longitude?: number | string,
    latitude?: number | string
): SimpleLngLat | undefined => {
    if (!coordTypes.includes(typeof longitude) || !coordTypes.includes(typeof latitude)) {
        return undefined;
    }

    const lng = Number(longitude);
    const lat = Number(latitude);
    if (Number.isNaN(lng) || Number.isNaN(lat)) {
        return undefined;
    }

    return [lng, lat];
};

export default coordsToSimpleLngLat;
