import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL, DEFAULT_NA_VALUE } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';

const useProductTypesOptions = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetProductTypes } = {} } = useCan({
        resource: 'productTypes',
        action: 'get',
    });
    const { data, isLoading } = useCustom<IProductType[]>({
        url: `${apiUrl}/productTypes`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetProductTypes,
        },
    });

    const productTypes = data?.data;

    return {
        data: useMemo(
            () =>
                productTypes?.map(
                    (item): AntdSelectOption<IProductType> => ({
                        value: item.id,
                        label: item.name?.value ?? DEFAULT_NA_VALUE,
                        item,
                    }),
                ) ?? [],
            [productTypes],
        ),
        isLoading,
    };
};

export default useProductTypesOptions;
