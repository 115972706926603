import { useMemo, type FunctionComponent } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { useCan, useTranslate } from '@refinedev/core';
import clsx from 'clsx';

import { PRODUCT_TYPE_PV_MODULES_ID, DEFAULT_NA_VALUE } from '@/constants';

import type { IProjectDisplay, IProjectSummary } from '@/interfaces/projects';

import c from './ProjectCard.module.css';

export type Props = {
    tBase: string;
    project?: IProjectDisplay;
    projectSummary?: IProjectSummary;
};

type ProjectSummaryItem = {
    key?: string;
    name: string;
    value?: string | number;
    indent?: boolean;
};

const ProjectCard: FunctionComponent<Props> = ({ tBase, project, projectSummary }) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const items = useMemo(() => {
        const newItems: ProjectSummaryItem[] = [];
        if (canGetCompanies) {
            newItems.push({ name: 'client', value: project?.client?.name });
        }
        newItems.push({ name: 'name', value: project?.name });

        projectSummary?.summary?.forEach(
            (
                { productTypeId, productTypeName, uomName, uomTotalNormalized, selmaTotalInspect },
                index,
            ) => {
                newItems.push(
                    { key: `productType-${index}`, name: 'productType', value: productTypeName },
                    {
                        key: `totalSize-${index}`,
                        name: 'totalSize',
                        value: uomTotalNormalized
                            ? `${uomTotalNormalized}${uomName ? ` ${uomName}` : ''}`
                            : undefined,
                        indent: true,
                    },
                );

                const isPvModules =
                    (productTypeId != null && productTypeId === PRODUCT_TYPE_PV_MODULES_ID) ||
                    (productTypeName != null && productTypeName === 'PV Modules');

                if (isPvModules) {
                    newItems.push({
                        key: `totalSelma-${index}`,
                        name: 'totalSelma',
                        value: selmaTotalInspect,
                        indent: true,
                    });
                }
            },
        );

        return newItems;
    }, [project, projectSummary, canGetCompanies]);

    return (
        <Card>
            {items.map(({ key, name, value, indent }) => {
                return (
                    <Row key={key ?? name} className={clsx(indent && c.indent)}>
                        <Col xs={12}>
                            <Typography.Paragraph strong={true}>
                                {t(`${tBase}.fields.${name}`)}
                            </Typography.Paragraph>
                        </Col>
                        <Col xs={12}>
                            <Typography.Paragraph className='d-flex justify-end text-right'>
                                {value || DEFAULT_NA_VALUE}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                );
            })}
        </Card>
    );
};

export default ProjectCard;
