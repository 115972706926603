import { useCallback, useMemo, useState, type FunctionComponent } from 'react';
import { List, useTable } from '@refinedev/antd';
import { Row, Col } from 'antd';
import {
    useCustom,
    type IResourceComponentsProps,
    type HttpError,
    useApiUrl,
    useCan,
} from '@refinedev/core';

import { API_ROOT, PRODUCT_TYPE_PV_MODULES_ID } from '@/constants';

import FactoriesTable from './FactoriesTable';
import FactoriesFilters from './FactoriesFilters';
import ActualProductionCard from './ActualProductionCard';
import LargestSupplierCard from './LargestSupplierCard';
import FinancialChartCard from './FinancialChartCard';
import FactoriesProductionCard from './FactoriesProductionCard';

import getActualProductionData from '@/utils/factory/getActualProductionData';
import getLargestSupplierData from '@/utils/factory/getLargestSupplierData';
import onSearch, { type SearchParams } from './FactoriesFilters/onSearch';
import { errorNotification } from '@/notifications';
import type { IProductType } from '@/interfaces/productTypes';
import type { IFactory, IFactorySummaryItem } from '@/interfaces/factories';

const tBase = 'factories.list';

export const FactoriesList: FunctionComponent<IResourceComponentsProps> = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'factories', action: 'create' });
    const [currentProductType, setCurrentProductType] = useState<IProductType | undefined>();

    const { tableProps, searchFormProps, filters } = useTable<IFactory, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'createdAt', order: 'desc' }],
        },
    });

    const { data: summaryResponse } = useCustom<IFactorySummaryItem[]>({
        url: `${API_ROOT}/factories/summary`,
        method: 'get',
        config: { filters },
    });
    const factoriesSummary = summaryResponse?.data;

    const actualProduction = useMemo(
        () => getActualProductionData(factoriesSummary),
        [factoriesSummary],
    );

    const lasrgestSuppliers = useMemo(
        () => getLargestSupplierData(factoriesSummary),
        [factoriesSummary],
    );

    const { data: productTypesData } = useCustom<IProductType[]>({
        url: `${apiUrl}/productTypes`,
        method: 'get',
    });
    const productTypes = productTypesData?.data;

    const pvModulesProductType = useMemo(
        () => productTypes?.find(({ id }) => id === PRODUCT_TYPE_PV_MODULES_ID),
        [productTypes],
    );

    const onProductTypeSelect = useCallback((productType?: IProductType) => {
        setCurrentProductType(productType);
    }, []);

    return (
        <List canCreate={canCreate}>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                {actualProduction.map(({ year, items, IconComponent }) => {
                    return (
                        <Col key={`production-${year}`} xs={24} lg={6}>
                            <ActualProductionCard
                                year={year}
                                Icon={IconComponent}
                                items={items}
                                tBase={tBase}
                            />
                        </Col>
                    );
                })}

                {lasrgestSuppliers.map(({ year, items, Icon }) => {
                    return (
                        <Col key={`supplier-${year}`} xs={24} lg={6}>
                            <LargestSupplierCard
                                year={year}
                                Icon={Icon}
                                items={items}
                                tBase={tBase}
                            />
                        </Col>
                    );
                })}
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <FactoriesFilters
                        productTypes={productTypes}
                        formProps={searchFormProps}
                        onProductTypeSelect={onProductTypeSelect}
                    />
                </Col>
                <Col span={18}>
                    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                        <Col xs={12}>
                            <FactoriesProductionCard
                                tBase={tBase}
                                filters={filters}
                                productType={currentProductType ?? pvModulesProductType}
                            />
                        </Col>
                        <Col xs={12}>
                            <FinancialChartCard tBase={tBase} filters={filters} />
                        </Col>
                    </Row>
                    <FactoriesTable tableProps={tableProps} />
                </Col>
            </Row>
        </List>
    );
};
