import { type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    type HttpError,
    useCan,
} from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import DefectsForm, { FormValues as DetailsFormValues } from './form';

import { errorNotification } from '@/notifications';

export const DefectsEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canDelete } = {} } = useCan({ resource: 'defects', action: 'delete' });
    const { formProps, saveButtonProps } = useForm<DetailsFormValues, HttpError, DetailsFormValues>(
        {
            errorNotification,
        },
    );

    return (
        <Edit
            title={t('defects.titles.edit')}
            saveButtonProps={saveButtonProps}
            canDelete={canDelete}
        >
            <DefectsForm formProps={formProps} />
        </Edit>
    );
};
