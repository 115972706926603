import { type FunctionComponent } from 'react';
import { TextField } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import type { IPropertyValue } from '@/interfaces/properties';

export type Props = {
    value?: IPropertyValue;
};

const PropertyDisplay: FunctionComponent<Props> = ({ value }) => {
    return (
        <TextField
            value={value?.value == null || value.value === '' ? DEFAULT_NA_VALUE : value.value}
        />
    );
};

export default PropertyDisplay;
