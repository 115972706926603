import React from 'react';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
    <React.StrictMode>
        <React.Suspense fallback='loading'>
            <App />
        </React.Suspense>
    </React.StrictMode>,
);

reportWebVitals(process.env.NODE_ENV === 'development' ? console.log : undefined);
