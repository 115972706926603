import { type FunctionComponent, useEffect, useState } from 'react';
import { useTranslate, type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Typography, Spin } from 'antd';
import { Show } from '@refinedev/antd';

import { PRODUCT_TYPE_PV_MODULES_ID, DEFAULT_NA_VALUE } from '@/constants';

import PropertiesTreeDisplay from '@/components/PropertiesTreeDisplay';
import PropertyDisplay from '@/components/PropertyDisplay';
import { otherModulesFieldsTree } from './form/OtherModulesFields';
import { pvModulesFieldsTree } from './form/PVModulesFields';

import propertiesToValues from '@/utils/property/propertiesToValues';
import type { IProductType } from '@/interfaces/productTypes';
import type { IPropertyFormValues } from '@/interfaces/properties';

const tBase = 'productTypes.show';

export const ProductTypesShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'productTypes', action: 'edit' });

    const [isPvModules, setIsPvModules] = useState<boolean | undefined>(undefined);
    const [values, setValues] = useState<IPropertyFormValues | undefined>(undefined);

    const { queryResult } = useShow<IProductType>();
    const { data, isLoading, isError } = queryResult;
    const entity = data?.data;

    useEffect(() => {
        const newIsPvModules =
            entity?.id == null ? undefined : entity.id === PRODUCT_TYPE_PV_MODULES_ID;
        setIsPvModules(newIsPvModules);
        setValues(
            newIsPvModules == null || entity?.properties == null
                ? undefined
                : propertiesToValues(
                      newIsPvModules ? pvModulesFieldsTree : otherModulesFieldsTree,
                      entity?.properties,
                  ),
        );
    }, [entity]);

    if (isLoading) {
        return <Spin />;
    }

    if (isError || isPvModules == null) {
        return <div>{t('productTypes.show.errors.notFound')}</div>;
    }

    return (
        <Show title={t('productTypes.titles.show')} canEdit={canEdit} isLoading={isLoading}>
            <Typography.Title level={5}>{t(`${tBase}.fields.name`)}</Typography.Title>
            <Typography.Paragraph>
                <PropertyDisplay value={entity?.name} />
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.uoms`)}</Typography.Title>
            <Typography.Paragraph>
                {entity?.uoms?.length
                    ? entity.uoms.map(({ name }) => name).join(', ')
                    : DEFAULT_NA_VALUE}
            </Typography.Paragraph>

            <PropertiesTreeDisplay
                tree={isPvModules ? pvModulesFieldsTree : otherModulesFieldsTree}
                tBase={tBase}
                values={values}
            />
        </Show>
    );
};
