import { type FunctionComponent, memo } from 'react';
import { Avatar, type AvatarProps } from 'antd';

import getUserInitials from '@/utils/user/getUserInitials';
import getRandomColorFromString from '@/utils/getRandomColorFromString';
import formatUserFullname from '@/utils/user/formatUserFullname';
import type { IUser } from '@/interfaces/users';

type Props = AvatarProps & {
    user?: IUser;
};

const UserAvatarComponent: FunctionComponent<Props> = ({ user, style, ...rest }: Props) => {
    const fullName = formatUserFullname(user);

    return (
        <Avatar
            alt={fullName}
            size='small'
            style={{
                backgroundColor: rest?.src ? 'transparent' : getRandomColorFromString(fullName),
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                ...style,
            }}
            {...rest}
        >
            {getUserInitials(user) || 'A'}
        </Avatar>
    );
};

const UserAvatar = memo(UserAvatarComponent, (prevProps, nextProps) => {
    return prevProps.user === nextProps.user && prevProps.src === nextProps.src;
});

export default UserAvatar;
