import { type FunctionComponent } from 'react';
import { Badge } from 'antd';
import { useTranslate } from '@refinedev/core';

import getAuditNccaStatusColor from '@/utils/audit/getAuditNccaStatusColor';
import type { IAudit } from '@/interfaces/audits';

import c from './AuditNccaStatusBadge.module.css';

export type Props = {
    status?: IAudit['nccaStatus'];
};

const AuditNccaStatusBadge: FunctionComponent<Props> = ({ status }) => {
    const t = useTranslate();

    return (
        <Badge
            count={t(`audits.nccaStatus.${status ?? 'unknown'}`)}
            color={getAuditNccaStatusColor(status)}
            className={c.root}
        />
    );
};

export default AuditNccaStatusBadge;
