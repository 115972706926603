import { Fragment, type FunctionComponent } from 'react';
import { Typography } from 'antd';
import { useTranslate } from '@refinedev/core';

import { PROPERTIES_MAX_DEPTH } from '@/constants';

import type { IPropertyFormValues, IPropertyTree } from '@/interfaces/properties';

import c from './PropertiesTreeDisplay.module.css';

export type Props = {
    tBase: string;
    tree: IPropertyTree;
    maxDepth?: number;
    depth?: number;
    values?: IPropertyFormValues;
};

const PropertiesTreeDisplay: FunctionComponent<Props> = ({
    tree,
    tBase,
    values,
    maxDepth = PROPERTIES_MAX_DEPTH,
    depth = 0,
}) => {
    const t = useTranslate();

    if (maxDepth <= 0) {
        return null;
    }

    return (
        <div className={depth > 0 ? c.PropertiesTreeDisplay : ''}>
            {tree.map((field) => {
                return (
                    <Fragment key={field.name}>
                        <Typography.Title level={5}>
                            {t(`${tBase}.fields.${field.name}`)}
                        </Typography.Title>

                        {!values?.[field.name]?.length && (
                            <Typography.Paragraph>-</Typography.Paragraph>
                        )}
                        {!field.children && (
                            <Typography.Paragraph>
                                {values?.[field.name]
                                    .map(({ value }) => value.value)
                                    .filter(Boolean)
                                    .join(', ')}
                            </Typography.Paragraph>
                        )}

                        {!!field.children &&
                            field.children.map((subField) => {
                                return (
                                    <Fragment key={subField.name}>
                                        {values?.[field.name]?.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Typography.Paragraph>
                                                        {item.value.value}
                                                    </Typography.Paragraph>

                                                    <PropertiesTreeDisplay
                                                        tree={field.children!}
                                                        tBase={tBase}
                                                        maxDepth={maxDepth - 1}
                                                        depth={depth + 1}
                                                        values={values?.[field.name][index]}
                                                    />
                                                </Fragment>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default PropertiesTreeDisplay;
