import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

const useFactoryCountriesOptions = (factoryBrandId?: IFactoryBrand['id']) => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetFactories } = {} } = useCan({
        resource: 'factories',
        action: 'get',
    });
    const { data } = useCustom<IFactory[]>({
        url: `${apiUrl}/factories`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetFactories,
        },
    });

    const factories = data?.data;

    return useMemo(() => {
        const countries: AntdSelectOption[] = [];
        const countriesSet = new Set();
        factories?.forEach((item) => {
            if (
                factoryBrandId != null &&
                ((item.brandId != null && factoryBrandId !== item.brandId) ||
                    (item.brand?.id != null && factoryBrandId !== item.brand.id))
            ) {
                return;
            }

            const country = item.country?.trim();
            if (country && !countriesSet.has(country)) {
                countries.push({ value: country, label: country });
                countriesSet.add(country);
            }
        });

        return countries;
    }, [factories, factoryBrandId]);
};

export default useFactoryCountriesOptions;
