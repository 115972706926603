import { PROPERTIES_MAX_DEPTH } from '@/constants';

import getFactoryLocation from '../factory/getFactoryLocation';
import getInspectionReportName from './getInspectionReportName';
import getInspectionFlashFileName from './getInspectionFlashFileName';
import { type FormValues } from '@/pages/inspections/form';
import type {
    IInspection,
    IInspectionDefect1,
    IInspectionDefectValue,
} from '@/interfaces/inspections';
import type { IDefect } from '@/interfaces/defects';
import type { IPropertyData } from '@/interfaces/properties';
import type { AntdSelectOption } from '@/interfaces';
import type { IProductType } from '@/interfaces/productTypes';
import { DefectSeverity } from '@/interfaces/enums/defects';

const severities = Object.values(DefectSeverity);

const defectsToFormValues = (
    defects?: IInspectionDefect1[],
): Record<IDefect['id'], IInspectionDefectValue> => {
    const defaultValues: Record<IDefect['id'], IInspectionDefectValue> = {};
    if (!defects) {
        return defaultValues;
    }

    return defects.reduce((result, defect) => {
        const key = defect.defect?.id;
        if (key) {
            result[key] = {
                defectId: key,
            };
            severities.forEach((severity) => {
                result[key][severity] = defect[severity];
            });
        }
        return result;
    }, defaultValues);
};

const extractPropertyValues = (
    data: IPropertyData | undefined,
    entity: IInspection,
    depth: number = PROPERTIES_MAX_DEPTH,
) => {
    let properties: Record<string, any> = {};
    if (depth <= 0 || !data) {
        return properties;
    }

    const values = entity.productTechnologyInfo;

    data.forEach((field) => {
        const value: string | undefined = values?.[field.id]?.id;
        if (value) {
            properties[`property_${field.id}`] = value;
        }
        let subfields: IPropertyData | undefined = undefined;
        field.vals?.some(({ id, children }) => {
            if (value === id) {
                if (children != null) {
                    subfields = children;
                }

                return true;
            }

            return false;
        });

        const subProperties = extractPropertyValues(subfields, entity, depth - 1);
        properties = { ...properties, ...subProperties };
    });

    return properties;
};

const formValuesFromInspection = (
    entity?: IInspection,
    productTypes?: AntdSelectOption<IProductType>[],
): FormValues | undefined => {
    if (!entity) {
        return;
    }

    const factory = entity.factory;
    const initialValues: FormValues = {
        name: entity.name,
        projectId: entity.projectId ?? entity.project?.id,
        factoryBrandId: factory?.brandId ?? factory?.brand?.id,
        factoryLocation: getFactoryLocation(factory),
        productTypeId: entity.productTypeId ?? entity.productType?.id,
        clientId: entity.clientId ?? entity.client?.id,
        subclient: entity.subclient,

        total: entity.total,
        productModels: entity.productModels,
        batchNumber: entity.batchNumber,
        imagesLink: entity.imagesLink,
        status: entity.status,
        result: entity.result,
        totalReworks: entity.totalReworks,
        uomId: entity.uomId,
        uomTotal: entity.uomTotal,
        uomInspected: entity.uomInspected,
        totalProductionInput: entity.totalProductionInput,

        bomCheckResult: entity.bomCheckResult,
        viStandard: entity.viStandard,
        ivmStandard: entity.ivmStandard,
        eliStandard: entity.eliStandard,
        qualityStandard: entity.qualityStandard,

        selmaTotalInspect: entity.selmaTotalInspect,
        selmaNCsQty: entity.selmaNCsQty,

        psiTotalInspect: entity.psiTotalInspect,
        viResult: entity.viResult,
        ivmResult: entity.ivmResult,
        eliResult: entity.eliResult,

        startDate: entity.startDate,
        completeDate: entity.completeDate,
        reportDate: entity.reportDate,

        psiDefectsValues: defectsToFormValues(entity.psiDefects),
        selmaDefectsValues: defectsToFormValues(entity.selmaDefects),

        isReportUploaded: entity.isReportUploaded,
        reportUpload: entity.isReportUploaded
            ? [
                  {
                      uid: 'reportUpload',
                      name: getInspectionReportName(entity),
                      type: 'application/pdf',
                  },
              ]
            : undefined,

        isFlashDataUploaded: entity.isFlashDataUploaded,
        flashDataUpload: entity.isFlashDataUploaded
            ? [
                  {
                      uid: 'flashDataUpload',
                      name: getInspectionFlashFileName(entity),
                      type: 'application/pdf',
                  },
              ]
            : undefined,
    };

    const productType =
        initialValues.productTypeId == null
            ? undefined
            : productTypes?.find(({ item }) => item?.id === initialValues.productTypeId)?.item;

    const properties = extractPropertyValues(productType?.properties, entity);
    Object.entries(properties).forEach(([key, value]) => {
        // @ts-ignore
        initialValues[key] = value;
    });

    return initialValues;
};

export default formValuesFromInspection;
