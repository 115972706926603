import { PROPERTIES_MAX_DEPTH } from '@/constants';

import type { IPropertyField, IPropertyTree, IPropertyValue } from '@/interfaces/properties';

type FlatValue = { field: IPropertyField; value?: IPropertyValue };

const getPropertiesValuesFlat = (
    tree: IPropertyTree,
    properties?: Record<string, IPropertyValue>,
    skipFields?: string[],
    maxDepth: number = PROPERTIES_MAX_DEPTH,
) => {
    const flatValues: FlatValue[] = [];

    tree.forEach((field) => {
        if (skipFields?.includes(field.name)) {
            return;
        }
        flatValues.push({ field, value: properties?.[field.name] });
        if (field.children) {
            const subValues = getPropertiesValuesFlat(
                field.children,
                properties,
                skipFields,
                maxDepth - 1,
            );
            flatValues.push(...subValues);
        }
    });

    return flatValues;
};

export default getPropertiesValuesFlat;
