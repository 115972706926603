import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Typography, Spin } from 'antd';
import { DateField, Show, TextField } from '@refinedev/antd';

import { DATE_FORMAT_DAY, DEFAULT_NA_VALUE } from '@/constants';

import type { ICompany } from '@/interfaces/companies';

const tBase = 'companies.show';

export const CompaniesShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'companies', action: 'edit' });

    const { queryResult } = useShow<ICompany>();
    const { data, isLoading, isError } = queryResult;
    const entity = data?.data;

    if (isLoading) {
        return <Spin />;
    }

    if (isError) {
        return <div>{t('companies.show.errors.notFound')}</div>;
    }

    return (
        <Show title={t('companies.titles.show')} canEdit={canEdit} isLoading={isLoading}>
            <Typography.Title level={5}>{t(`${tBase}.fields.name`)}</Typography.Title>
            <Typography.Paragraph>
                <TextField value={entity?.name || DEFAULT_NA_VALUE} />
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.createdAt`)}</Typography.Title>
            <Typography.Paragraph>
                {entity?.createdAt ? (
                    <DateField value={entity.createdAt} format={DATE_FORMAT_DAY} />
                ) : (
                    DEFAULT_NA_VALUE
                )}
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.updatedAt`)}</Typography.Title>
            <Typography.Paragraph>
                {entity?.updatedAt ? (
                    <DateField value={entity.updatedAt} format={DATE_FORMAT_DAY} />
                ) : (
                    DEFAULT_NA_VALUE
                )}
            </Typography.Paragraph>
        </Show>
    );
};
