import { type FunctionComponent, useMemo, Fragment } from 'react';
import { useTranslate, useNavigation, useCan, type HttpError } from '@refinedev/core';
import { DateField, List, TextField, useTable } from '@refinedev/antd';
import { Table, Space } from 'antd';

import { DATE_FORMAT, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import InspectionResultBadge from '@/components/InspectionResultBadge';

import useTableSettings from '@/hooks/useTableSettings';
import downloadInspectionReport from '@/utils/inspection/downloadInspectionReport';
import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import { errorNotification } from '@/notifications';
import type { TableColumn } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';
import type { IProject } from '@/interfaces/projects';
import type { IProductType } from '@/interfaces/productTypes';
import getInspectionReworkRate from '@/utils/inspection/getInspectionReworkRate';

export type Props = {};

type InspectionColumns = TableColumn<
    IInspection,
    keyof IInspection | ['project', 'name'] | ['productType', 'name'] | 'actions'
>[];

const tBase = 'dashboard.inspections.list';

const DashboardInspectionsList: FunctionComponent<Props> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'inspections', action: 'create' });

    const { tableProps } = useTable<IInspection, HttpError>({
        resource: 'inspections',
        errorNotification,
        sorters: {
            initial: [{ field: 'updatedAt', order: 'desc' }],
        },
        pagination: {
            pageSize: 5,
        },
    });

    const columns: InspectionColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: IInspection['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: ['project', 'name'],
                render: (value?: IProject['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: ['productType', 'name'],
                render: (value?: IProductType['name']) => (
                    <TextField value={value?.value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'factory',
                render: (value?: IInspection['factory']) => (
                    <TextField value={getFactoryTitle(value) || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'batchNumber',
                render: (value?: IInspection['batchNumber']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'uomTotal',
                render: (value?: IInspection['uomTotal'], entity?: IInspection) => (
                    <TextField
                        value={
                            value == null
                                ? DEFAULT_NA_VALUE
                                : `${value.toFixed(2)}${entity?.uom?.name ? ` ${entity.uom.name}` : ''}`
                        }
                    />
                ),
            },
            {
                name: 'status',
                render: (value?: IInspection['status']) => (
                    <TextField
                        value={
                            value
                                ? t(`inspections.status.${value}`, DEFAULT_NA_VALUE)
                                : DEFAULT_NA_VALUE
                        }
                    />
                ),
            },
            {
                name: 'result',
                render: (value?: IInspection['result']) => <InspectionResultBadge result={value} />,
            },
            {
                name: 'totalReworks',
                render: (_value?: IInspection['totalReworks'], entity?: IInspection) => {
                    const reworkRate = getInspectionReworkRate(entity);
                    return (
                        <TextField
                            value={
                                reworkRate == null ? DEFAULT_NA_VALUE : `${reworkRate.toFixed(2)}%`
                            }
                        />
                    );
                },
            },
            {
                name: 'selmaNCsQty',
                render: (value?: IInspection['selmaNCsQty']) => (
                    <TextField value={value == null ? DEFAULT_NA_VALUE : value} />
                ),
            },
            {
                name: 'updatedAt',
                render: (value?: IInspection['updatedAt']) =>
                    value ? <DateField value={value} format={DATE_FORMAT} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'actions',
                render: (_value: void, entity: IInspection) => (
                    <Space>
                        <DownloadButton
                            size='small'
                            hideText={true}
                            title={t(`${tBase}.buttons.downloadReport`)}
                            disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadInspectionReport(entity);
                            }}
                        />
                    </Space>
                ),
            },
        ],
        [t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'dashboard-inspections',
    );

    return (
        <List
            title={t(`${tBase}.title`)}
            canCreate={canCreate}
            resource='inspections'
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Table
                {...tableProps}
                rowKey='id'
                pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true,
                }}
                rowClassName='cursor-pointer'
                className='table-with-header-cell-nowrap'
                onRow={(entity) => {
                    return {
                        onClick: () => show('inspections', entity.id),
                    };
                }}
            >
                {columns.map(({ name, title, hidden, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return (
                        <Table.Column
                            {...columnProps}
                            dataIndex={name}
                            key={key}
                            hidden={hidden ?? !settings[key]}
                            title={title ?? t(`${tBase}.fields.${key}`)}
                        />
                    );
                })}
            </Table>
        </List>
    );
};

export default DashboardInspectionsList;
