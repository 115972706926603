import { API_ROOT } from '@/constants';

import type { TokenResponseData } from '@/interfaces/auth';

const login = async (email: string, password: string): Promise<TokenResponseData> => {
    const request = new Request(`${API_ROOT}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);
    if (response.status >= 400 && response.status < 500) {
        throw new Error('The login or password you entered is incorrect.');
    }

    const data: TokenResponseData = await response.json();
    if (!data) {
        throw new Error('Failed to log you in');
    }

    return data;
};

export default login;
