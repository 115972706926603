import { useEffect, type FunctionComponent, useState, useCallback } from 'react';
import { Row, Col, Button, Select, SelectProps } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import type { IPropertyValue } from '@/interfaces/properties';

export type Props = SelectProps & {
    value?: IPropertyValue;
    fieldKey?: number;
    hasRemoveButton?: boolean;
    readonly?: boolean;
    onChange?: (value?: IPropertyValue) => void;
    onRemove?: (fieldKey: number) => void;
};

/** @todo Bad component design */
const PropertySelectField: FunctionComponent<Props> = ({
    value,
    hasRemoveButton,
    fieldKey,
    readonly,
    onChange,
    onRemove,
    ...props
}) => {
    const [innerValue, setInnerValue] = useState<IPropertyValue | undefined>(undefined);

    useEffect(() => {
        if (typeof value === 'object' && value != null) {
            /** @todo Improve this */
            setInnerValue((prev) => (prev === value ? prev : value));
        }
    }, [value, onChange]);

    const onInnerChange = useCallback(
        (value?: string) => {
            setInnerValue((prevValue?: IPropertyValue) => {
                const nextValue: IPropertyValue =
                    !!prevValue && typeof prevValue === 'object' ? { ...prevValue } : {};
                nextValue.value = value;

                onChange?.(nextValue);
                return nextValue;
            });
        },
        [onChange]
    );

    const onRemoveInner = useCallback(() => {
        fieldKey != null && onRemove?.(fieldKey);
    }, [fieldKey, onRemove]);

    const fieldComponent = (
        <Select
            {...props}
            disabled={readonly || innerValue?.readonly}
            value={innerValue?.value}
            onChange={(event) => onInnerChange(event.target.value)}
        />
    );

    if (!hasRemoveButton) {
        return fieldComponent;
    }

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: 16, flexGrow: 1 }}>
            <Col style={{ flexGrow: 1 }}>{fieldComponent}</Col>
            <Col>
                <Button
                    type='link'
                    disabled={readonly || innerValue?.readonly}
                    className='dynamic-delete-button'
                    onClick={onRemoveInner}
                >
                    <MinusCircleOutlined />
                </Button>
            </Col>
        </Row>
    );
};

export default PropertySelectField;
