import { useCallback, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Button } from 'antd';
import { SaveButton, type SaveButtonProps } from '@refinedev/antd';

import c from './StepsFooterButtons.module.css';

export type Props = {
    tBase: string;
    step: number;
    lastStep: number;
    isSaveDisabled: boolean;
    saveButtonProps: SaveButtonProps;
    className?: string;
    onStepChange: (step: number) => Promise<any | boolean>;
};

const StepsFooterButtons: FunctionComponent<Props> = ({
    tBase,
    step,
    lastStep,
    isSaveDisabled,
    saveButtonProps,
    className,
    onStepChange,
}) => {
    const t = useTranslate();

    const nextStep = useCallback(async () => {
        onStepChange(step + 1);
    }, [step, onStepChange]);

    const prevStep = useCallback(() => {
        onStepChange(step - 1);
    }, [step, onStepChange]);

    return (
        <div className={className}>
            <Button disabled={step === 0} className={c.prevButton} onClick={prevStep}>
                {t(`${tBase}.buttons.previous`)}
            </Button>

            {step === lastStep ? (
                <SaveButton {...saveButtonProps} disabled={isSaveDisabled} />
            ) : (
                <Button type='primary' onClick={nextStep}>
                    {t(`${tBase}.buttons.next`)}
                </Button>
            )}
        </div>
    );
};

export default StepsFooterButtons;
