import { Fragment, useCallback, useMemo, useState, type FunctionComponent } from 'react';
import {
    HttpError,
    useApiUrl,
    useCan,
    useCustom,
    useNavigation,
    useTranslate,
} from '@refinedev/core';
import { Form, Spin, Steps } from 'antd';
import { useStepsForm } from '@refinedev/antd';
import clsx from 'clsx';

import { PRODUCT_TYPE_PV_MODULES_ID, REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import StepsFooterButtons from '@/components/StepsFooterButtons';
import InspectionFormBase, { type FormValues as BaseFormValues } from './InspectionFormBase';
import InspectionFormSelma, { type FormValues as SelmaFormValues } from './InspectionFormSelma';
import InspectionFormPsi, { type FormValues as PsiFormValues } from './InspectionFormPsi';

import useUploadFilesToAmazonService from '@/hooks/useUploadFilesToAmazonService';
import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import useFactoriesOptions from '@/hooks/options/useFactoriesOptions';
import formValuesToInspection from '@/utils/inspection/formValuesToInspection';
import formValuesFromInspection from '@/utils/inspection/formValuesFromInspection';
import { errorNotification } from '@/notifications';
import type { IInspection, IInspectionDefectValue } from '@/interfaces/inspections';
import type { IProductType } from '@/interfaces/productTypes';
import type { IDefect } from '@/interfaces/defects';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { ICompany } from '@/interfaces/companies';

import c from './InspectionForm.module.css';

export type FormValues = BaseFormValues &
    SelmaFormValues &
    PsiFormValues & {
        inspectionDefects?: IInspectionDefectValue[];
    };

export type Props = {};

export const tBase = 'inspections.form';

const inspectionFilesFields = [
    { name: 'reportUpload', urlKey: 'reportUploadingUrl', flagName: 'isReportUploaded' },
    { name: 'flashDataUpload', urlKey: 'flashDataUploadingUrl', flagName: 'isFlashDataUploaded' },
];

const InspectionForm: FunctionComponent<Props> = () => {
    const { list } = useNavigation();
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { data: { can: canGetDefects } = {} } = useCan({ resource: 'defects', action: 'get' });

    const { data: productTypesOptions, isLoading: isProductTypesLoading } =
        useProductTypesOptions();
    const factories = useFactoriesOptions();

    const uploadReports = useUploadFilesToAmazonService({
        resource: 'inspections',
        tBase,
        fields: inspectionFilesFields,
    });

    // Typings of `stepsProps.onChange` are incorrect. The return value of `onChange` is the same as for `gotoStep`
    const { current, gotoStep, stepsProps, formProps, saveButtonProps, onFinish } = useStepsForm<
        IInspection,
        HttpError,
        FormValues
    >({
        errorNotification,
        redirect: false,
        async submit(v: any) {
            const values: FormValues = v;
            setIsSaving(true);
            try {
                const {
                    data,
                    reportUpload,
                    flashDataUpload,
                    isReportUploaded,
                    isFlashDataUploaded,
                } = formValuesToInspection(values, factories, productTypesOptions);

                const response = await onFinish(data);
                const entityId = response?.data?.id;
                if (entityId != null) {
                    await uploadReports(
                        entityId,
                        { reportUpload, flashDataUpload },
                        { isReportUploaded, isFlashDataUploaded },
                    );
                }

                list('inspections');
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
    });

    const formWatchParams = { form: formProps.form, preserve: true };
    const selectedProductTypeId: IProductType['id'] | undefined = Form.useWatch(
        'productTypeId',
        formWatchParams,
    );
    const factoryBrandId: IFactoryBrand['id'] | undefined = Form.useWatch(
        'factoryBrandId',
        formWatchParams,
    );
    const clientId: ICompany['id'] | undefined = Form.useWatch('clientId', formWatchParams);

    const selectedProductType = useMemo(() => {
        return selectedProductTypeId == null
            ? undefined
            : productTypesOptions.find(({ item }) => item?.id === selectedProductTypeId)?.item;
    }, [selectedProductTypeId, productTypesOptions]);
    const isPvModules = selectedProductType?.id === PRODUCT_TYPE_PV_MODULES_ID;

    const { data: defectsData } = useCustom<IDefect[]>({
        url: `${apiUrl}/defects`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetDefects && selectedProductType?.id != null,
        },
        config: {
            filters: [{ field: 'productTypeId', operator: 'eq', value: selectedProductType?.id }],
        },
    });
    const defects = defectsData?.data;

    const steps = useMemo(() => {
        const newSteps = [{ title: t(`${tBase}.steps.base`) }];
        if (isPvModules) {
            newSteps.push({ title: t(`${tBase}.steps.selma`) });
        }
        newSteps.push({ title: t(`${tBase}.steps.psi`) });

        return newSteps;
    }, [isPvModules, t]);

    const inspection = formProps.initialValues as IInspection | undefined;
    const initialValues: FormValues | undefined = useMemo(
        () => formValuesFromInspection(inspection, productTypesOptions),
        [inspection, productTypesOptions],
    );

    const onStepChange = useCallback(
        async (step: number) => {
            try {
                return await gotoStep(step);
            } catch (error) {
                /** @todo Display notification */
                console.error(error);
            }

            return false;
        },
        [gotoStep],
    );

    const currentStep = stepsProps.current;
    const stepName =
        currentStep === 0 ? 'base' : isPvModules ? (currentStep === 1 ? 'selma' : 'psi') : 'psi';

    if (isProductTypesLoading) {
        return <Spin className='w-full h-full' />;
    }

    return (
        <Fragment>
            <Steps
                current={currentStep}
                items={steps}
                className={c.steps}
                onChange={onStepChange}
            />
            <Form
                {...formProps}
                initialValues={initialValues}
                disabled={isSaving}
                layout='vertical'
                className={c.form}
            >
                <div className='flex-grow-1'>
                    {stepName === 'base' && (
                        <InspectionFormBase
                            formProps={formProps}
                            productType={selectedProductType}
                            factoryBrandId={factoryBrandId}
                            clientId={clientId}
                        />
                    )}
                    {stepName === 'selma' && <InspectionFormSelma defects={defects} />}
                    {stepName === 'psi' && (
                        <InspectionFormPsi defects={defects} isPvModules={isPvModules} />
                    )}
                </div>
            </Form>

            <StepsFooterButtons
                tBase={tBase}
                step={current}
                lastStep={steps.length - 1}
                isSaveDisabled={isSaving}
                saveButtonProps={saveButtonProps}
                className={clsx('d-flex row justify-between', c.actions)}
                onStepChange={onStepChange}
            />
        </Fragment>
    );
};

export default InspectionForm;
