import { type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useShow,
    useCan,
    useApiUrl,
    useCustom,
} from '@refinedev/core';
import { Show } from '@refinedev/antd';
import { Row, Col } from 'antd';

import { REACT_QUERY_MICRO_CACHE_TTL, DEFECT_CHART_MAX_ITEMS } from '@/constants';

import InspectionDefectsChart from '@/components/InspectionDefectsChart';
import InspectionBaseCard from './InspectionBaseCard';
import InspectionSummaryCard from './InspectionSummaryCard';
import InspectionStats from './InspectionStats';

import type { IInspection, IInspectionDefectSummary } from '@/interfaces/inspections';
import { DefectType } from '@/interfaces/enums/defects';

const tBase = 'inspections.show';

export const InspectionsShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'inspections', action: 'edit' });
    const { data: { can: canGetDefects } = {} } = useCan({ resource: 'defects', action: 'get' });

    const { queryResult } = useShow<IInspection>();
    const { data, isLoading } = queryResult;
    const inspection = data?.data;

    const { data: selmaDefectsData, isLoading: isSelmaLoading } = useCustom<
        IInspectionDefectSummary[]
    >({
        url: `${apiUrl}/inspections/chart/selma`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetDefects && inspection?.id != null,
        },
        config: {
            filters: [{ field: 'id', operator: 'eq', value: inspection?.id }],
        },
    });

    const { data: psiDefectsData, isLoading: isPsiLoading } = useCustom<IInspectionDefectSummary[]>(
        {
            url: `${apiUrl}/inspections/chart/psi`,
            method: 'get',
            queryOptions: {
                staleTime: REACT_QUERY_MICRO_CACHE_TTL,
                enabled: !!canGetDefects && inspection?.id != null,
            },
            config: {
                filters: [{ field: 'id', operator: 'eq', value: inspection?.id }],
            },
        },
    );

    const selmaDefectsSummary = selmaDefectsData?.data;
    const psiDefectsSummary = psiDefectsData?.data;

    const itemsCount =
        selmaDefectsSummary?.length == null
            ? undefined
            : Math.min(DEFECT_CHART_MAX_ITEMS, selmaDefectsSummary?.length);

    return (
        <Show
            title={t('inspections.titles.show')}
            canEdit={canEdit}
            isLoading={isLoading}
            contentProps={{
                style: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    boxShadow: 'none',
                },
                styles: {
                    body: {
                        padding: 0,
                    },
                },
            }}
        >
            <InspectionStats tBase={tBase} inspection={inspection} />

            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <Col xs={12}>
                    <InspectionDefectsChart
                        tBase={tBase}
                        defectType={DefectType.SELMA}
                        defectsSummary={selmaDefectsSummary}
                        isLoading={isSelmaLoading}
                        count={itemsCount}
                    />
                </Col>
                <Col xs={12}>
                    <InspectionDefectsChart
                        tBase={tBase}
                        defectType={DefectType.PSI}
                        defectsSummary={psiDefectsSummary}
                        isLoading={isPsiLoading}
                        count={itemsCount}
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={12}>
                    <InspectionBaseCard tBase={tBase} inspection={inspection} />
                </Col>
                <Col xs={12}>
                    <InspectionSummaryCard tBase={tBase} inspection={inspection} />
                </Col>
            </Row>
        </Show>
    );
};
