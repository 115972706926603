import { type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { InfoCircleOutlined } from '@ant-design/icons';

import Chart from './Chart';

import type { IAuditScoreResultChartData } from '@/interfaces/audits';

import c from './AuditScoreResultChart.module.css';

export type Props = {
    tBase: string;
    data?: IAuditScoreResultChartData;
};

const AuditScoreResultChart: FunctionComponent<Props> = ({ tBase, data }) => {
    const t = useTranslate();

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <InfoCircleOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.chart.scoreResult.title`)}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Chart data={data} />
        </Card>
    );
};

export default AuditScoreResultChart;
