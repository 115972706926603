import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { IProjectListItem } from '@/interfaces/projects';
import type { ICompany } from '@/interfaces/companies';

const useProjectsOptions = (clientId?: ICompany['id'], includeUnassigned?: boolean) => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetProjects } = {} } = useCan({
        resource: 'projects',
        action: 'get',
    });
    const { data } = useCustom<IProjectListItem[]>({
        url: `${apiUrl}/projects`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetProjects,
        },
    });

    const projects = data?.data;

    return useMemo(() => {
        const filteredProjects = projects
            ? clientId
                ? projects.filter((project) => {
                      const projectClientId = project.clientId ?? project.client?.id;
                      return (
                          (includeUnassigned && projectClientId == null) ||
                          projectClientId === clientId
                      );
                  })
                : projects
            : [];

        return filteredProjects.map(
            (item): AntdSelectOption<IProjectListItem> => ({
                value: item.id,
                label: item.name,
                item,
            }),
        );
    }, [projects, clientId, includeUnassigned]);
};

export default useProjectsOptions;
