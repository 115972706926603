import { type FunctionComponent, useState, useEffect } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';

import { PRODUCT_TYPE_PV_MODULES_ID } from '@/constants';

import ProductTypesForm, { type FormValues } from './form';
import { otherModulesFieldsTree } from './form/OtherModulesFields';
import { pvModulesFieldsTree } from './form/PVModulesFields';

import propertiesToValues from '@/utils/property/propertiesToValues';
import { errorNotification } from '@/notifications';
import type { IProductType } from '@/interfaces/productTypes';
import type { IPropertyFormValues } from '@/interfaces/properties';

export const ProductTypesEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [isPvModules, setIsPvModules] = useState<boolean | undefined>();

    const [propertiesValues, setPropertiesValues] = useState<IPropertyFormValues | undefined>();
    const { formProps, saveButtonProps, onFinish } = useForm<IProductType, HttpError, FormValues>({
        errorNotification,
    });

    const initialValues = formProps?.initialValues;
    useEffect(() => {
        const entityId = initialValues?.id;
        const newIsPvModules =
            entityId == null ? undefined : entityId === PRODUCT_TYPE_PV_MODULES_ID;
        setPropertiesValues(
            propertiesToValues(
                newIsPvModules ? pvModulesFieldsTree : otherModulesFieldsTree,
                initialValues?.properties,
            ),
        );

        setIsPvModules(newIsPvModules);
    }, [initialValues]);

    return (
        <Edit title={t('productTypes.titles.edit')} saveButtonProps={saveButtonProps}>
            {initialValues != null && isPvModules != null && (
                <ProductTypesForm
                    isPvModules={isPvModules}
                    formProps={{
                        ...formProps,
                        initialValues: {
                            ...initialValues,
                            ...propertiesValues,
                            uoms: initialValues?.uoms?.length
                                ? initialValues.uoms
                                : [{ name: '', factor: '' }],
                        },
                    }}
                    onFinish={onFinish}
                />
            )}
        </Edit>
    );
};
