import { type FunctionComponent } from 'react';

import PropertiesTreeField from '@/components/PropertiesTreeField';

import type { IPropertyTree } from '@/interfaces/properties';

export const otherModulesFieldsTree: IPropertyTree = [
    {
        name: 'product_tech',
        type: 'text',
    },
];

export type Props = {
    tBase: string;
};

const OtherModulesFieldsTree: FunctionComponent<Props> = ({ tBase }) => {
    return <PropertiesTreeField tree={otherModulesFieldsTree} tBase={`${tBase}.modules.other`} />;
};

export default OtherModulesFieldsTree;
