import { useMemo } from 'react';
import { useTranslate } from '@refinedev/core';

import type { AntdSelectOption } from '@/interfaces';
import { InspectionStatus } from '@/interfaces/enums/inspections';

const useInspectionStatusOptions = () => {
    const t = useTranslate();

    return useMemo(
        () =>
            Object.values(InspectionStatus).map(
                (status): AntdSelectOption => ({
                    value: status,
                    label: t(`inspections.status.${status}`),
                }),
            ),
        [t],
    );
};

export default useInspectionStatusOptions;
