import { ReactNode, useMemo, type FunctionComponent } from 'react';
import { Card, Collapse, CollapseProps, Table, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { DateField, TextField } from '@refinedev/antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { DATE_FORMAT_DAY, PRODUCT_TYPE_PV_MODULES_ID, DEFAULT_NA_VALUE } from '@/constants';

import { pvModulesFieldsTree } from '@/pages/productTypes/form/PVModulesFields';
import { otherModulesFieldsTree } from '@/pages/productTypes/form/OtherModulesFields';

import getPropertiesValuesFlat from '@/utils/property/getPropertiesValuesFlat';
import formatUserFullname from '@/utils/user/formatUserFullname';
import type { TableColumn } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';

import c from './InspectionSummaryCard.module.css';

export type Props = {
    tBase: string;
    inspection?: IInspection;
};

type SummaryItem = { name: string; value?: string | number | ReactNode };

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const InspectionSummaryCard: FunctionComponent<Props> = ({ tBase, inspection }) => {
    const t = useTranslate();

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: SummaryItem['name']) => (
                    <Typography.Title level={5} className='m-0'>
                        <TextField value={value} />
                    </Typography.Title>
                ),
            },
            {
                name: 'value',
                align: 'right',
                render: (value?: SummaryItem['value']) => {
                    if (value == null) {
                        return <TextField value={DEFAULT_NA_VALUE} />;
                    }

                    if (typeof value === 'string') {
                        return <TextField value={value} />;
                    }

                    return value;
                },
            },
        ],
        [],
    );

    const items = useMemo((): CollapseProps['items'] => {
        const productTypeId = inspection?.productTypeId ?? inspection?.productType?.id;
        const isPvModules = productTypeId === PRODUCT_TYPE_PV_MODULES_ID;
        const propertiesTree = isPvModules ? pvModulesFieldsTree : otherModulesFieldsTree;
        const propertiesValues = getPropertiesValuesFlat(
            propertiesTree,
            inspection?.productTechnologyInfo,
            ['cell_arch'],
        );

        const panels: { key: string; label: string; rows: SummaryItem[] }[] = [
            {
                key: 'numbers',
                label: t(`${tBase}.panels.numbers`),
                rows: [
                    {
                        name: t(`${tBase}.fields.uomTotal`),
                        value:
                            inspection?.uomTotal == null
                                ? undefined
                                : `${inspection.uomTotal.toFixed(2)}${inspection.uom?.name ? ` ${inspection.uom.name}` : ''}`,
                    },
                    { name: t(`${tBase}.fields.uomInspected`), value: inspection?.uomInspected },
                    {
                        name: t(`${tBase}.fields.totalProductionInput`),
                        value: inspection?.totalProductionInput,
                    },
                ],
            },
            {
                key: 'technology',
                label: t(`${tBase}.panels.technology`),
                rows: propertiesValues.map(({ field, value }) => {
                    return { name: t(`${tBase}.fields.${field.name}`), value: value?.value };
                }),
            },
            {
                key: 'standards',
                label: t(`${tBase}.panels.standards`),
                rows: [
                    {
                        name: t(`${tBase}.fields.bomCheckResult`),
                        value: inspection?.bomCheckResult
                            ? t(`inspections.bomCheckResult.${inspection.bomCheckResult}`)
                            : '',
                    },
                ],
            },
            {
                key: 'additional',
                label: t(`${tBase}.panels.additional`),
                rows: [
                    {
                        name: t(`${tBase}.fields.author`),
                        value: formatUserFullname(inspection?.author),
                    },
                    { name: t(`${tBase}.fields.subclient`), value: inspection?.subclient },
                    {
                        name: t(`${tBase}.fields.createdAt`),
                        value: inspection?.createdAt ? (
                            <DateField value={inspection.createdAt} format={DATE_FORMAT_DAY} />
                        ) : (
                            ''
                        ),
                    },
                    {
                        name: t(`${tBase}.fields.updatedAt`),
                        value: inspection?.updatedAt ? (
                            <DateField value={inspection.updatedAt} format={DATE_FORMAT_DAY} />
                        ) : (
                            ''
                        ),
                    },
                ],
            },
        ];

        let count = 1;
        let totalProductModelsOutput = 0;
        inspection?.productModels?.forEach(({ total }) => {
            totalProductModelsOutput += total == null ? 0 : total;
            panels[0].rows.push({
                name: t(`${tBase}.fields.productModelOutput`, { index: count++ }),
                value: total,
            });
        });

        panels[0].rows.push({
            name: t(`${tBase}.fields.productModelOutputTotal`),
            value: totalProductModelsOutput,
        });

        if (isPvModules) {
            panels[2].rows.push(
                { name: t(`${tBase}.fields.viStandard`), value: inspection?.viStandard },
                { name: t(`${tBase}.fields.ivmStandard`), value: inspection?.ivmStandard },
                { name: t(`${tBase}.fields.eliStandard`), value: inspection?.eliStandard },
            );
        } else {
            panels[2].rows.push({
                name: t(`${tBase}.fields.qualityStandard`),
                value: inspection?.qualityStandard,
            });
        }

        return panels.map(({ key, label, rows }) => {
            return {
                key,
                label,
                children: (
                    <Table
                        dataSource={rows}
                        bordered={false}
                        pagination={false}
                        showHeader={false}
                        rowKey='name'
                        className={c.list}
                    >
                        {columns.map(({ name, title, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                        })}
                    </Table>
                ),
            };
        });
    }, [inspection, columns, t, tBase]);

    return (
        <Card className={c.card}>
            <Collapse
                defaultActiveKey={['numbers']}
                ghost={true}
                expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                items={items}
            />
        </Card>
    );
};

export default InspectionSummaryCard;
