import { type CrudFilter } from '@refinedev/core';
import { Dayjs } from 'dayjs';

import type { ICompany } from '@/interfaces/companies';

export type SearchParams = Partial<
    Pick<ICompany, 'name'> & {
        createdAt: [Dayjs, Dayjs];
        updatedAt: [Dayjs, Dayjs];
    }
>;

const onSearch = ({ name, createdAt, updatedAt }: SearchParams): CrudFilter[] => {
    return [
        {
            field: 'name',
            operator: 'contains',
            value: name,
        },
        {
            field: 'createdAt',
            operator: 'gte',
            value: createdAt?.[0]?.isValid()
                ? createdAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt?.[1]?.isValid() ? createdAt[1].endOf('day').toISOString() : undefined,
        },
        {
            field: 'updatedAt',
            operator: 'gte',
            value: updatedAt?.[0]?.isValid()
                ? updatedAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'updatedAt',
            operator: 'lte',
            value: updatedAt?.[1]?.isValid()
                ? updatedAt[1].startOf('day').toISOString()
                : undefined,
        },
    ];
};

export default onSearch;
