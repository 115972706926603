import getPercent from '@/utils/getPercent';
import type { IInspection } from '@/interfaces/inspections';
import { DefectSeverity } from '@/interfaces/enums/defects';

export type Result = {
    total: number;
    inspected: number;
    percent: number;
};

const severities = Object.values(DefectSeverity);

const getInspectionTotalModulesWithDefects = (inspection?: IInspection): Result | undefined => {
    if (inspection == null) {
        return;
    }

    const result: Result = {
        total: 0,
        inspected: inspection.psiTotalInspect ?? 0 + inspection.selmaTotalInspect ?? 0,
        percent: 0,
    };

    const allDefects = [...(inspection.psiDefects ?? []), ...(inspection.selmaDefects ?? [])];
    allDefects.forEach((defect) => {
        severities.forEach((severity) => {
            if (defect[severity] != null) {
                result.total += Number(defect[severity]) || 0;
            }
        });
    });

    result.percent = getPercent(result.total, result.inspected);

    return result;
};

export default getInspectionTotalModulesWithDefects;
