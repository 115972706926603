import { useMemo, type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';
import { useCustom, useTranslate } from '@refinedev/core';
import { LineChartOutlined } from '@ant-design/icons';

import { API_ROOT } from '@/constants';

import LineChart from '@/components/LineChart';

import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import getFactoriesChartData from '@/utils/factory/getFactoriesChartData';
import type { IFactory, IFactoryChartItem } from '@/interfaces/factories';

import c from './FactoryFinanceCard.module.css';

export type Props = {
    tBase: string;
    factory?: IFactory;
};

const FactoryFinanceCard: FunctionComponent<Props> = ({ tBase, factory }) => {
    const t = useTranslate();

    const factoryTitle = useMemo(() => getFactoryTitle(factory), [factory]);

    const { data: chartResponse } = useCustom<IFactoryChartItem[]>({
        url: `${API_ROOT}/factories/${factory?.id}/chart`,
        method: 'get',
        queryOptions: {
            enabled: factory?.id != null,
        },
    });

    const chartResponseData = chartResponse?.data;
    const factoryChartData = useMemo(
        () => getFactoriesChartData(chartResponseData),
        [chartResponseData],
    );

    // const factoryChartData =
    //     chartResponse?.data.map(({ factoryName, quarter, value }) => ({
    //         xField: quarter,
    //         seriesField: factoryName,
    //         value,
    //     })) ?? [];

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <LineChartOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.manufacturerFinanceHealth`, {
                            factoryTitle,
                        })}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <LineChart data={factoryChartData} />
        </Card>
    );
};

export default FactoryFinanceCard;
