import { useCallback, useMemo, type FunctionComponent } from 'react';
import { Gauge } from '@ant-design/plots';
import { useTranslate } from '@refinedev/core';

import getAuditScoreResultColor from '@/utils/audit/getAuditScoreResultColor';
import type { IAuditScoreResultChartData } from '@/interfaces/audits';
import {
    AuditScoreResultColor,
    AuditScoreResult,
    // AuditScoreResultCurrentColor,
} from '@/interfaces/enums/audits';

export type Props = {
    data?: IAuditScoreResultChartData;
};

const scoreResults: { threshold: number; scoreResult: AuditScoreResult }[] = [
    { threshold: 60, scoreResult: AuditScoreResult.BAD },
    { threshold: 80, scoreResult: AuditScoreResult.AVERAGE },
    { threshold: 90, scoreResult: AuditScoreResult.GOOD },
    { threshold: 100, scoreResult: AuditScoreResult.EXCELLENT },
];

const thresholds = scoreResults.map(({ threshold }) => threshold);

const defaultColors = Object.values(AuditScoreResultColor).slice(0, -1);

const Chart: FunctionComponent<Props> = ({ data }) => {
    const t = useTranslate();

    const scoreResult = data?.filtered?.scoreResult;
    const avgTotalScore = data?.filtered?.avgTotalScore || 0;
    const { chartData, colors } = useMemo(() => {
        return {
            chartData: {
                name: t('audits.chart.scoreResult.tooltipTitle'),
                total: 100,
                thresholds,
                target: avgTotalScore,
            },
            colors: [...defaultColors, getAuditScoreResultColor(scoreResult)],
            // colors: [...defaultColors, AuditScoreResultCurrentColor],
        };
    }, [scoreResult, avgTotalScore, t]);

    const filteredText = useCallback(
        (target?: number) => {
            return [
                t('audits.chart.scoreResult.average', {
                    average: avgTotalScore.toFixed(2),
                }),
                t('audits.chart.scoreResult.score', {
                    score: (target || 0).toFixed(2),
                }),
            ].join('\n');
        },
        [avgTotalScore, t],
    );

    const getThresholdName = useCallback(
        (index: number | 'target') => {
            if (index === 'target') {
                return t(`audits.chart.scoreResult.target`);
            }

            const scoreResult = scoreResults[index].scoreResult;
            return t(`audits.scoreResult.${scoreResult}`);
        },
        [t],
    );

    return (
        <Gauge
            data={chartData}
            scale={{
                color: {
                    range: colors,
                },
            }}
            style={{
                textContent: filteredText,
            }}
            interaction={{
                tooltip: false,
                legendFilter: false,
            }}
            autoFit={true}
            legend={{
                color: {
                    itemMarker: 'circle',
                    position: 'bottom',
                    labelFormatter: getThresholdName,
                    layout: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                },
            }}
            // tooltip={{
            //     render: (target: number) =>
            //         t('audits.chart.scoreResult.score', {
            //             score: (target || 0).toFixed(2),
            //         }),
            // }}
            animation={{
                appear: {
                    duration: 2000,
                },
            }}
        />
    );
};

export default Chart;
