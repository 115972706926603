import { API_ROOT } from '@/constants';

import type { ProfileResponseData } from '@/interfaces/auth';

const fetchProfile = async (accessToken: string): Promise<ProfileResponseData> => {
    const request = new Request(`${API_ROOT}/auth/profile`, {
        method: 'GET',
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
    });

    const response = await fetch(request);
    if (response.status >= 400 && response.status < 500) {
        throw new Error('Failed to fetch user data.');
    }

    const data: ProfileResponseData = await response.json();
    if (!data) {
        throw new Error('Failed to fetch user data.');
    }

    return data;
};

export default fetchProfile;
