import { type FunctionComponent, useMemo, Fragment } from 'react';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { Col, Row, Typography } from 'antd';
import { AuditOutlined, BankOutlined, FileSearchOutlined, FireOutlined } from '@ant-design/icons';

import SummaryCard, { type SummaryItem } from './SummaryCard';

import type { IDashboardSummary } from '@/interfaces/dashboard';
import { IconComponent } from '@/interfaces';

export type Props = {};

const tBase = 'dashboard.summary';

const DashboardSummary: FunctionComponent<Props> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();

    const { data } = useCustom<IDashboardSummary>({
        url: `${apiUrl}/dashboard/summary`,
        method: 'get',
    });

    const summaryData = data?.data;
    const summaryItems = useMemo(() => {
        const now = new Date();
        const currentYear = now.getFullYear();
        const previousYear = currentYear - 1;
        const startYear = previousYear - 1;
        const inspectedCurrentYear = summaryData?.totalInspected?.find(
            ({ year }) => year === currentYear,
        );
        const inspectedPreviousYear = summaryData?.totalInspected?.find(
            ({ year }) => year === previousYear,
        );
        const inspectedStartYear = summaryData?.totalInspected?.find(
            ({ year }) => year === startYear,
        );

        const items: { name: string; items: SummaryItem[]; Icon: IconComponent }[] = [
            {
                name: 'reports',
                items: [
                    {
                        title: t(`${tBase}.reports.items.inspection`),
                        value: summaryData?.reports?.inspection,
                    },
                    {
                        title: t(`${tBase}.reports.items.audit`),
                        value: summaryData?.reports?.audit,
                    },
                ],
                Icon: AuditOutlined,
            },
            {
                name: 'factories',
                items: [
                    {
                        title: t(`${tBase}.factories.items.inspected`),
                        value: summaryData?.factories?.inspected,
                    },
                    {
                        title: t(`${tBase}.factories.items.audited`),
                        value: summaryData?.factories?.audited,
                    },
                ],
                Icon: BankOutlined,
            },
            {
                name: 'largestSuppliers',
                items: [
                    {
                        title: `${currentYear}`,
                        value: summaryData?.largestSuppliers?.find(
                            ({ year }) => year === currentYear,
                        )?.name,
                    },
                    {
                        title: `${previousYear}`,
                        value: summaryData?.largestSuppliers?.find(
                            ({ year }) => year === previousYear,
                        )?.name,
                    },
                ],
                Icon: FireOutlined,
            },
            {
                name: 'totalInspected',
                items: [
                    {
                        title: `${currentYear}`,
                        value:
                            inspectedCurrentYear?.value == null
                                ? undefined
                                : `${inspectedCurrentYear.value.toFixed(2)}${inspectedCurrentYear.uom == null ? '' : ` ${inspectedCurrentYear.uom}`}`,
                        change:
                            inspectedCurrentYear?.value == null ||
                            inspectedPreviousYear?.value == null
                                ? undefined
                                : inspectedCurrentYear?.value - inspectedPreviousYear?.value >= 0
                                  ? 'up'
                                  : 'down',
                    },
                    {
                        title: `${previousYear}`,
                        value:
                            inspectedPreviousYear?.value == null
                                ? undefined
                                : `${inspectedPreviousYear.value.toFixed(2)}${inspectedPreviousYear.uom == null ? '' : ` ${inspectedPreviousYear.uom}`}`,
                        change:
                            inspectedPreviousYear?.value == null ||
                            inspectedStartYear?.value == null
                                ? undefined
                                : inspectedPreviousYear?.value - inspectedStartYear?.value >= 0
                                  ? 'up'
                                  : 'down',
                    },
                ],
                Icon: FileSearchOutlined,
            },
        ];

        return items;
    }, [summaryData, t]);

    return (
        <Fragment>
            <Typography.Title level={4} style={{ marginTop: 4, marginBottom: 20 }}>
                {t('dashboard.summary.title')}
            </Typography.Title>
            <Row gutter={[16, 16]}>
                {summaryItems.map(({ name, items, Icon }) => {
                    return (
                        <Col key={name} xs={6}>
                            <SummaryCard
                                title={t(`${tBase}.${name}.title`)}
                                items={items}
                                Icon={Icon}
                            />
                        </Col>
                    );
                })}
            </Row>
        </Fragment>
    );
};

export default DashboardSummary;
