import { type FunctionComponent } from 'react';
import { Card, Spin, Typography } from 'antd';
import { useTranslate, useCustom, type CrudFilters } from '@refinedev/core';
import { LineChartOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { API_ROOT } from '@/constants';

import FinancialChart from './FinancialChart';

import type { IFactoryChartItem } from '@/interfaces/factories';

import c from './FinancialChartCard.module.css';

export type Props = {
    tBase: string;
    filters: CrudFilters;
};

export const FinancialChartCard: FunctionComponent<Props> = ({ tBase, filters }) => {
    const t = useTranslate();

    const { data: chartResponse, isLoading } = useCustom<IFactoryChartItem[]>({
        url: `${API_ROOT}/factories/chart`,
        method: 'get',
        config: { filters },
    });

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <LineChartOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.analytics.manufacturerFinanceHealth`)}
                    </Typography.Text>
                </div>
            }
            className={clsx(c.card, isLoading && c.cardLoading)}
        >
            {isLoading ? (
                <Spin className={c.spinner} key='spinner' />
            ) : (
                <FinancialChart data={chartResponse?.data} />
            )}
        </Card>
    );
};

export default FinancialChartCard;
