import { useMemo, type FunctionComponent } from 'react';
import { useCan, useNavigation, useTranslate } from '@refinedev/core';
import { Col, Row, Space, Table, type TableProps } from 'antd';
import { DateField, EditButton, TextField } from '@refinedev/antd';

import { DATE_FORMAT, DEFAULT_NA_VALUE } from '@/constants';

import TableSettingsButton from '@/components/TableSettingsButton';
import CustomDeleteButton from '@/components/CustomDeleteButton';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import type { TableColumn } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';

export type Props = {
    tableProps: TableProps<IFactory>;
};

type FactoryColumns = TableColumn<IFactory, keyof IFactory | 'actions'>[];

const tBase = 'factories.list';

const FactoriesTable: FunctionComponent<Props> = ({ tableProps }) => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'factories', action: 'edit' });
    const { data: { can: canDelete } = {} } = useCan({ resource: 'factories', action: 'delete' });

    const columns: FactoryColumns = useMemo(
        () => [
            {
                name: 'name',
                sorter: true,
                render: (value: IFactory['name']) => <TextField value={value} />,
            },
            {
                name: 'brand',
                sorter: true,
                render: (value?: IFactory['brand']) =>
                    value?.name ? <TextField value={value.name} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'country',
                sorter: true,
                render: (value: IFactory['country']) =>
                    value ? <TextField value={value} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'city',
                sorter: true,
                render: (value: IFactory['city']) =>
                    value ? <TextField value={value} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'createdAt',
                sorter: true,
                render: (value?: IFactory['createdAt']) =>
                    value ? <DateField value={value} format={DATE_FORMAT} /> : DEFAULT_NA_VALUE,
            },
            {
                name: 'actions',
                render: (_value: void, entity) => (
                    <Space>
                        <EditButton
                            hideText={true}
                            size='small'
                            disabled={!canEdit}
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />
                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            disabled={!canDelete}
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [canEdit, canDelete],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'factories',
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} className='text-right'>
                <TableSettingsButton
                    title={t(`${tBase}.tableSettings.title`)}
                    columnsList={columnsList}
                    settings={settings}
                    onSettingsChange={onSettingsChange}
                />
            </Col>
            <Col xs={24}>
                <Table
                    {...tableProps}
                    rowKey='id'
                    rowClassName='cursor-pointer'
                    className='table-with-header-cell-nowrap'
                    onRow={(entity) => {
                        return {
                            onClick: () => show('factories', entity.id),
                        };
                    }}
                >
                    {columns.map(({ name, title, hidden, ...columnProps }) => {
                        const key = Array.isArray(name) ? name.join('.') : name;
                        return (
                            <Table.Column
                                {...columnProps}
                                dataIndex={name}
                                key={key}
                                hidden={hidden ?? !settings[key]}
                                title={title ?? t(`${tBase}.fields.${key}`)}
                            />
                        );
                    })}
                </Table>
            </Col>
        </Row>
    );
};

export default FactoriesTable;
