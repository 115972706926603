import { type FunctionComponent } from 'react';
import { TextField } from '@refinedev/antd';

import type { UnitOfMeasure } from '@/interfaces';

export type Props = {
    value?: UnitOfMeasure[];
};

const UnitsOfMeasureDisplay: FunctionComponent<Props> = ({ value }) => {
    return <TextField value={`${value?.map(({ name }) => name).join(', ')}`} />;
};

export default UnitsOfMeasureDisplay;
