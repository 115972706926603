import type { AuditNCFieldKey } from '@/interfaces/audits';

export enum AuditStatus {
    PLANNED = 'Scheduling',
    SCHEDULED = 'Scheduled',
    ONGOING = 'In progress',
    COMPLETE = 'Completed',
}

export enum AuditStatusColor {
    SCHEDULED = '#df370d',
    PLANNED = '#df370d',
    ONGOING = '#f9e966',
    COMPLETE = '#acd68f',
    UNKNOWN = '#b8b8b8',
}

export enum AuditNccaStatus {
    ONGOING = 'ongoing',
    COMPLETE = 'complete',
    NA = 'n/a',
}

export enum AuditNccaStatusColor {
    ONGOING = '#f9e966',
    COMPLETE = '#acd68f',
    NA = '#b8b8b8',
    UNKNOWN = '#b8b8b8',
}

export enum AuditScoreResult {
    BAD = 'Bad',
    AVERAGE = 'Average',
    GOOD = 'Good',
    EXCELLENT = 'Excellent',
}

export enum AuditScoreResultColor {
    BAD = '#df370d',
    AVERAGE = '#f9e966',
    GOOD = '#acd68f',
    EXCELLENT = '#70adf6',
    UNKNOWN = '#b8b8b8',
}

export enum AuditNcsRateResultColor {
    BAD = '#df370d',
    AVERAGE = '#f9e966',
    GOOD = '#acd68f',
    EXCELLENT = '#70adf6',
    UNKNOWN = '#b8b8b8',
}

export enum AuditNCCASeverity {
    MINOR = 'minor',
    MAJOR = 'major',
    CRITICAL = 'critical',
}

export enum AuditNCCASeverityColor {
    MINOR = '#ffd966',
    MAJOR = '#ff9900',
    CRITICAL = '#cc4125',
    UNKNOWN = '#b8b8b8',
}

export const auditNcfields: AuditNCFieldKey[] = [...Object.values(AuditNCCASeverity), 'score'];
