import { Fragment, useCallback, useMemo, type FunctionComponent } from 'react';
import { useCan, useTranslate } from '@refinedev/core';
import {
    DatePicker,
    Form,
    type FormProps,
    Input,
    InputNumber,
    Select,
    Upload,
    type UploadFile,
} from 'antd';
import { getValueFromEvent } from '@refinedev/antd';
import dayjs from 'dayjs';

import { DEFAULT_MIN_DATE, MAX_NAME_LENGTH } from '@/constants';

import ProductModelsField from './ProductModelsField';

import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import useProjectsOptions from '@/hooks/options/useProjectsOptions';
import useCompaniesOptions from '@/hooks/options/useCompaniesOptions';
import useUomsOptions from '@/hooks/options/useUomsOptions';
import useInspectionResultOptions from '@/hooks/options/useInspectionResultOptions';
import useInspectionStatusOptions from '@/hooks/options/useInspectionStatusOptions';
import useFactoryBrandsOptions from '@/hooks/options/useFactoryBrandsOptions';
import useFactoryLocationsOptions from '@/hooks/options/useFactoryLocationsOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import type { FormField } from '@/interfaces';
import type { IInspection } from '@/interfaces/inspections';
import type { IProductType } from '@/interfaces/productTypes';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';
import type { ICompany } from '@/interfaces/companies';

export type Props = {
    formProps: FormProps;
    isPvModules: boolean;
    productType?: IProductType;
    factoryBrandId?: IFactoryBrand['id'];
    clientId?: ICompany['id'];
};

export type FormValues = Pick<
    IInspection,
    | 'name'
    | 'total'
    | 'projectId'
    | 'clientId'
    | 'factoryId'
    | 'productTypeId'
    | 'productModels'
    | 'batchNumber'
    | 'startDate'
    | 'completeDate'
    | 'reportDate'
    | 'imagesLink'
    | 'status'
    | 'result'
    | 'totalReworks'
    | 'uomId'
    | 'uomTotal'
    | 'uomInspected'
    | 'totalProductionInput'
    | 'isReportUploaded'
    | 'isFlashDataUploaded'
> & {
    reportUpload?: UploadFile[];
    flashDataUpload?: UploadFile[];
    factoryBrandId?: IFactoryBrand['id'];
    factoryLocation?: string;
};

const tBase = 'inspections.form.base';

type FormFields = FormField<keyof FormValues | 'productModelsList'>[];

const InspectionsBaseFields: FunctionComponent<Props> = ({
    formProps,
    isPvModules,
    productType,
    factoryBrandId,
    clientId,
}) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const factoryBrandsOptions = useFactoryBrandsOptions();
    const factoryLocationsOptions = useFactoryLocationsOptions(factoryBrandId);
    const { data: productTypesOptions } = useProductTypesOptions();
    const clientsOptions = useCompaniesOptions();
    const projectsOptions = useProjectsOptions(clientId, true);
    const uomsOptions = useUomsOptions(productType);
    const statusOptions = useInspectionStatusOptions();
    const resultOptions = useInspectionResultOptions();

    const setFieldValue = formProps?.form?.setFieldValue;

    const onFactoryBrandChange = useCallback(() => {
        setFieldValue?.('factoryLocation', undefined);
    }, [setFieldValue]);

    const onClientChange = useCallback(() => {
        setFieldValue?.('projectId', undefined);
    }, [setFieldValue]);

    const fields: FormFields = useMemo(() => {
        const newFields: FormFields = [
            {
                name: 'name',
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: t(`${tBase}.rules.name.required`),
                    },
                    {
                        max: MAX_NAME_LENGTH,
                        message: t(`${tBase}.rules.name.tooLong`, { length: MAX_NAME_LENGTH }),
                    },
                ],
                field: <Input placeholder={t(`${tBase}.placeholders.name`)} autoComplete='off' />,
            },
            {
                name: 'total',
                rules: [{ min: 0, type: 'number', message: t(`${tBase}.rules.total.min`) }],
                field: (
                    <InputNumber
                        type='number'
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.total`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
        ];
        if (canGetCompanies) {
            newFields.push({
                name: 'clientId',
                rules: [{ required: true, message: t(`${tBase}.rules.clientId.required`) }],
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.clientId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={clientsOptions}
                        onChange={onClientChange}
                    />
                ),
            });
        }
        newFields.push(
            {
                name: 'projectId',
                field: (
                    <Select
                        placeholder={
                            !!clientId && !projectsOptions.length
                                ? t(`${tBase}.placeholders.projectIdNoneForClient`)
                                : t(`${tBase}.placeholders.projectId`)
                        }
                        allowClear={true}
                        showSearch={true}
                        disabled={!clientId || !projectsOptions.length ? true : undefined}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={projectsOptions}
                    />
                ),
            },
            {
                name: 'factoryBrandId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryBrandId`)}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryBrandsOptions}
                        onChange={onFactoryBrandChange}
                    />
                ),
            },
            {
                name: 'factoryLocation',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.factoryLocation`)}
                        showSearch={true}
                        allowClear={true}
                        disabled={!factoryBrandId ? true : undefined}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={factoryLocationsOptions}
                    />
                ),
            },
            {
                name: 'productTypeId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.productTypeId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
            {
                name: 'productModelsList',
                field: <ProductModelsField tBase={tBase} isPvModules={isPvModules}/>,
            },
            {
                name: 'batchNumber',
                field: (
                    <Input
                        placeholder={t(`${tBase}.placeholders.batchNumber`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'startDate',
                getValueProps: (value?: IInspection['startDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: <DatePicker minDate={DEFAULT_MIN_DATE} className='w-full' />,
            },
            {
                name: 'completeDate',
                getValueProps: (value?: IInspection['completeDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: <DatePicker minDate={DEFAULT_MIN_DATE} className='w-full' />,
            },
            {
                name: 'reportDate',
                getValueProps: (value?: IInspection['reportDate']) => ({
                    value: value ? dayjs(value) : '',
                }),
                field: <DatePicker minDate={DEFAULT_MIN_DATE} className='w-full' />,
            },
            {
                name: 'imagesLink',
                rules: [
                    {
                        validator: async (_rule: any, value?: string) => {
                            if (value && !/^(http(s)?:\/\/)?\w+(\.\w+)+(\/.*?)?$/.test(value)) {
                                throw new Error(t(`${tBase}.rules.imagesLink.invalidUrl`));
                            }
                        },
                    },
                ],
                field: (
                    <Input placeholder={t(`${tBase}.placeholders.imagesLink`)} autoComplete='off' />
                ),
            },
            {
                name: 'reportUpload',
                valuePropName: 'fileList',
                getValueFromEvent,
                rules: [
                    {
                        validator: async (_rule: any, value?: UploadFile[]) => {
                            if (!Array.isArray(value) || !value.length) {
                                return;
                            }
                            if (value[0].type !== 'application/pdf') {
                                throw new Error(
                                    t(`${tBase}.rules.reportUpload.invalidFileType`, {
                                        types: '.pdf',
                                    }),
                                );
                            }
                        },
                    },
                ],
                field: (
                    <Upload.Dragger
                        listType='picture'
                        maxCount={1}
                        multiple={false}
                        accept='.pdf,application/pdf'
                        beforeUpload={() => false}
                    >
                        <p className='ant-upload-text'>{t(`${tBase}.fields.reportUploadText`)}</p>
                    </Upload.Dragger>
                ),
            },
        );
        if (isPvModules) {
            newFields.push({
                name: 'flashDataUpload',
                valuePropName: 'fileList',
                getValueFromEvent,
                rules: [
                    {
                        validator: async (_rule: any, value?: UploadFile[]) => {
                            if (!Array.isArray(value) || !value.length) {
                                return;
                            }
                            if (value[0].type !== 'application/pdf') {
                                throw new Error(
                                    t(`${tBase}.rules.flashDataUpload.invalidFileType`, {
                                        types: '.pdf',
                                    }),
                                );
                            }
                        },
                    },
                ],
                field: (
                    <Upload.Dragger
                        listType='picture'
                        maxCount={1}
                        multiple={false}
                        accept='.pdf,application/pdf'
                        beforeUpload={() => false}
                    >
                        <p className='ant-upload-text'>
                            {t(`${tBase}.fields.flashDataUploadText`)}
                        </p>
                    </Upload.Dragger>
                ),
            });
        }
        newFields.push(
            {
                name: 'status',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.status`)}
                        showSearch={true}
                        optionFilterProp='label'
                        options={statusOptions}
                    />
                ),
            },
            {
                name: 'result',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.result`)}
                        showSearch={true}
                        optionFilterProp='label'
                        options={resultOptions}
                    />
                ),
            },
            {
                name: 'totalReworks',
                rules: [{ min: 0, type: 'number', message: t(`${tBase}.rules.totalReworks.min`) }],
                field: (
                    <InputNumber
                        type='number'
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.totalReworks`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'uomId',
                field: (
                    <Select
                        placeholder={t(`${tBase}.placeholders.uomId`)}
                        showSearch={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={uomsOptions}
                    />
                ),
            },
            {
                name: 'uomTotal',
                rules: [{ min: 0, type: 'number', message: t(`${tBase}.rules.uomTotal.min`) }],
                field: (
                    <InputNumber
                        type='number'
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.uomTotal`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'uomInspected',
                rules: [{ min: 0, type: 'number', message: t(`${tBase}.rules.uomInspected.min`) }],
                field: (
                    <InputNumber
                        type='number'
                        precision={0}
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.uomInspected`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
            {
                name: 'totalProductionInput',
                rules: [
                    {
                        min: 0,
                        type: 'number',
                        message: t(`${tBase}.rules.totalProductionInput.min`),
                    },
                ],
                field: (
                    <InputNumber
                        type='number'
                        autoComplete='off'
                        placeholder={t(`${tBase}.placeholders.totalProductionInput`)}
                        controls={false}
                        changeOnWheel={false}
                        className='w-full'
                    />
                ),
            },
        );

        return newFields;
    }, [
        factoryBrandId,
        clientId,
        projectsOptions,
        factoryLocationsOptions,
        factoryBrandsOptions,
        productTypesOptions,
        clientsOptions,
        statusOptions,
        resultOptions,
        uomsOptions,
        canGetCompanies,
        onFactoryBrandChange,
        onClientChange,
        isPvModules,
        t,
    ]);

    return (
        <Fragment>
            {fields.map(({ name, field, ...fieldProps }) => {
                return (
                    <Form.Item
                        {...fieldProps}
                        key={name}
                        label={t(`${tBase}.fields.${name}`)}
                        name={name}
                    >
                        {field}
                    </Form.Item>
                );
            })}
        </Fragment>
    );
};

export default InspectionsBaseFields;
