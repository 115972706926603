import { useMemo } from 'react';
import { Authenticated, Refine, ResourceProps } from '@refinedev/core';
import { Route, Routes, BrowserRouter, Outlet } from 'react-router-dom';
import routerProvider, { NavigateToResource } from '@refinedev/react-router-v6';
import { notificationProvider, ErrorComponent, ThemedLayoutV2 } from '@refinedev/antd';
import {
    BankOutlined,
    CopyrightOutlined,
    DashboardOutlined,
    FolderAddOutlined,
    PaperClipOutlined,
    AuditOutlined,
    SolutionOutlined,
    StarOutlined,
    TrademarkOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/es/locale';
import enUS from 'antd/locale/en_US';

import '@refinedev/antd/dist/reset.css';
// import '@fontsource/roboto';
import '@fontsource-variable/inter';

import { Header, OffLayoutArea, Title, Footer } from '@/components';
import { ThemedSiderV2 } from '@/components/themedLayout/sider';

import { UsersList, UsersCreate, UsersEdit, UsersShow } from '@/pages/users';
import { CompaniesList, CompaniesCreate, CompaniesEdit, CompaniesShow } from '@/pages/companies';
import { FactoriesList, FactoriesCreate, FactoriesEdit, FactoriesShow } from '@/pages/factories';
import {
    FactoryBrandsList,
    FactoryBrandsCreate,
    FactoryBrandsEdit,
    FactoryBrandsShow,
} from '@/pages/factoryBrands';
import {
    InspectionsList,
    InspectionsCreate,
    InspectionsEdit,
    InspectionsShow,
} from '@/pages/inspections';
import { ProjectsList, ProjectsCreate, ProjectsEdit, ProjectShow } from '@/pages/projects';
import {
    ProductTypesCreate,
    ProductTypesList,
    ProductTypesEdit,
    ProductTypesShow,
} from '@/pages/productTypes';
import { DefectsCreate, DefectsList, DefectsEdit, DefectsShow } from '@/pages/defects';
import { AuditsList, AuditsCreate, AuditsEdit, AuditsShow } from '@/pages/audits';
import { ChangePasswordPage } from '@/pages/changePassword';
import { LoginPage } from '@/pages/login';
import { DashboardPage } from '@/pages/dashboard';

import dataProvider from '@/utils/dataProvider';
import authProvider from '@/utils/auth/authProvider';
import can from '@/utils/auth/can';
import { Language } from '@/interfaces/enums/global';

import '@/styles/common.css';
import './App.less';

const locales: Record<Language, Locale> = { [Language.en]: enUS };

function App() {
    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string | string[], params: Record<string, string>) => t(key, params),
        changeLocale: (locale: string) => i18n.changeLanguage(locale),
        getLocale: () => i18n.language,
    };

    const currentLocale = i18n.language as Language;

    const resources = useMemo((): ResourceProps[] => {
        return [
            {
                name: 'dashboard',
                list: '/',
                meta: { icon: <DashboardOutlined /> },
            },
            {
                name: 'audits',
                list: '/audits',
                create: '/audits/create',
                edit: '/audits/edit/:id',
                show: '/audits/show/:id',
                meta: {
                    icon: <AuditOutlined />,
                },
            },
            {
                name: 'inspections',
                list: '/inspections',
                create: '/inspections/create',
                edit: '/inspections/edit/:id',
                show: '/inspections/show/:id',
                meta: {
                    icon: <SolutionOutlined />,
                },
            },
            {
                name: 'projects',
                list: '/projects',
                create: '/projects/create',
                edit: '/projects/edit/:id',
                show: '/projects/show/:id',
                meta: {
                    icon: <FolderAddOutlined />,
                },
            },
            {
                name: 'productTypes',
                list: '/productTypes',
                create: '/productTypes/create',
                edit: '/productTypes/edit/:id',
                show: '/productTypes/show/:id',
                meta: {
                    icon: <PaperClipOutlined />,
                },
            },
            {
                name: 'defects',
                list: '/defects',
                create: '/defects/create',
                edit: '/defects/edit/:id',
                show: '/defects/show/:id',
                meta: {
                    icon: <StarOutlined />,
                },
            },
            {
                name: 'factories',
                list: '/factories',
                create: '/factories/create',
                edit: '/factories/edit/:id',
                show: '/factories/show/:id',
                meta: {
                    icon: <BankOutlined />,
                },
            },
            {
                name: 'factoryBrands',
                list: '/factoryBrands',
                create: '/factoryBrands/create',
                edit: '/factoryBrands/edit/:id',
                show: '/factoryBrands/show/:id',
                meta: {
                    icon: <CopyrightOutlined />,
                },
            },
            {
                name: 'companies',
                list: '/companies',
                create: '/companies/create',
                edit: '/companies/edit/:id',
                show: '/companies/show/:id',
                meta: {
                    icon: <TrademarkOutlined />,
                },
            },
            {
                name: 'users',
                list: '/users',
                create: '/users/create',
                edit: '/users/edit/:id',
                show: '/users/show/:id',
                meta: {
                    icon: <UserOutlined />,
                },
            },
        ];
    }, []);

    return (
        <BrowserRouter>
            <ConfigProvider
                locale={locales[currentLocale]}
                // theme={{ token: { fontFamily: "'Roboto', sans-serif" } }}
                theme={{ token: { fontFamily: "'Inter Variable', sans-serif" } }}
            >
                <Refine
                    options={{
                        disableTelemetry: true,
                        warnWhenUnsavedChanges: true,
                    }}
                    routerProvider={routerProvider}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    notificationProvider={notificationProvider}
                    resources={resources}
                    accessControlProvider={{ can }}
                >
                    <Routes>
                        <Route
                            element={
                                <Authenticated key='authenticated'>
                                    <ThemedLayoutV2
                                        Header={Header}
                                        Title={Title}
                                        Footer={Footer}
                                        Sider={ThemedSiderV2}
                                        OffLayoutArea={OffLayoutArea}
                                    >
                                        <Outlet />
                                    </ThemedLayoutV2>
                                </Authenticated>
                            }
                        >
                            <Route index element={<DashboardPage />} />
                            <Route path='/audits'>
                                <Route index element={<AuditsList />} />
                                <Route path='create' element={<AuditsCreate />} />
                                <Route path='edit/:id' element={<AuditsEdit />} />
                                <Route path='show/:id' element={<AuditsShow />} />
                            </Route>
                            <Route path='/inspections'>
                                <Route index element={<InspectionsList />} />
                                <Route path='create' element={<InspectionsCreate />} />
                                <Route path='edit/:id' element={<InspectionsEdit />} />
                                <Route path='show/:id' element={<InspectionsShow />} />
                            </Route>
                            <Route path='/projects'>
                                <Route index element={<ProjectsList />} />
                                <Route path='create' element={<ProjectsCreate />} />
                                <Route path='edit/:id' element={<ProjectsEdit />} />
                                <Route path='show/:id' element={<ProjectShow />} />
                            </Route>
                            <Route path='/productTypes'>
                                <Route index element={<ProductTypesList />} />
                                <Route path='create' element={<ProductTypesCreate />} />
                                <Route path='edit/:id' element={<ProductTypesEdit />} />
                                <Route path='show/:id' element={<ProductTypesShow />} />
                            </Route>
                            <Route path='/defects'>
                                <Route index element={<DefectsList />} />
                                <Route path='create' element={<DefectsCreate />} />
                                <Route path='edit/:id' element={<DefectsEdit />} />
                                <Route path='show/:id' element={<DefectsShow />} />
                            </Route>
                            <Route path='/factories'>
                                <Route index element={<FactoriesList />} />
                                <Route path='create' element={<FactoriesCreate />} />
                                <Route path='edit/:id' element={<FactoriesEdit />} />
                                <Route path='show/:id' element={<FactoriesShow />} />
                            </Route>
                            <Route path='/factoryBrands'>
                                <Route index element={<FactoryBrandsList />} />
                                <Route path='create' element={<FactoryBrandsCreate />} />
                                <Route path='edit/:id' element={<FactoryBrandsEdit />} />
                                <Route path='show/:id' element={<FactoryBrandsShow />} />
                            </Route>
                            <Route path='/companies'>
                                <Route index element={<CompaniesList />} />
                                <Route path='create' element={<CompaniesCreate />} />
                                <Route path='edit/:id' element={<CompaniesEdit />} />
                                <Route path='show/:id' element={<CompaniesShow />} />
                            </Route>
                            <Route path='/users'>
                                <Route index element={<UsersList />} />
                                <Route path='create' element={<UsersCreate />} />
                                <Route path='edit/:id' element={<UsersEdit />} />
                                <Route path='show/:id' element={<UsersShow />} />
                            </Route>
                        </Route>
                        <Route
                            element={
                                <Authenticated key='anonymous' fallback={<Outlet />}>
                                    <NavigateToResource resource='dashboard' />
                                </Authenticated>
                            }
                        >
                            <Route path='/login' element={<LoginPage />} />
                        </Route>
                        <Route path='/change-password' element={<ChangePasswordPage />} />
                        <Route path='*' element={<ErrorComponent />} />
                    </Routes>
                </Refine>
            </ConfigProvider>
        </BrowserRouter>
    );
}

export default App;
