import { useRef, useEffect, useState, type FunctionComponent } from 'react';
import mapboxgl, { Marker, Map, NavigationControl } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxSdk from '@mapbox/mapbox-sdk';
import geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
    MAPBOX_TOKEN,
    MAPBOX_DEFAULT_CENTER,
    MAPBOX_DEFAULT_ZOOM,
    MAPBOX_DEFAULT_LOCALE,
} from '@/constants';

import mapboxLngLatToSimple from '@/utils/mapboxLngLatToSimple';
import coordsToSimpleLngLat from '@/utils/coordsToSimpleLngLat';
import type { LocationAddress, SimpleLngLat } from '@/interfaces';

mapboxgl.accessToken = MAPBOX_TOKEN;

export type Props = {
    defaultLocation?: SimpleLngLat;
    needInitialAddress?: boolean;
    className?: string;
    onLocationChange: (location: LocationAddress) => void;
};

type GeocoderResultContext = {
    id: string;
    mapbox_id: string;
    text: string;
    'text_en-US'?: string;
    languages: string;
    'language_en-US'?: string;
    wikidata?: string;
    short_code?: string;
};

type GeocoderResult = {
    id: string;
    center: [number, number];
    context?: GeocoderResultContext[];
    place_name?: string;
    'place_name_en-US'?: string;
    place_type: string[];
};

const locale = MAPBOX_DEFAULT_LOCALE;
const mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
const geocodingClient = geocoding(mapboxClient);

// @link https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md
const MapBoxGeocoder: FunctionComponent<Props> = ({
    defaultLocation,
    needInitialAddress,
    className,
    onLocationChange,
}) => {
    const mapRef = useRef(null);
    const [markerLocation, setMarkerLocation] = useState<SimpleLngLat | undefined>();
    const [lookupLongitude, setLookupLongitude] = useState<number | undefined>();
    const [lookupLatitude, setLookupLatitude] = useState<number | undefined>();
    const [map, setMap] = useState<Map | undefined>();

    useEffect(() => {
        setMarkerLocation(defaultLocation);
    }, [defaultLocation]);

    useEffect(() => {
        if (needInitialAddress) {
            setLookupLongitude(defaultLocation?.[0]);
            setLookupLatitude(defaultLocation?.[1]);
        }
    }, [defaultLocation, needInitialAddress]);

    useEffect(() => {
        if (
            lookupLongitude == null ||
            typeof lookupLongitude !== 'number' ||
            lookupLatitude == null ||
            typeof lookupLatitude !== 'number'
        ) {
            return;
        }

        let isAborted = false;
        const fetchLocations = async () => {
            const response = await geocodingClient
                .reverseGeocode({
                    query: [lookupLongitude, lookupLatitude],
                    language: [locale],
                    mode: 'mapbox.places',
                    types: ['address', 'poi', 'postcode'],
                    limit: 1,
                })
                .send();

            if (isAborted) {
                return;
            }

            const result: { features?: GeocoderResult[] } = response.body;
            const feature =
                result.features?.find(({ id }) => id.startsWith('address')) ??
                result.features?.find(({ id }) => id.startsWith('poi')) ??
                result.features?.find(({ id }) => id.startsWith('postcode'));

            if (feature) {
                setLookupLongitude(undefined);
                setLookupLatitude(undefined);
                setMarkerLocation([lookupLongitude, lookupLatitude]);
                const city = feature.context?.find(({ id }) => id.startsWith('place'));
                const country = feature.context?.find(({ id }) => id.startsWith('country'));
                onLocationChange({
                    address: feature['place_name_en-US'] ?? feature?.place_name,
                    city: city?.['text_en-US'] ?? city?.text,
                    country: country?.['text_en-US'] ?? country?.text,
                    longitude: lookupLongitude,
                    latitude: lookupLatitude,
                });
            }
        };

        fetchLocations();

        return () => {
            isAborted = true;
        };
    }, [lookupLongitude, lookupLatitude, onLocationChange]);

    useEffect(() => {
        if (!map || !markerLocation) {
            return;
        }

        const onMarkerDragEnd = (e: any) => {
            const newLocation: SimpleLngLat | undefined = coordsToSimpleLngLat(
                e.target._lngLat.lng,
                e.target._lngLat.lat,
            );
            if (newLocation == null) {
                setMarkerLocation(undefined);
                setLookupLongitude(undefined);
                setLookupLatitude(undefined);
            } else {
                setMarkerLocation((prev) => {
                    if (prev != null) {
                        prev[0] = newLocation[0];
                        prev[1] = newLocation[1];
                        return prev;
                    }

                    return newLocation;
                });

                setLookupLongitude(newLocation[0]);
                setLookupLatitude(newLocation[1]);
            }
        };

        const marker = new Marker({ draggable: true });
        marker.setLngLat(markerLocation);
        marker.on('dragend', onMarkerDragEnd);

        try {
            marker.addTo(map);
        } catch (error) {
            console.error(error);
        }

        return () => {
            marker.off('dragend', onMarkerDragEnd);
            marker.remove();
        };
    }, [map, markerLocation]);

    useEffect(() => {
        if (!mapRef.current) {
            return;
        }

        const map = new Map({
            container: mapRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: defaultLocation ?? MAPBOX_DEFAULT_CENTER,
            zoom: defaultLocation ? 16 : MAPBOX_DEFAULT_ZOOM,
        });

        map.addControl(new NavigationControl(), 'bottom-right');

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            language: locale,
            marker: false,
            mapboxgl,
        });

        let currentMarkerId: number = 0;
        let geocoderMarkerId: number = 0;

        geocoder.on('loading', () => {
            geocoderMarkerId = currentMarkerId = Math.random();
        });

        geocoder.on('result', ({ result }: { result: GeocoderResult }) => {
            if (geocoderMarkerId === currentMarkerId) {
                setLookupLongitude(undefined);
                setLookupLatitude(undefined);
                setMarkerLocation(result.center);
                const city = result.context?.find(({ id }) => id.startsWith('place'));
                const country = result.context?.find(({ id }) => id.startsWith('country'));
                onLocationChange({
                    address: result.place_name ?? result?.['place_name_en-US'],
                    city: city?.['text_en-US'] ?? city?.text,
                    country: country?.['text_en-US'] ?? country?.text,
                    longitude: result.center[0],
                    latitude: result.center[1],
                });
            }
        });

        map.on('click', (event) => {
            currentMarkerId = Math.random();
            const location = mapboxLngLatToSimple(event.lngLat);
            setMarkerLocation(location);
            setLookupLongitude(location?.[0]);
            setLookupLatitude(location?.[1]);
        });

        map.addControl(geocoder, 'top-right');

        let isAborted = false;
        map.on('load', () => {
            if (!isAborted) {
                setMap(map);
            }
        });

        return () => {
            isAborted = true;
            map.remove();
            setMap(undefined);
        };
    }, [defaultLocation, onLocationChange]);

    return <div ref={mapRef} className={className} />;
};

export default MapBoxGeocoder;
