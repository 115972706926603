import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';

import UserForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IUser } from '@/interfaces/users';

export const UsersCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, onFinish } = useForm<IUser, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Create title={t('users.titles.create')} saveButtonProps={saveButtonProps}>
            <UserForm formProps={formProps} onFinish={onFinish} />
        </Create>
    );
};
