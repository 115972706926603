import { Fragment, useMemo, type FunctionComponent } from 'react';
import { useTranslate } from '@refinedev/core';
import { Col, Row } from 'antd';

import type { FactoryBrandScore } from '@/interfaces/factoryBrands';

export type Props = {
    value?: FactoryBrandScore[];
};

const ScoresDisplay: FunctionComponent<Props> = ({ value }) => {
    const t = useTranslate();

    const scores = useMemo(() => {
        const newValue = value ? [...value] : [];
        newValue.sort((a, b) => {
            return a.year === b.year ? a.quarter - b.quarter : a.year - b.year;
        });

        return newValue;
    }, [value]);

    if (!scores.length) {
        return (
            <Row>
                <Col span={24}>-</Col>
            </Row>
        );
    }

    return (
        <Fragment>
            {scores.map(({ year, quarter, value }) => {
                return (
                    <Row>
                        <Col span={12}>
                            <span>{t(`quarters.${quarter}q`)}</span>
                            <span>-</span>
                            <span>{year}</span>
                        </Col>
                        <Col span={12}>{value}</Col>
                    </Row>
                );
            })}
        </Fragment>
    );
};

export default ScoresDisplay;
