import { useMemo, type FunctionComponent } from 'react';
import { Col, Row, Table } from 'antd';
import { useNavigation, useTranslate } from '@refinedev/core';
import { TextField, useTable } from '@refinedev/antd';

import { DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import InspectionResultBadge from '@/components/InspectionResultBadge';

import useTableSettings from '@/hooks/useTableSettings';
import downloadInspectionReport from '@/utils/inspection/downloadInspectionReport';
import type { TableColumn } from '@/interfaces';
import type { IFactory } from '@/interfaces/factories';
import type { IInspection } from '@/interfaces/inspections';

export type Props = {
    tBase: string;
    factory?: IFactory;
};

type InspectionColumns = TableColumn<IInspection, keyof IInspection>[];

const FactoryInspections: FunctionComponent<Props> = ({ factory, tBase }) => {
    const t = useTranslate();
    const { show } = useNavigation();

    const { tableProps } = useTable<IInspection>({
        resource: 'inspections',
        filters: {
            permanent: [{ field: 'factory.id', operator: 'eq', value: factory?.id }],
        },
        sorters: {
            initial: [{ field: 'startDate', order: 'desc' }],
        },
        queryOptions: {
            enabled: factory?.id != null,
        },
    });

    const columns: InspectionColumns = useMemo(
        () => [
            {
                name: 'project',
                sorter: true,
                render: (value?: IInspection['project']) => (
                    <TextField value={value?.name || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'name',
                sorter: true,
                render: (value?: IInspection['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'uomTotal',
                sorter: true,
                render: (value?: IInspection['uomTotal'], entity?: IInspection) => (
                    <TextField
                        value={
                            value == null
                                ? DEFAULT_NA_VALUE
                                : `${value.toFixed(2)}${entity?.uom?.name == null ? '' : ` ${entity.uom.name}`}`
                        }
                    />
                ),
            },
            {
                name: 'result',
                sorter: true,
                render: (value?: IInspection['result']) => <InspectionResultBadge result={value} />,
            },
            {
                name: 'reportDownloadingUrl',
                render: (_value: IInspection['reportDownloadingUrl'], entity) => (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.inspections.list.buttons.downloadReport`)}
                        disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadInspectionReport(entity);
                        }}
                    />
                ),
            },
        ],
        [tBase, t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        `${tBase}.inspections.list`,
        'factory-inspections',
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} className='text-right'>
                <TableSettingsButton
                    title={t(`${tBase}.inspections.list.tableSettings.title`)}
                    columnsList={columnsList}
                    settings={settings}
                    onSettingsChange={onSettingsChange}
                />
            </Col>
            <Col xs={24}>
                <Table
                    {...tableProps}
                    rowKey='id'
                    onRow={(entity) => {
                        return {
                            onClick: () => show('inspections', entity.id),
                        };
                    }}
                    rowClassName='cursor-pointer'
                    className='table-with-header-cell-nowrap'
                >
                    {columns.map(({ name, title, hidden, ...columnProps }) => {
                        const key = Array.isArray(name) ? name.join('.') : name;
                        return (
                            <Table.Column
                                {...columnProps}
                                dataIndex={name}
                                key={key}
                                hidden={hidden ?? !settings[key]}
                                title={title ?? t(`${tBase}.inspections.list.fields.${key}`)}
                            />
                        );
                    })}
                </Table>
            </Col>
        </Row>
    );
};

export default FactoryInspections;
