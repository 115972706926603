import { type CanParams } from '@refinedev/core';

import getProfile from '@/utils/auth/getProfile';
import isUserAdmin from '@/utils/user/isUserAdmin';
import { UserRole } from '@/interfaces/enums/users';

type Resource =
    | 'dashboard'
    | 'audits'
    | 'companies'
    | 'defects'
    | 'factories'
    | 'factorybrands'
    | 'inspections'
    | 'producttypes'
    | 'projects'
    | 'users';

type Action = 'get' | 'list' | 'create' | 'edit' | 'delete';

const permissions: Partial<
    Record<UserRole, Partial<Record<Resource, Partial<Record<Action, boolean>>>>>
> = {
    [UserRole.OPERATOR]: {
        dashboard: { list: true },
        audits: { get: true, list: true, create: true, edit: true, delete: true },
        companies: { get: true, list: true, create: true, edit: true },
        defects: { get: true, list: true, create: true, edit: true },
        factories: { get: true, list: true, create: true, edit: true },
        factorybrands: { get: true, list: true, create: true, edit: true },
        inspections: { get: true, list: true, create: true, edit: true, delete: true },
        producttypes: { get: true, list: true, create: true, edit: true },
        projects: { get: true, list: true, create: true, edit: true, delete: true },
        users: { get: true, list: true, create: true, edit: true },
    },
    [UserRole.PARTNER]: {
        dashboard: { list: true },
        audits: { get: true, list: true },
        defects: { get: true },
        factories: { get: true, list: true },
        factorybrands: { get: true },
        inspections: { get: true, list: true },
        producttypes: { get: true },
        projects: { get: true, list: true },
    },
};

const can = async ({ action, resource, params }: CanParams) => {
    let can = false;
    const profile = getProfile();
    if (profile?.role) {
        const checkResource = resource?.toLowerCase();
        if (isUserAdmin(profile)) {
            can = true;
            // } else if (checkResource === 'users' && (action === 'edit' || action === 'delete')) {
            //     if (params?.resource) {
            //         console.log('params?.resource', params?.resource);
            //         can = true;
            //     }
        } else if (checkResource) {
            can = !!permissions[profile.role]?.[checkResource as Resource]?.[action as Action];
        }
    }

    return { can };
};

export default can;
