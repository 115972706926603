import { PROFILE_STORAGE_KEY } from '@/constants';

import type { IUser } from '@/interfaces/users';

const getProfile = (): IUser | undefined => {
    try {
        const profileData = localStorage.getItem(PROFILE_STORAGE_KEY);
        if (profileData) {
            return JSON.parse(profileData);
        }
    } catch (error) {
        console.error(error);
    }
};

export default getProfile;
