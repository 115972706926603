import React, { useRef } from 'react';
import { Edit } from '@refinedev/antd';
import { Button, Input, Card, Form, Layout as AntdLayout, Row, Col, Typography } from 'antd';
import { useNavigation, useNotification, useTranslate } from '@refinedev/core';
import { useLocation, Navigate } from 'react-router-dom';
import { SaveOutlined } from '@ant-design/icons';

import { API_ROOT } from '@/constants';

import axiosInstance from '@/utils/dataProvider/axios';

import c from '../login/login.module.css';

const ChangePasswordForm = ({
    onFormSubmit,
    formRef,
    t,
}: {
    onFormSubmit: (values: any) => Promise<void>;
    formRef: React.MutableRefObject<any>;
    t: any;
}) => {
    return (
        <Form onFinish={onFormSubmit} ref={formRef} layout='vertical'>
            <Form.Item
                label={t('users.fields.password')}
                name='password'
                rules={[{ required: true }, { min: 6 }]}
            >
                <Input.Password autoComplete='off' />
            </Form.Item>
            <Form.Item
                label={t('users.fields.passwordConfirmation')}
                name='passwordConfirmation'
                rules={[{ required: true }, { min: 6 }]}
            >
                <Input.Password autoComplete='off' />
            </Form.Item>
        </Form>
    );
};

export const ChangePasswordPage: React.FC = () => {
    const formRef = useRef<any>(null);

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigation();

    const notification = useNotification();
    const t = useTranslate();

    let isAuthenticated = false;
    try {
        isAuthenticated = !!localStorage.getItem('sp-access-token');
    } catch (error) {
        // Just swallowing errors
        console.error(error);
    }

    const search = useLocation().search;

    const email = new URLSearchParams(search).get('email');
    const token = new URLSearchParams(search).get('token');

    const handleSubmitClick = () => {
        formRef.current?.submit();
    };

    const handleFormSubmit = async (values: any) => {
        setLoading(true);

        const password = values.password;
        const passwordConfirmation = values.passwordConfirmation;

        if (!password || !passwordConfirmation) {
            notification.open?.({
                message: t('notifications.missingRequiredParamsError'),
                type: 'error',
            });
            setLoading(false);
            return;
        }

        if (password !== passwordConfirmation) {
            notification.open?.({
                message: t('users.notifications.passwordsDoesNotMatch'),
                type: 'error',
            });
            setLoading(false);
            return;
        }

        if (!email || !token) {
            notification.open?.({
                message: t('notifications.missingRequiredParamsError'),
                type: 'error',
            });
            setLoading(false);
            return;
        } else {
            axiosInstance
                .post(`${API_ROOT}/auth/repair-password`, {
                    email,
                    password,
                    passwordConfirmation,
                    token,
                })
                .then((response) => {
                    if (response.data) {
                        notification.open?.({
                            message: t('notifications.success'),
                            type: 'success',
                        });
                        navigate.push('/');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    notification.open?.({
                        message: t('notifications.requestError'),
                        type: 'error',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    if (!email || !token) {
        return <Navigate to='/' />;
    }

    return isAuthenticated ? (
        <Edit
            title='Change password'
            headerProps={{
                backIcon: false,
                style: {
                    color: 'white',
                },
            }}
            headerButtons={() => (
                <Button type='default' onClick={() => navigate.push('/')}>
                    Go back
                </Button>
            )}
            footerButtons={() => (
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    icon={<SaveOutlined />}
                    onClick={handleSubmitClick}
                    type='primary'
                >
                    {t('buttons.save')}
                </Button>
            )}
        >
            <ChangePasswordForm onFormSubmit={handleFormSubmit} formRef={formRef} t={t} />
        </Edit>
    ) : (
        <AntdLayout className={c.layout}>
            <Row justify='center' align='middle' className={c.layoutInner}>
                <Col xs={22}>
                    <div className={c.container}>
                        <Card
                            title={
                                <Typography.Title level={3} className={c.title}>
                                    Change password
                                </Typography.Title>
                            }
                            headStyle={{ borderBottom: 0 }}
                        >
                            <ChangePasswordForm
                                t={t}
                                formRef={formRef}
                                onFormSubmit={handleFormSubmit}
                            />
                            <Button
                                block
                                size='large'
                                disabled={isLoading}
                                loading={isLoading}
                                icon={<SaveOutlined />}
                                onClick={handleSubmitClick}
                                type='primary'
                            >
                                {t('buttons.save')}
                            </Button>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
