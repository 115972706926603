import { type CrudFilter } from '@refinedev/core';
import { type Dayjs } from 'dayjs';

import type { IFactory } from '@/interfaces/factories';
import type { IAudit } from '@/interfaces/audits';

export type SearchParams = Partial<
    Pick<IAudit, 'name' | 'typeId' | 'projectId' | 'productTypeId' | 'scoreResult' | 'clientId'> & {
        factoryBrandId: IFactory['brandId'];
        city: string;
        country: string;
        createdAt: [Dayjs, Dayjs];
    }
>;

const onSearch = ({
    name,
    typeId,
    projectId,
    productTypeId,
    scoreResult,
    clientId,
    createdAt,
    factoryBrandId,
    city,
    country,
}: SearchParams): CrudFilter[] => {
    return [
        {
            field: 'name',
            operator: 'contains',
            value: name || undefined,
        },
        {
            field: 'typeId',
            operator: 'eq',
            value: typeId || undefined,
        },
        {
            field: 'projectId',
            operator: 'eq',
            value: projectId || undefined,
        },
        {
            field: 'productTypeId',
            operator: 'eq',
            value: productTypeId || undefined,
        },
        {
            field: 'factory.brandId',
            operator: 'eq',
            value: factoryBrandId || undefined,
        },
        {
            field: 'factory.country',
            operator: 'eq',
            value: country || undefined,
        },
        {
            field: 'factory.city',
            operator: 'eq',
            value: city || undefined,
        },
        {
            field: 'scoreResult',
            operator: 'eq',
            value: scoreResult || undefined,
        },

        {
            field: 'clientId',
            operator: 'eq',
            value: clientId || undefined,
        },
        {
            field: 'createdAt',
            operator: 'gte',
            value: createdAt?.[0]?.isValid()
                ? createdAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt?.[1]?.isValid() ? createdAt[1].endOf('day').toISOString() : undefined,
        },
    ];
};

export default onSearch;
