import { useMemo } from 'react';
import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL, DEFAULT_NA_VALUE } from '@/constants';

import type { AntdSelectOption } from '@/interfaces';
import type { ISemiconductorType } from '@/interfaces/semiconductorTypes';

const useSemiconductorTypesOptions = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetProductTypes } = {} } = useCan({
        resource: 'productTypes',
        action: 'get',
    });
    const { data, isLoading } = useCustom<ISemiconductorType[]>({
        url: `${apiUrl}/productTypes/semiconductorTypes`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetProductTypes,
        },
    });

    const semiconductorTypes = data?.data;

    return {
        data: useMemo(
            () =>
                semiconductorTypes?.map(
                    (item): AntdSelectOption<ISemiconductorType> => ({
                        value: item.id,
                        label: item.value,
                        item,
                    }),
                ) ?? [],
            [semiconductorTypes],
        ),
        isLoading,
    };
};

export default useSemiconductorTypesOptions;
