import { useMemo, useState, type FunctionComponent } from 'react';
import { Pie } from '@ant-design/plots';

import getPercent from '@/utils/getPercent';

export type ChartItem = {
    value: number;
    name: string;
};

export type Props = {
    title: string;
    items: ChartItem[];
    colors: string[];
    legendLabelFormatter: (label: string) => string;
};

const Chart: FunctionComponent<Props> = ({ title, items, colors, legendLabelFormatter }) => {
    const [highlightedArea, setHighlightedArea] = useState<string | undefined>();
    const total = useMemo(
        () => items.reduce((result, { value }) => result + (value ?? 0), 0) ?? 0,
        [items],
    );
    const text = useMemo(() => {
        if (highlightedArea == null) {
            return `${title}\n${total}`;
        }

        const item = items.find(({ name }) => name === highlightedArea);

        return item ? `${legendLabelFormatter(item.name)}\n${item.value}` : `${title}\n${total}`;
    }, [title, total, items, highlightedArea, legendLabelFormatter]);

    const renderLabel = useMemo(() => {
        return ({ value }: ChartItem) => `${getPercent(value, total).toFixed(2)}%`;
    }, [total]);

    return (
        <div style={{ textAlign: 'center' }}>
            <Pie
                data={items}
                angleField='value'
                colorField='name'
                radius={0.95}
                innerRadius={0.64}
                label={{ position: 'outside', text: renderLabel }}
                tooltip={false}
                state={{
                    inactive: { opacity: 0.8 },
                }}
                autoFit={false}
                annotations={[
                    {
                        type: 'text',
                        tooltip: false,
                        style: {
                            text: text,
                            x: '50%',
                            y: '50%',
                            textAlign: 'center',
                            fontSize: 24,
                        },
                    },
                ]}
                scale={{
                    color: {
                        range: colors,
                    },
                }}
                interaction={{
                    legendFilter: false,
                    elementHighlight: true,
                }}
                legend={{
                    color: {
                        itemMarker: 'circle',
                        position: 'bottom',
                        labelFormatter: legendLabelFormatter,
                        layout: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        },
                    },
                }}
                onReady={(plot) => {
                    plot.chart.on('interval:pointerover', (event: any) => {
                        setHighlightedArea(event.data.data.name);
                    });
                    plot.chart.on('interval:pointerout', () => {
                        setHighlightedArea(undefined);
                    });
                }}
                animate={false}
            />
        </div>
    );
};

export default Chart;
