import type { IAudit } from '@/interfaces/audits';
import { AuditStatus, AuditStatusColor } from '@/interfaces/enums/audits';

export const colors: Record<AuditStatus | 'unknown', AuditStatusColor> = {
    [AuditStatus.COMPLETE]: AuditStatusColor.COMPLETE,
    [AuditStatus.ONGOING]: AuditStatusColor.ONGOING,
    [AuditStatus.PLANNED]: AuditStatusColor.PLANNED,
    [AuditStatus.SCHEDULED]: AuditStatusColor.SCHEDULED,
    unknown: AuditStatusColor.UNKNOWN,
};

const getAuditStatusColor = (status?: IAudit['auditStatus']) => {
    return (status != null && colors[status]) || colors.unknown;
};

export default getAuditStatusColor;
