import type { IUser } from '@/interfaces/users';

const getUserInitials = (user?: IUser): string => {
    const pieces = [
        user?.firstName?.trim().replace(/[^a-zA-Z]/g, '')?.[0],
        user?.lastName?.trim().replace(/[^a-zA-Z]/g, '')?.[0],
    ].filter(Boolean);

    return pieces.join('').toUpperCase();
};

export default getUserInitials;
