import { type CrudFilters } from '@refinedev/core';

import { IProductType } from '@/interfaces/productTypes';

export type SearchParams = Partial<Pick<IProductType, 'name'>> & {
    productTypeId?: string;
    typeId?: string;
    subtypeId?: string;
};

const onSearch = ({ name, productTypeId, typeId, subtypeId }: SearchParams): CrudFilters => {
    return [
        {
            field: 'name',
            operator: 'contains',
            value: name || undefined,
        },
        {
            field: 'productTypeId',
            operator: 'eq',
            value: productTypeId || undefined,
        },
        {
            field: 'typeId',
            operator: 'eq',
            value: typeId || undefined,
        },
        {
            field: 'subtypeId',
            operator: 'eq',
            value: subtypeId || undefined,
        },
    ];
};

export default onSearch;
