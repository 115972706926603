import dayjs from 'dayjs';

import { type FormValues } from '@/pages/factoryBrands/form';

const formValuesToFactoryBrand = (values: FormValues): FormValues => {
    const scores =
        values.scores?.map(({ year, quarter, value }) => ({
            year: dayjs(year).year(),
            quarter: Number(quarter),
            value: Number(value),
        })) ?? [];
    scores.sort((a, b) => {
        return a.year === b.year ? a.quarter - b.quarter : a.year - b.year;
    });
    const data: FormValues = {
        name: values.name,
        listing: values.listing,
        scores,
    };

    return data;
};

export default formValuesToFactoryBrand;
