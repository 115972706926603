import { type FunctionComponent, useCallback, useMemo } from 'react';
import { Button, Popover, Checkbox, type CheckboxOptionType } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import type { TableColumnsList, TableSettings } from '@/interfaces';

export type Props = {
    title: string;
    settings: TableSettings;
    columnsList: TableColumnsList;
    onSettingsChange: (settings: TableSettings) => void;
};

const TableSettingsButton: FunctionComponent<Props> = ({
    title,
    settings,
    columnsList,
    onSettingsChange,
}) => {
    const options: CheckboxOptionType<string>[] = useMemo(() => {
        return columnsList.map(({ name, label }) => ({ value: name, label }));
    }, [columnsList]);

    const value = useMemo(() => {
        return Object.entries(settings)
            .filter(([_name, value]) => !!value)
            .map(([name]) => name);
    }, [settings]);

    const onChange = useCallback(
        (selected: string[]) => {
            const newSettings: TableSettings = {};
            columnsList.forEach(({ name }) => {
                newSettings[name] = selected.includes(name);
            });
            onSettingsChange(newSettings);
        },
        [columnsList, onSettingsChange],
    );

    return (
        <Popover
            content={
                <Checkbox.Group
                    value={value}
                    options={options}
                    className='column'
                    onChange={onChange}
                />
            }
            title={title}
        >
            <Button>
                <SettingOutlined />
                {title}
            </Button>
        </Popover>
    );
};

export default TableSettingsButton;
