import { type FunctionComponent, useCallback } from 'react';
import { Row, Col, InputNumber } from 'antd';
import { useTranslate } from '@refinedev/core';

import type { IInspectionDefectValue } from '@/interfaces/inspections';
import type { IDefect } from '@/interfaces/defects';
import { DefectSeverity } from '@/interfaces/enums/defects';

import c from './DefectField.module.css';

export type Props = {
    value?: IInspectionDefectValue;
    defect: IDefect;
    disabled?: boolean;
    className?: string;
    onChange?: (value?: IInspectionDefectValue) => void;
};

const severities = Object.values(DefectSeverity);

const DefectField: FunctionComponent<Props> = ({
    value,
    defect,
    disabled,
    className,
    onChange,
}) => {
    const t = useTranslate();

    const onChangeInner = useCallback(
        (severity: DefectSeverity, fieldValue: string | number | null) => {
            const newValue: IInspectionDefectValue = {
                ...(value ?? {}),
                defectId: defect.id,
            };
            newValue[severity] = fieldValue;
            onChange?.(newValue);
        },
        [value, defect, onChange],
    );

    return (
        <Row className={className}>
            <Col className='flex-grow-1'>{t(`defects.names.${defect.name}`, defect.name)}</Col>
            {severities.map((severity) => {
                return (
                    <Col key={severity}>
                        <InputNumber
                            type='number'
                            value={value?.[severity]}
                            disabled={disabled}
                            precision={0}
                            min={0}
                            controls={false}
                            changeOnWheel={false}
                            placeholder={t(`defects.severities.${severity}`)}
                            className={c.input}
                            onChange={(value) => onChangeInner(severity, value)}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default DefectField;
