import { Fragment, useCallback, useState, type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    type HttpError,
    useNotification,
    useCan,
    useApiUrl,
    useGetIdentity,
} from '@refinedev/core';
import { DeleteButton, Edit, SaveButton, useForm } from '@refinedev/antd';
import { Button, Modal, Form, Popconfirm } from 'antd';
import { KeyOutlined } from '@ant-design/icons';

import UserForm, { type FormValues } from './form';

import axiosInstance from '@/utils/dataProvider/axios';
import { errorNotification } from '@/notifications';
import type { IUser } from '@/interfaces/users';

import c from './EditForm.module.css';

export const UsersEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: user } = useGetIdentity<IUser>();
    const apiUrl = useApiUrl();
    const notification = useNotification();
    const { data: { can: canDelete } = {} } = useCan({ resource: 'users', action: 'delete' });

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState<boolean>(false);
    const [isResetPasswordLoading, setIsResetPasswordLoading] = useState<boolean>(false);
    const { formProps, saveButtonProps, onFinish } = useForm<IUser, HttpError, FormValues>({
        errorNotification,
    });

    const showResetPasswordModal = useCallback(() => {
        setIsResetPasswordModalOpen(true);
    }, []);

    const onCancelResetPassword = useCallback(() => {
        setIsResetPasswordModalOpen(false);
    }, []);

    const email = formProps.initialValues?.email;
    const onConfirmResetPassword = useCallback(async () => {
        setIsResetPasswordLoading(true);
        try {
            const response = await axiosInstance.post(`${apiUrl}/auth/forgot-password`, { email });
            if (response.data) {
                notification.open?.({
                    message: t('notifications.success'),
                    type: 'success',
                });
            }
        } catch (error) {
            console.error(error);
            notification.open?.({
                message: t('notifications.requestError'),
                type: 'error',
            });
        } finally {
            setIsResetPasswordModalOpen(false);
            setIsResetPasswordLoading(false);
        }
    }, [email, apiUrl, notification, t]);

    const formWatchParams = { form: formProps.form, preserve: true };
    const isActive: boolean | undefined = Form.useWatch('isActive', formWatchParams);

    const isAboutToDeactivateSelf = !isActive && user?.id === formProps?.initialValues?.id;

    return (
        <Edit
            canDelete={canDelete}
            title={t('users.titles.edit')}
            saveButtonProps={saveButtonProps}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    <Button type='primary' onClick={showResetPasswordModal} icon={<KeyOutlined />}>
                        {t('users.form.buttons.passwordReset')}
                    </Button>
                    {defaultButtons}
                </Fragment>
            )}
            footerButtons={
                isAboutToDeactivateSelf
                    ? ({ deleteButtonProps, saveButtonProps }) => {
                          const onSaveClick = saveButtonProps?.onClick;
                          delete saveButtonProps?.onClick;

                          return (
                              <Fragment>
                                  <DeleteButton {...deleteButtonProps} />
                                  <Popconfirm
                                      cancelText='Cancel'
                                      okType='danger'
                                      title={t('users.confirm.deactivateOwnAccount')}
                                      rootClassName={c.popup}
                                      // @ts-ignore
                                      onConfirm={onSaveClick}
                                  >
                                      <SaveButton {...saveButtonProps} />
                                  </Popconfirm>
                              </Fragment>
                          );
                      }
                    : undefined
            }
        >
            <UserForm formProps={formProps} isEdit={true} onFinish={onFinish} />
            <Modal
                title={t('users.resetPasswordModal.title')}
                open={isResetPasswordModalOpen}
                confirmLoading={isResetPasswordLoading}
                onOk={onConfirmResetPassword}
                onCancel={onCancelResetPassword}
            >
                <p>{t('users.resetPasswordModal.text')}</p>
            </Modal>
        </Edit>
    );
};
