import { type Result } from '@/utils/inspection/getInspectionTotalModulesWithDefects';
import { InspectionModulesDefectsColor } from '@/interfaces/enums/inspections';

export const colors: { threshold: number; color: InspectionModulesDefectsColor }[] = [
    { threshold: 5, color: InspectionModulesDefectsColor.GOOD },
    { threshold: 20, color: InspectionModulesDefectsColor.PASSABLE },
];

const getInspectionModulesDefectsColor = (data?: Result) => {
    if (data == null) {
        return InspectionModulesDefectsColor.UNKNOWN;
    }

    for (let { threshold, color } of colors) {
        if (data.percent < threshold) {
            return color;
        }
    }

    return InspectionModulesDefectsColor.BAD;
};

export default getInspectionModulesDefectsColor;
