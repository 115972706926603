import { useMemo, type FunctionComponent } from 'react';
import { Card, Spin, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';
import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import Chart from './Chart';

import getInspectionDefectsChartItems from '@/utils/inspection/getInspectionDefectsChartItems';
import type { IInspectionDefectSummary } from '@/interfaces/inspections';
import { DefectType } from '@/interfaces/enums/defects';

import c from './InspectionDefectsChart.module.css';

export type Props = {
    tBase: string;
    defectType: DefectType;
    defectsSummary?: IInspectionDefectSummary[];
    isLoading?: boolean;
    count?: number;
};

const InspectionDefectsChart: FunctionComponent<Props> = ({
    tBase,
    defectType,
    defectsSummary,
    isLoading,
    count,
}) => {
    const t = useTranslate();

    const data = useMemo(
        () => getInspectionDefectsChartItems(defectsSummary, count),
        [defectsSummary, count],
    );

    if (!isLoading && !count) {
        return null;
    }

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <InfoCircleOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.defects.chart.${defectType}`)}
                    </Typography.Text>
                </div>
            }
            className={clsx(c.card, isLoading && c.loading)}
        >
            {isLoading ? <Spin className='w-full h-full' /> : <Chart data={data} count={count} />}
        </Card>
    );
};

export default InspectionDefectsChart;
