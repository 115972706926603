import { type FunctionComponent, useMemo } from 'react';
import { Button, Card, Form, type FormProps, Select, Input } from 'antd';
import { useTranslate } from '@refinedev/core';

import { DEFECT_SUB_TYPES, DEFECT_TYPES } from '@/constants';

import useProductTypesOptions from '@/hooks/options/useProductTypesOptions';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import { type SearchParams } from './onSearch';
import type { FormField } from '@/interfaces';

export type FormValues = SearchParams;

export type Props = {
    formProps: FormProps;
};

const defectTypesOptions = DEFECT_TYPES.map(({ id, text }) => ({ value: id, label: text }));

const defectSubtypesOptions = DEFECT_SUB_TYPES.map(({ id, text }) => ({ value: id, label: text }));

const tBase = 'defects.filters';

const DefectsFilters: FunctionComponent<Props> = ({ formProps }) => {
    const t = useTranslate();

    const { data: productTypesOptions } = useProductTypesOptions();

    const fields: FormField<keyof FormValues>[] = useMemo(() => {
        return [
            {
                name: 'name',
                field: (
                    <Input
                        allowClear={true}
                        placeholder={t(`${tBase}.placeholders.name`)}
                        autoComplete='off'
                    />
                ),
            },
            {
                name: 'productTypeId',
                field: (
                    <Select
                        placeholder={t('defects.filters.placeholders.productType')}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={productTypesOptions}
                    />
                ),
            },
            {
                name: 'typeId',
                field: (
                    <Select
                        placeholder={t('defects.filters.placeholders.typeId')}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={defectTypesOptions}
                    />
                ),
            },
            {
                name: 'subtypeId',
                field: (
                    <Select
                        placeholder={t('defects.filters.placeholders.subtypeId')}
                        showSearch={true}
                        allowClear={true}
                        optionFilterProp='label'
                        filterOption={antdSelectFilter}
                        filterSort={antdSelectSort}
                        options={defectSubtypesOptions}
                    />
                ),
            },
        ];
    }, [productTypesOptions, t]);

    return (
        <Card title={t(`${tBase}.title`)}>
            <Form layout='vertical' {...formProps}>
                {fields.map(({ name, field, ...fieldProps }) => {
                    return (
                        <Form.Item
                            {...fieldProps}
                            key={name}
                            name={name}
                            label={t(`${tBase}.fields.${name}`)}
                        >
                            {field}
                        </Form.Item>
                    );
                })}

                <Form.Item>
                    <Button
                        onClick={formProps.form?.submit}
                        type='primary'
                        size='large'
                        block={true}
                    >
                        {t(`${tBase}.buttons.submit`)}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default DefectsFilters;
