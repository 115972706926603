import type { IInspectionDefectSummary, InspectionDefectChartItem } from '@/interfaces/inspections';
import { DefectSeverity } from '@/interfaces/enums/defects';

export type Result = InspectionDefectChartItem[];

const severities = Object.values(DefectSeverity);

const getInspectionDefectsChartItems = (
    defectsSummary?: IInspectionDefectSummary[],
    count?: number,
): Result => {
    const items: Result = [];

    let defects =
        defectsSummary?.map((item) => {
            const max = severities.reduce(
                (result, severity) => Math.max(result, Number(item[severity]) || 0),
                0,
            );
            return { ...item, max };
        }) ?? [];
    defects.sort((a, b) => b.max - a.max);

    if (count != null) {
        defects = defects.slice(0, count);
    }

    defects.forEach((item) => {
        const defectSeverities = severities.map((severity) => ({
            name: item.defect,
            severity,
            count: Number(item[severity]) || 0,
        }));

        defectSeverities.sort((a, b) => b.count - a.count);
        items.push(...defectSeverities);
    });

    return items;
};

export default getInspectionDefectsChartItems;
