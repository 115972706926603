import { useMemo, type FunctionComponent } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslate } from '@refinedev/core';

import { DEFAULT_NA_VALUE } from '@/constants';

import StatsCard from '@/components/StatsCard';

import getInspectionStatusColor from '@/utils/inspection/getInspectionStatusColor';
import getInspectionResultColor from '@/utils/inspection/getInspectionResultColor';
import getInspectionReworkRateColor from '@/utils/inspection/getInspectionReworkRateColor';
import getInspectionModulesDefectsColor from '@/utils/inspection/getInspectionModulesDefectsColor';
import getInspectionTotalModulesWithDefects from '@/utils/inspection/getInspectionTotalModulesWithDefects';
import getInspectionReworkRate from '@/utils/inspection/getInspectionReworkRate';
import type { IInspection } from '@/interfaces/inspections';

export type Props = {
    tBase: string;
    inspection?: IInspection;
};

const InspectionStats: FunctionComponent<Props> = ({ tBase, inspection }) => {
    const t = useTranslate();

    const defectsTotal = useMemo(
        () => getInspectionTotalModulesWithDefects(inspection),
        [inspection],
    );

    const rate = useMemo(() => getInspectionReworkRate(inspection), [inspection]);

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.status`)}
                    color={getInspectionStatusColor(inspection?.status)}
                >
                    <div className='d-flex row justify-between'>
                        <div>&nbsp;</div>
                        <Typography.Title level={3} className='m-0 uppercase'>
                            {inspection?.status == null
                                ? DEFAULT_NA_VALUE
                                : t(`inspections.status.${inspection.status}`)}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.result`)}
                    color={getInspectionResultColor(inspection?.result)}
                >
                    <div className='d-flex row justify-between'>
                        <div>&nbsp;</div>
                        <Typography.Title level={3} className='m-0 uppercase'>
                            {inspection?.result == null
                                ? DEFAULT_NA_VALUE
                                : t(`inspections.result.${inspection.result}`)}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.reworkRate`)}
                    color={getInspectionReworkRateColor(rate)}
                >
                    <div className='d-flex row justify-between align-end'>
                        <div>{`${inspection?.totalReworks ?? '-'}/${inspection?.totalProductionInput ?? '-'}`}</div>
                        <Typography.Title level={3} className='m-0'>
                            {rate == null ? DEFAULT_NA_VALUE : `${rate.toFixed(2)}%`}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
            <Col xs={6} className='d-flex flex-grow-1'>
                <StatsCard
                    title={t(`${tBase}.stats.totalModulesWithDefects`)}
                    color={getInspectionModulesDefectsColor(defectsTotal)}
                >
                    <div className='d-flex row justify-between align-end'>
                        <div>{`${defectsTotal?.total}/${defectsTotal?.inspected ?? DEFAULT_NA_VALUE}`}</div>
                        <Typography.Title level={3} className='m-0'>
                            {defectsTotal?.percent == null
                                ? DEFAULT_NA_VALUE
                                : `${defectsTotal.percent.toFixed(2)}%`}
                        </Typography.Title>
                    </div>
                </StatsCard>
            </Col>
        </Row>
    );
};

export default InspectionStats;
