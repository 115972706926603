import { type FunctionComponent } from 'react';
import { type IResourceComponentsProps, type HttpError } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';

import FactoriesForm, { type FormValues } from './form';

import { errorNotification } from '@/notifications';
import type { IFactory } from '@/interfaces/factories';

export const FactoriesCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IFactory, HttpError, FormValues>({
        errorNotification,
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <FactoriesForm formProps={formProps} onFinish={onFinish} />
        </Create>
    );
};
