import downloadFile from '@/utils/downloadFile';
import getInspectionReportName from './getInspectionReportName';
import type { IInspection } from '@/interfaces/inspections';

const downloadInspectionReport = (inspection?: IInspection) => {
    if (inspection && inspection.isReportUploaded && inspection.reportDownloadingUrl) {
        downloadFile(inspection.reportDownloadingUrl, getInspectionReportName(inspection));
    }
};

export default downloadInspectionReport;
