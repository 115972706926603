import { type CrudFilter } from '@refinedev/core';
import { Dayjs } from 'dayjs';

import type { IUser } from '@/interfaces/users';

export type SearchParams = Partial<
    Pick<IUser, 'email' | 'firstName' | 'lastName' | 'role' | 'companyId'> & {
        createdAt: [Dayjs, Dayjs];
        updatedAt: [Dayjs, Dayjs];
        isActive: string | boolean;
    }
>;

const onSearch = ({
    email,
    firstName,
    lastName,
    createdAt,
    updatedAt,
    role,
    companyId,
    isActive,
}: SearchParams): CrudFilter[] => {
    return [
        {
            field: 'email',
            operator: 'contains',
            value: email ? email : undefined,
        },
        {
            field: 'firstName',
            operator: 'contains',
            value: firstName ? firstName : undefined,
        },
        {
            field: 'lastName',
            operator: 'contains',
            value: lastName ? lastName : undefined,
        },
        {
            field: 'createdAt',
            operator: 'gte',
            value: createdAt?.[0]?.isValid()
                ? createdAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt?.[1]?.isValid() ? createdAt[1].endOf('day').toISOString() : undefined,
        },
        {
            field: 'updatedAt',
            operator: 'gte',
            value: updatedAt?.[0]?.isValid()
                ? updatedAt[0].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'updatedAt',
            operator: 'lte',
            value: updatedAt?.[1]?.isValid()
                ? updatedAt[1].startOf('day').toISOString()
                : undefined,
        },
        {
            field: 'role',
            operator: 'eq',
            value: role ? role : undefined,
        },
        {
            field: 'companyId',
            operator: 'eq',
            value: companyId ? companyId : undefined,
        },
        {
            field: 'isActive',
            operator: 'eq',
            value: isActive == null || isActive === '' ? undefined : !!isActive,
        },
    ];
};

export default onSearch;
