import { useMemo } from 'react';
import { useTranslate } from '@refinedev/core';

import type { AntdSelectOption } from '@/interfaces';
import { AuditScoreResult } from '@/interfaces/enums/audits';

const useAuditScoreResultOptions = () => {
    const t = useTranslate();

    return useMemo(
        () =>
            Object.values(AuditScoreResult).map(
                (result): AntdSelectOption => ({
                    value: result,
                    label: t(`audits.scoreResult.${result}`),
                }),
            ),
        [t],
    );
};

export default useAuditScoreResultOptions;
