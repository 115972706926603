import { Fragment, useCallback, useEffect, useState, type FunctionComponent } from 'react';
import { Form, Select } from 'antd';
import { useTranslate } from '@refinedev/core';

import { PROPERTIES_MAX_DEPTH } from '@/constants';

import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import type { AntdSelectOption } from '@/interfaces';
import type { IPropertyData } from '@/interfaces/properties';

export type Props = {
    tBase: string;
    data?: IPropertyData;
    maxDepth?: number;
    skipFields?: string[];
    required?: boolean;
    getFieldValue?: (name: string) => string | undefined;
    setFieldValue?: (name: string, value: string | undefined) => void;
};

type FieldData = {
    name: string;
    label?: string;
    options?: AntdSelectOption[];
    subfields?: IPropertyData;
};

const PropertiesTreeSelector: FunctionComponent<Props> = ({
    data,
    tBase,
    required,
    skipFields,
    maxDepth = PROPERTIES_MAX_DEPTH,
    getFieldValue,
    setFieldValue,
}) => {
    const t = useTranslate();
    // This state variable is used as a trigger for dependent fields check inside useEffect.
    const [fieldChangeTrigger, setFieldChangeTrigger] = useState<number | undefined>(0);
    const [fields, setFields] = useState<FieldData[]>([]);

    useEffect(() => {
        if (fieldChangeTrigger == null) {
            return;
        }

        const newFields =
            data?.map((field) => {
                const name = `property_${field.id}`;
                const currentValue = getFieldValue?.(name);
                let subfields: IPropertyData | undefined = undefined;
                const options: AntdSelectOption[] = [];
                let valueFound = false;
                field.vals?.forEach(({ id, value, children }) => {
                    options.push({ value: id || '', label: value || '' });
                    if (currentValue === id) {
                        valueFound = true;
                        if (children != null) {
                            subfields = children;
                        }
                    }
                });

                if (!valueFound && currentValue != null) {
                    setFieldValue?.(name, undefined);
                }

                return { name, label: field.name, options, subfields };
            }) ?? [];

        setFields(newFields);
    }, [data, getFieldValue, setFieldValue, fieldChangeTrigger]);

    const onChange = useCallback(() => {
        setFieldChangeTrigger(Math.random());
    }, []);

    if (maxDepth <= 0) {
        return null;
    }

    return (
        <Fragment>
            {fields.map(({ name, label, options, subfields }) => {
                if (skipFields?.includes(name)) {
                    return null;
                }

                return (
                    <Fragment key={name}>
                        <Form.Item
                            name={name}
                            label={t(`${tBase}.fields.${name}`, label)}
                            required={required}
                        >
                            <Select
                                placeholder={t(`${tBase}.placeholders.${name}`, '')}
                                showSearch={true}
                                optionFilterProp='label'
                                filterOption={antdSelectFilter}
                                filterSort={antdSelectSort}
                                options={options}
                                onChange={onChange}
                            />
                        </Form.Item>

                        {!!subfields && (
                            <PropertiesTreeSelector
                                tBase={tBase}
                                data={subfields}
                                maxDepth={maxDepth - 1}
                                skipFields={skipFields}
                                required={required}
                                getFieldValue={getFieldValue}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

export default PropertiesTreeSelector;
