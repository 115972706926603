export type SearchParams = {
    name: string;
};

export const onSearch = ({ name }: SearchParams) => {
    return [
        {
            field: 'name.value',
            operator: 'contains' as const,
            value: name,
        },
    ];
};
