import getFactoryLocation from '@/utils/factory/getFactoryLocation';
import type { IFactory } from '@/interfaces/factories';

const getFactoryTitle = (factory?: IFactory) => {
    if (!factory) {
        return '';
    }
    const factoryLocation = getFactoryLocation(factory);
    return [factory.name, factoryLocation ? `(${factoryLocation})` : ''].filter(Boolean).join(' ');
};

export default getFactoryTitle;
