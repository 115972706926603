import { type FunctionComponent } from 'react';
import { useTranslate, type IResourceComponentsProps, useShow, useCan } from '@refinedev/core';
import { Typography, Spin, Tag } from 'antd';
import { BooleanField, Show, TextField } from '@refinedev/antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';

import { DEFAULT_NA_VALUE } from '@/constants';

import formatUserFullname from '@/utils/user/formatUserFullname';
import type { IUser } from '@/interfaces/users';

const tBase = 'users.show';

export const UsersShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'users', action: 'edit' });

    const { queryResult } = useShow<IUser>();
    const { data, isLoading, isError } = queryResult;
    const entity = data?.data;

    if (isLoading) {
        return <Spin />;
    }

    if (isError) {
        return <div>{t(`${tBase}.errors.notFound`)}</div>;
    }

    return (
        <Show title={t('users.titles.show')} canEdit={canEdit} isLoading={isLoading}>
            <Typography.Title level={5}>{t(`${tBase}.fields.email`)}</Typography.Title>
            <Typography.Paragraph>
                <MailOutlined />
                <span>&nbsp;</span>
                <TextField value={entity?.email || DEFAULT_NA_VALUE} />
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.name`)}</Typography.Title>
            <Typography.Paragraph>
                <UserOutlined />
                <span>&nbsp;</span>
                <TextField value={formatUserFullname(entity) || DEFAULT_NA_VALUE} />
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.company`)}</Typography.Title>
            <Typography.Paragraph>
                <TextField value={entity?.company?.name || DEFAULT_NA_VALUE} />
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.role`)}</Typography.Title>
            <Typography.Paragraph>
                <Tag>
                    {entity?.role
                        ? t(`users.role.${entity.role}`, DEFAULT_NA_VALUE)
                        : DEFAULT_NA_VALUE}
                </Tag>
            </Typography.Paragraph>

            <Typography.Title level={5}>{t(`${tBase}.fields.isActive`)}</Typography.Title>
            <Typography.Paragraph>
                <BooleanField value={entity?.isActive} />
            </Typography.Paragraph>
        </Show>
    );
};
