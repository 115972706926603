export enum InspectionResult {
    PASS = 'pass',
    INCONCULSIVE = 'inconclusive',
    FAIL = 'fail',
}

export enum InspectionResultColor {
    PASS = '#acd68f',
    INCONCULSIVE = '#f9e966',
    FAIL = '#df370d',
    UNKNOWN = '#b8b8b8',
}

export enum InspectionStatus {
    SCHEDULING = 'Scheduling',
    SCHEDULED = 'Scheduled',
    IN_PROGRESS = 'In progress',
    COMPLETED = 'Completed',
}

export enum InspectionStatusColor {
    SCHEDULING = '#df370d',
    SCHEDULED = '#df370d',
    IN_PROGRESS = '#f9e966',
    COMPLETED = '#acd68f',
    UNKNOWN = '#b8b8b8',
}

export enum InspectionBomCdfCheck {
    COMPLIANT = 'compliant',
    NON_COMPLIANT = 'non-compliant',
    INCONCLUSIVE = 'inconclusive',
}

export enum InspectionReworkRateColor {
    GOOD = '#acd68f',
    PASSABLE = '#f9e966',
    BAD = '#df370d',
    UNKNOWN = '#b8b8b8',
}

export enum InspectionModulesDefectsColor {
    GOOD = '#acd68f',
    PASSABLE = '#f9e966',
    BAD = '#df370d',
    UNKNOWN = '#b8b8b8',
}
