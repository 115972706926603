import { InspectionReworkRateColor } from '@/interfaces/enums/inspections';

export const colors: { threshold: number; color: InspectionReworkRateColor }[] = [
    { threshold: 5, color: InspectionReworkRateColor.GOOD },
    { threshold: 20, color: InspectionReworkRateColor.PASSABLE },
];

const getInspectionReworkRateColor = (rate?: number) => {
    if (rate == null) {
        return InspectionReworkRateColor.UNKNOWN;
    }

    for (let { threshold, color } of colors) {
        if (rate < threshold) {
            return color;
        }
    }

    return InspectionReworkRateColor.BAD;
};

export default getInspectionReworkRateColor;
