import { useMemo, type FunctionComponent } from 'react';
import { Line } from '@ant-design/plots';

import type { IFactoryChartItem } from '@/interfaces/factories';

export type Props = {
    data?: IFactoryChartItem[];
};

const FinancialChart: FunctionComponent<Props> = ({ data }) => {
    const chartData: IFactoryChartItem[] = useMemo(
        () =>
            data?.map(({ value, ...item }) => ({
                value: Number((value ?? 0).toFixed(2)),
                ...item,
            })) ?? [],
        [data],
    );

    return (
        <Line
            data={chartData}
            padding='auto'
            xField='quarter'
            yField='value'
            colorField='factoryName'
            xAxis={{
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            }}
            legend={{
                color: {
                    itemMarker: 'circle',
                    position: 'bottom',
                    layout: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    },
                },
            }}
            interaction={{
                legendFilter: false,
            }}
            animation={{
                appear: {
                    duration: 2000,
                },
            }}
        />
    );
};

export default FinancialChart;
