import { Fragment, useCallback, useMemo, useState, type FunctionComponent } from 'react';
import { HttpError, useNavigation, useTranslate } from '@refinedev/core';
import { Form, Steps } from 'antd';
import { useStepsForm } from '@refinedev/antd';
import clsx from 'clsx';

import StepsFooterButtons from '@/components/StepsFooterButtons';
import AuditFormBase, { type FormValues as BaseFormValues } from './AuditFormBase';
import AuditFormNcs, { type FormValues as NcsFormValues } from './AuditFormNcs';
import AuditFormKeyItems, { type FormValues as KeyItemsFormValues } from './AuditFormKeyItems';

import useUploadFilesToAmazonService from '@/hooks/useUploadFilesToAmazonService';
import useFactoriesOptions from '@/hooks/options/useFactoriesOptions';
import formValuesToAudit from '@/utils/audit/formValuesToAudit';
import formValuesFromAudit from '@/utils/audit/formValuesFromAudit';
import { errorNotification } from '@/notifications';
import type { IAudit } from '@/interfaces/audits';
import type { IFactoryBrand } from '@/interfaces/factoryBrands';

import c from './AuditForm.module.css';

export type FormValues = BaseFormValues & NcsFormValues & KeyItemsFormValues;

export type Props = {};

export const tBase = 'audits.form';

export const auditFilesFields = [
    { name: 'reportUpload', urlKey: 'reportUploadingUrl', flagName: 'isReportUploaded' },
    {
        name: 'nccaReportUpload',
        urlKey: 'nccaReportUploadingUrl',
        flagName: 'isNccaReportUploaded',
    },
];

const AuditForm: FunctionComponent<Props> = () => {
    const t = useTranslate();
    const { list } = useNavigation();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const factories = useFactoriesOptions();

    const uploadReports = useUploadFilesToAmazonService({
        resource: 'audits',
        tBase: `${tBase}.base`,
        fields: auditFilesFields,
    });

    const steps = useMemo(() => {
        return [
            { title: t(`${tBase}.steps.base`) },
            { title: t(`${tBase}.steps.ncs`) },
            { title: t(`${tBase}.steps.keyItems`) },
        ];
    }, [t]);

    // Typings of `stepsProps.onChange` are incorrect. The return value of `onChange` is the same as for `gotoStep`
    const { current, gotoStep, stepsProps, formProps, saveButtonProps, onFinish } = useStepsForm<
        IAudit,
        HttpError,
        FormValues
    >({
        errorNotification,
        redirect: false,
        async submit(v: any) {
            const values: FormValues = v;
            setIsSaving(true);
            try {
                const {
                    data,
                    reportUpload,
                    nccaReportUpload,
                    isReportUploaded,
                    isNccaReportUploaded,
                } = formValuesToAudit(values, factories);

                const response = await onFinish(data);
                const entityId = response?.data?.id;
                if (entityId != null) {
                    await uploadReports(
                        entityId,
                        { reportUpload, nccaReportUpload },
                        { isReportUploaded, isNccaReportUploaded },
                    );
                }

                list('audits');
            } catch (error) {
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
    });

    const audit = formProps.initialValues as IAudit | undefined;
    const initialValues: FormValues | undefined = useMemo(
        () => formValuesFromAudit(audit),
        [audit],
    );

    const onStepChange = useCallback(
        async (step: number) => {
            try {
                return await gotoStep(step);
            } catch (error) {
                /** @todo Display notification */
                console.error(error);
            }

            return false;
        },
        [gotoStep],
    );

    const formWatchParams = { form: formProps.form, preserve: true };
    const factoryBrandId: IFactoryBrand['id'] | undefined = Form.useWatch(
        'factoryBrandId',
        formWatchParams,
    );
    const auditTypeId: IAudit['typeId'] | undefined = Form.useWatch('typeId', formWatchParams);
    const productTypeId: IAudit['productTypeId'] | undefined = Form.useWatch(
        'productTypeId',
        formWatchParams,
    );
    const clientId: IAudit['clientId'] | undefined = Form.useWatch('clientId', formWatchParams);

    const currentStep = stepsProps.current;

    return (
        <Fragment>
            <Steps
                current={currentStep}
                items={steps}
                className={c.steps}
                onChange={onStepChange}
            />
            <Form
                {...formProps}
                initialValues={initialValues}
                disabled={isSaving}
                layout='vertical'
                className={c.form}
            >
                <div className='flex-grow-1'>
                    {currentStep === 0 && (
                        <AuditFormBase
                            formProps={formProps}
                            tBase={`${tBase}.base`}
                            factoryBrandId={factoryBrandId}
                            clientId={clientId}
                        />
                    )}
                    {currentStep === 1 && <AuditFormNcs/>}
                    {currentStep === 2 && <AuditFormKeyItems auditTypeId={auditTypeId} productTypeId={productTypeId} clientId={clientId} />}
                </div>
            </Form>

            <StepsFooterButtons
                tBase={tBase}
                step={current}
                lastStep={steps.length - 1}
                isSaveDisabled={isSaving}
                saveButtonProps={saveButtonProps}
                className={clsx('d-flex row justify-between', c.actions)}
                onStepChange={onStepChange}
            />
        </Fragment>
    );
};

export default AuditForm;
