import { type FunctionComponent, useMemo } from 'react';
import { TextField } from '@refinedev/antd';

import type { IPropertyData } from '@/interfaces/properties';

export type Props = {
    propertyId: string;
    properties?: IPropertyData;
    defaultText?: string;
};

const PropertyDataDisplay: FunctionComponent<Props> = ({
    properties,
    propertyId,
    defaultText = '',
}) => {
    const productTechnologies = useMemo(
        () => properties?.find((property) => property.id === propertyId),
        [properties, propertyId]
    );

    return (
        <TextField
            value={
                productTechnologies
                    ? `${productTechnologies?.vals.map(({ value }) => value).join(', ')}`
                    : defaultText
            }
        />
    );
};

export default PropertyDataDisplay;
